import {
    Divider,
    List,
    ListItemButton,
    ListItemText,
    Tab,
    Tabs,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    setCurrent,
    setStepper,
} from "../../../../store/slices/profileStepper";
import { PROFILE_STEPS_LABELS } from "../../../../services/constants";
import { useLocation, useNavigate } from "react-router-dom";
import { preparePathStep, prepareStepPath } from "../../../../services/utils";

const Navigator = () => {
    const dispatch = useDispatch();

    const navigate = useNavigate();
    const location = useLocation();

    console.log(location);
    const theme = useTheme();
    const isMdScreen = useMediaQuery(theme.breakpoints.down("md"));

    const role = useSelector((state) => state.user.role);
    const { current, steps } = useSelector((state) => state.profileStepper);

    useEffect(() => {
        const path = location.pathname.split("/").pop();
        const step = preparePathStep(path);
        dispatch(setStepper({ role, step }));
    }, []);

    useEffect(() => {
        const step = steps[current];
        const path = prepareStepPath(step);
        navigate(path);
    }, [current]);

    return (
        <>
            {!isMdScreen && (
                <List>
                    <ListItemButton
                        sx={{
                            textAlign: "right",
                            "& .MuiTypography-root": {
                                color: theme.palette.danger.main,
                                fontWeight: "700",
                                fontSize: "24px",
                            },
                            "&.Mui-disabled": {
                                opacity: 1,
                            },
                        }}
                        disableRipple
                        disabled
                    >
                        <ListItemText primary="تعديل معلومات الحساب" />
                    </ListItemButton>
                    {steps.map((step, index) => (
                        <>
                            <ListItemButton
                                onClick={() => dispatch(setCurrent(index))}
                                sx={{
                                    bgcolor:
                                        steps[current] == step
                                            ? "rgba(0, 0, 0, 0.04)"
                                            : "inherit",
                                }}
                            >
                                <ListItemText
                                    sx={{
                                        textAlign: "right",
                                        "& .MuiTypography-root": {
                                            color: theme.palette.primary.main,
                                            fontWeight:
                                                steps[current] == step
                                                    ? 700
                                                    : 500,
                                            fontSize: "24px",
                                        },
                                    }}
                                    primary={PROFILE_STEPS_LABELS[step]}
                                />
                            </ListItemButton>
                            <Divider />
                        </>
                    ))}
                </List>
            )}
            {isMdScreen && (
                <Tabs
                    value={current}
                    variant="scrollable"
                    scrollButtons
                    allowScrollButtonsMobile
                    aria-label="scrollable force tabs example"
                    sx={{
                        marginBottom: 4,
                    }}
                >
                    {steps.map((step, index) => (
                        <Tab
                            onClick={() => dispatch(setCurrent(index))}
                            label={PROFILE_STEPS_LABELS[step]}
                        />
                    ))}
                </Tabs>
            )}
        </>
    );
};

export default Navigator;

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    createEducation,
    fetchAllEducations,
    fetchOneEducation,
    updateEducation,
} from "../../../../store/slices/educationLevelData";
import {
    fetchAllLevels,
    fetchAllSpecs,
    fetchAllUniversities,
    setErrors,
} from "../../../../store/slices/educationLevelForm";
import validationSchema from "./validationSchema";
import { collectAllValidationErrors } from "../../../../services/utils";
import { toast } from "react-toastify";
import { PROFILE_MODES } from "../../../../services/constants";

const useForm = () => {
    const dispatch = useDispatch();

    const userId = useSelector((state) => state.user.id);
    const { mode } = useSelector((state) => state.personalInfo);
    const educationLevelForm = useSelector((state) => state.educationLevelForm);
    const educationLevelData = useSelector((state) => state.educationLevelData);

    useEffect(() => {
        dispatch(fetchAllEducations({ userId }));
        dispatch(fetchAllSpecs());
        dispatch(fetchAllUniversities());
        dispatch(fetchAllLevels());
    }, [dispatch, userId]);

    useEffect(() => {
        if (
            mode == PROFILE_MODES.REGISTER &&
            educationLevelData.all.length > 0
        ) {
            const id = educationLevelData.all[0].id;
            dispatch(fetchOneEducation({ userId, id }));
        }
    }, [educationLevelData.all]);

    const handleSubmit = async () => {
        try {
            await validationSchema.validate(
                {
                    spec: educationLevelForm.spec,
                    university: educationLevelForm.university,
                    level: educationLevelForm.level,
                    graduated: educationLevelForm.graduated,
                    gradDate: educationLevelForm.gradDate,
                },
                { abortEarly: false }
            );
        } catch (error) {
            const errors = collectAllValidationErrors(error);
            dispatch(setErrors(errors));
            window.scrollTo(0, 0);
            toast.warn("اصلح الحقول المطلوبة");
            return;
        }

        const data = {
            specializationId: educationLevelForm.spec.id,
            collegeId: educationLevelForm.university.id,
            educationLevelId: educationLevelForm.level.id,
            graduated: educationLevelForm.graduated,
            graduationDate: educationLevelForm.gradDate,
        };

        if (educationLevelData.current) {
            dispatch(
                updateEducation({
                    userId,
                    id: educationLevelData.current.id,
                    data,
                })
            );
        } else {
            dispatch(
                createEducation({
                    userId,
                    data,
                })
            );
        }

        dispatch(setErrors(null));
    };

    return [educationLevelForm, educationLevelData, handleSubmit];
};

export default useForm;

import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

const AuthRedirectWrapper = ({ children }) => {
    const { accessToken, isVerified } = useSelector((state) => state.user);
    
    if (accessToken && isVerified) {
        return <Navigate to="/" />;
    } else if (accessToken && !isVerified) {
        return <Navigate to="/register/personal-info" />;
    }
    return <>{children}</>;
};

export default AuthRedirectWrapper;

import {
    Box,
    Chip,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Stack,
    Typography,
    useTheme,
} from "@mui/material";
import { MapPin, SuitcaseSimple } from "@phosphor-icons/react";
import React from "react";

const InterestRequest = () => {
    const theme = useTheme();

    return (
        <Box className="page">
            <Stack direction={"row"} justifyContent={"space-between"}>
                <Typography variant="heading1" fontWeight={"500"}>
                    {"تود مؤسسة ... معرفة مدى اهتمامك بالشاغر التالي:"}
                </Typography>
                <Chip
                    sx={{ borderRadius: 1, paddingY: 2.5, fontSize: "1.2rem" }}
                    label={"الشاغر متاح لتاريخ: 18-3-2022"}
                    color="error"
                    variant="outlined"
                />
            </Stack>
            <Stack direction={"row"} gap={4}>
                <List
                    sx={{
                        width: "100%",
                        maxWidth: 360,
                        bgcolor: "background.paper",
                        "& svg": {
                            color: theme.palette.primary.main,
                        },
                    }}
                    component="nav"
                >
                    <ListItem>
                        <ListItemIcon>
                            <SuitcaseSimple size={32} />
                        </ListItemIcon>
                        <ListItemText
                            sx={{
                                textAlign: "right",
                                color: theme.palette.primary.main,
                            }}
                            primary={`اسم الشاغر: ${"مطور واجهة امامية"}`}
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <MapPin size={32} />
                        </ListItemIcon>
                        <ListItemText
                            sx={{
                                textAlign: "right",
                                color: theme.palette.primary.main,
                            }}
                            primary={`مكان العمل: ${""}`}
                        />
                    </ListItem>
                </List>
            </Stack>
        </Box>
    );
};

export default InterestRequest;

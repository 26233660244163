import React from "react";
import { useSelector } from "react-redux";

const useAuthenicate = () => {
    const { accessToken } = useSelector((state) => state.user);

    return accessToken ? true : false;
};

export default useAuthenicate;

import {
    ENTERPRISE_STEPS,
    FREELANCER_STEPS,
    Gender,
    GenderLabel,
    JOB_SEEKER_STEPS,
    REGISTERTATION_TYPES,
    TRAINER_STEPS,
} from "./constants";

export const getRoleRegisterationSteps = (role) => {
    switch (role) {
        case REGISTERTATION_TYPES.JOB_SEEKER:
            return JOB_SEEKER_STEPS;
        case REGISTERTATION_TYPES.TRAINER:
            return TRAINER_STEPS;
        case REGISTERTATION_TYPES.FREELANCER:
            return FREELANCER_STEPS;
        case REGISTERTATION_TYPES.ENTERPRISE:
            return ENTERPRISE_STEPS;
        default:
            return [];
    }
};

export class BirthdateGenerator {
    static getMaxDays(year, month) {
        if (!year || !month) return 31;
        return new Date(year, month, 0).getDate();
    }

    static generateDays(year, month) {
        const daysInMonth = new Date(year, month, 0).getDate();
        return Array.from({ length: daysInMonth }, (_, i) => i + 1).map(
            (day) => ({
                label: day + "",
                value: day,
            })
        );
    }

    static generateMonths() {
        return Array.from({ length: 12 }, (_, i) => i + 1).map((month) => ({
            label: month + "",
            value: month,
        }));
    }

    static generateYears() {
        let currentYear = new Date().getFullYear();
        const lastAvailabileYear = currentYear - 13;

        return Array.from({ length: 60 }, (_, i) => lastAvailabileYear - i).map(
            (year) => ({
                label: year + "",
                value: year,
            })
        );
    }
}

export const formatDate = (dateInput) => {
    // Create a Date object from the input
    const date = new Date(dateInput);

    // Get the year, month, and day from the Date object
    const year = date.getFullYear();
    const month = date.getMonth() + 1; // Months are 0-based
    const day = date.getDate();

    // Return the formatted values
    return {
        year: year,
        month: month,
        day: day,
    };
};

export const createDateFromComponents = (year, month, day) => {
    // Month is zero-based, so subtract 1 from the month
    const date = new Date(year, month - 1, day);
    return date;
};

export class OptionsHelper {
    static getOptionObj(value) {
        return { label: value + "", value };
    }

    static getGenderOptionObj(value) {
        return { label: GenderLabel[value], value: value };
    }
}

export const getTokenFromLocalStorage = () => {
    try {
        const persistedState = localStorage.getItem("persist:root");
        if (!persistedState) {
            return null;
        }

        const rootState = JSON.parse(persistedState);
        const userState = JSON.parse(rootState.user); // Assuming 'user' is the key in rootReducer

        return userState.accessToken || null;
    } catch (error) {
        console.error("Failed to retrieve token from localStorage", error);
        return null;
    }
};

export const collectAllValidationErrors = (error) => {
    const errors = {};
    console.log(error.inner);
    if (error.inner) {
        error.inner.forEach((error) => {
            errors[error.path] = error.message;
        });
    }

    console.log(error);
    console.log(errors);

    return errors;
};

export const prepareStepPath = (step = "") => {
    if (step) {
        const path = step.toLowerCase().split("_").join("-");
        return path;
    }

    return null;
};

export const preparePathStep = (path = "") => {
    if (path) {
        const step = path.toUpperCase().split("-").join("_");
        return step;
    }

    return null;
};

export const getFileName = (path = "") => {
    const arr = path.split("/");
    return arr.length > 0 ? arr[arr.length - 1] : "";
};

export const isRegistrationCompleted = (role = "", user) => {
    switch (role) {
        case REGISTERTATION_TYPES.JOB_SEEKER:
            if (
                user.personalInfo &&
                user.contactInformation &&
                user.educations.length > 0 &&
                user.personalPracticalExperiences &&
                user.languageExperiences.length > 0
            ) {
                return true;
            } else {
                return false;
            }
        case REGISTERTATION_TYPES.FREELANCER:
            if (
                user.personalInfo &&
                user.contactInformation &&
                user.educations.length > 0 &&
                user.personalPracticalExperiences &&
                user.languageExperiences.length > 0 &&
                user.freelanceService
            ) {
                return true;
            } else {
                return false;
            }
        case REGISTERTATION_TYPES.TRAINER:
            if (
                user.personalInfo &&
                user.contactInformation &&
                user.educations.length > 0 &&
                user.personalPracticalExperiences &&
                user.languageExperiences.length > 0 &&
                user.trainingAndCoaching
            ) {
                return true;
            } else {
                return false;
            }
        case REGISTERTATION_TYPES.ENTERPRISE:
            return false;
        default:
            return false;
    }
};

export const toBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
    });

export function convertBirthdateFormat(dateString) {
    // Split the input date string into parts
    const [year, month, day] = dateString.split("-");

    // Rearrange and join the parts into the desired format
    const formattedDate = `${day}/${month}/${year}`;

    return formattedDate;
}

export const calcAvgHourSalary = (salaries = []) => {
    if (salaries.length === 0) {
        return 0; // Return 0 if the array is empty to avoid division by zero
    }

    const totalSalary = salaries.reduce(
        (accumulator, currentSalary) => accumulator + currentSalary,
        0
    );
    const averageSalary = totalSalary / salaries.length;

    return averageSalary;
};

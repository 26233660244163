import { useTheme } from "@emotion/react";
import {
    Box,
    Grid,
    Paper,
    Stack,
    Typography,
    useMediaQuery,
} from "@mui/material";
import { LineChart } from "@mui/x-charts";
import { CaretDown } from "@phosphor-icons/react";
import React from "react";
import XCenter from "../../components/common/XCenter";

const ProfileVisitors = () => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

    return (
        <Box className="page">
            <Paper
                elevation={0}
                sx={{
                    padding: 4,
                    borderRadius: 4,
                    boxShadow: theme.palette.boxShadow.main1,
                }}
            >
                <Grid container>
                    <Grid item xs={12}>
                        <Grid
                            container
                            alignItems={"center"}
                            justifyContent={"space-between"}
                        >
                            <Grid item xs={12} sm={12} md={"auto"}>
                                <Typography
                                    component={"p"}
                                    fontSize={"2rem"}
                                    fontWeight={"bolder"}
                                >
                                    {"929"}
                                </Typography>
                                <Typography component={"p"} color={"gray"}>
                                    {"زيارات الحساب"}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={"auto"}>
                                <Stack
                                    direction={"row"}
                                    alignItems={"center"}
                                    gap={2}
                                >
                                    <Stack direction={"row"}>
                                        <CaretDown
                                            size={24}
                                            weight="fill"
                                            style={{
                                                color: theme.palette.danger
                                                    .main,
                                            }}
                                        />
                                        <Typography
                                            variant="heading2"
                                            sx={{
                                                color: theme.palette.danger
                                                    .main,
                                            }}
                                        >
                                            {"14%"}
                                        </Typography>
                                    </Stack>
                                    <Typography variant="heading2">
                                        {"الأسبوع الماضي"}
                                    </Typography>
                                </Stack>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <LineChart
                            xAxis={[{ data: [1, 2, 3, 5, 8, 10] }]}
                            series={[
                                {
                                    data: [2, 5.5, 2, 8.5, 1.5, 5],
                                },
                            ]}
                            sx={{
                                width: "100%",
                            }}
                            grid={{ horizontal: true }}
                            height={400}
                        />
                    </Grid>
                </Grid>
            </Paper>
            <Box marginTop={4}>
                <XCenter>
                    <Typography variant="heading2">
                        {"عدد المؤسسات التي زارت  حسابك"}
                    </Typography>
                </XCenter>
            </Box>
        </Box>
    );
};

export default ProfileVisitors;

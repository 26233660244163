import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import CertificatesAPI from "../../services/api/Certificates";
import { closeForm, openForm, resetAll, setAll } from "./certificatesForm";

const initialState = {
    all: [],
    current: null,
    loaders: {
        all: {
            loading: false,
            error: null,
        },
        current: {
            loading: false,
            error: null,
        },
    },
};

export const fetchAllCertificates = createAsyncThunk(
    "certificates/fetchAllCertificates",
    async () => {
        const result = await CertificatesAPI.getAll();
        return result;
    }
);

export const fetchOneCertificate = createAsyncThunk(
    "certificates/fetchOneCertificate",
    async ({ id }, { dispatch }) => {
        const result = await CertificatesAPI.getOne(id);
        dispatch(
            setAll({
                name: result.name,
                certificateInstitution: result.certificateInstitution,
                releaseDate: result.releaseDate,
                expirationDate: result.expirationDate,
                certificateUrl: result.certificateUrl,
            })
        );
        dispatch(openForm());
        return result;
    }
);

export const createCertificate = createAsyncThunk(
    "certificates/createCertificate",
    async ({ data }, { dispatch }) => {
        const result = await CertificatesAPI.create(data);
        dispatch(fetchAllCertificates());
        dispatch(resetAll());
        dispatch(closeForm());
        return result;
    }
);

export const updateCertificate = createAsyncThunk(
    "certificates/updateCertificate",
    async ({ id, data }, { dispatch }) => {
        const result = await CertificatesAPI.update(id, data);
        dispatch(fetchAllCertificates());
        dispatch(resetAll());
        dispatch(closeForm());
        return result;
    }
);

export const deleteCertificate = createAsyncThunk(
    "certificates/deleteCertificate",
    async ({ id }, { dispatch }) => {
        const result = await CertificatesAPI.delete(id);
        dispatch(fetchAllCertificates());
        dispatch(resetAll());
        return result;
    }
);

const slice = createSlice({
    name: "certificatesData",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllCertificates.pending, (state) => {
                state.loaders.all.loading = true;
                state.loaders.all.error = null;
            })
            .addCase(fetchAllCertificates.fulfilled, (state, action) => {
                state.loaders.all.loading = false;
                state.all = action.payload;
            })
            .addCase(fetchAllCertificates.rejected, (state, action) => {
                state.loaders.all.loading = true;
                state.loaders.all.error = action.message;
            })
            .addCase(fetchOneCertificate.pending, (state) => {
                state.loaders.current.loading = true;
                state.loaders.current.error = null;
            })
            .addCase(fetchOneCertificate.fulfilled, (state, action) => {
                state.loaders.current.loading = false;
                state.current = action.payload;
            })
            .addCase(fetchOneCertificate.rejected, (state, action) => {
                state.loaders.current.loading = true;
                state.loaders.current.error = action.message;
            })
            .addCase(updateCertificate.fulfilled, (state, action) => {
                state.current = null;
            })
            .addCase(deleteCertificate.fulfilled, (state, action) => {
                state.current = null;
            });
    },
});

export const {} = slice.actions;

export default slice.reducer;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { WorkFieldAPI } from "../../services/api/model";
import JobPositionLevelAPI from "../../services/api/JobPositionLevel";

const initialState = {
    // form values
    selectedWorkField: null,
    selectedJobPositionLevel: null,
    organization: null,
    startDate: null,
    endDate: null,
    current: false,
    responsibilities: null,
    achievements: null,
    errors: null,
    // available values
    workFields: [],
    jobPositionLevels: [],
    // loaders
    loaders: {
        workFields: {
            loading: false,
            error: null,
        },
        jobPositionLevels: {
            loading: false,
            error: null,
        },
    },
};

export const fetchAllWorkFields = createAsyncThunk(
    "workFields/fetchAllWorkFields",
    async () => {
        const result = await WorkFieldAPI.getAll();
        return result;
    }
);

export const fetchAllJobPositionLevels = createAsyncThunk(
    "jobPositionLevels/fetchAllJobPositionLevels",
    async () => {
        const result = await JobPositionLevelAPI.getAll();
        return result;
    }
);

const jobHistoryFormSlice = createSlice({
    name: "jobHistoryForm",
    initialState,
    reducers: {
        setSelectedWorkField(state, action) {
            state.selectedWorkField = action.payload;
        },
        setSelectedJobPositionLevel(state, action) {
            state.selectedJobPositionLevel = action.payload;
        },
        setOrganization(state, action) {
            state.organization = action.payload;
        },
        setStartDate(state, action) {
            state.startDate = action.payload.toISOString().split("T")[0];
        },
        setEndDate(state, action) {
            state.endDate = action.payload.toISOString().split("T")[0];
        },
        setCurrent(state, action) {
            state.current = action.payload;
        },
        setResponsibilities(state, action) {
            state.responsibilities = action.payload;
        },
        setAchievements(state, action) {
            state.achievements = action.payload;
        },
        setErrors(state, action) {
            state.errors = action.payload;
        },
        setAll(state, action) {
            state.selectedWorkField = action.payload.selectedWorkField;
            state.selectedJobPositionLevel =
                action.payload.selectedJobPositionLevel;
            state.organization = action.payload.organization;
            state.startDate = action.payload.startDate;
            state.endDate = action.payload.endDate;
            state.current = action.payload.current;
            state.responsibilities = action.payload.responsibilities;
            state.achievements = action.payload.achievements;
        },
        resetAll(state, action) {
            state.selectedWorkField = null;
            state.selectedJobPositionLevel = null;
            state.organization = null;
            state.startDate = null;
            state.endDate = null;
            state.current = false;
            state.responsibilities = null;
            state.achievements = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllWorkFields.pending, (state) => {
                state.loaders.workFields.loading = true;
                state.loaders.workFields.error = null;
            })
            .addCase(fetchAllWorkFields.fulfilled, (state, action) => {
                state.loaders.workFields.loading = false;
                state.workFields = action.payload;
            })
            .addCase(fetchAllWorkFields.rejected, (state, action) => {
                state.loaders.workFields.loading = true;
                state.loaders.workFields.error = action.message;
            })
            .addCase(fetchAllJobPositionLevels.pending, (state) => {
                state.loaders.jobPositionLevels.loading = true;
                state.loaders.jobPositionLevels.error = null;
            })
            .addCase(fetchAllJobPositionLevels.fulfilled, (state, action) => {
                state.loaders.jobPositionLevels.loading = false;
                state.jobPositionLevels = action.payload;
            })
            .addCase(fetchAllJobPositionLevels.rejected, (state, action) => {
                state.loaders.jobPositionLevels.loading = true;
                state.loaders.jobPositionLevels.error = action.message;
            });
    },
});

export const {
    setSelectedWorkField,
    setSelectedJobPositionLevel,
    setOrganization,
    setStartDate,
    setEndDate,
    setCurrent,
    setResponsibilities,
    setAchievements,
    setErrors,
    setAll,
    resetAll,
} = jobHistoryFormSlice.actions;

export default jobHistoryFormSlice.reducer;

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    createJobHistory,
    fetchAllJobHistories,
    fetchOneJobHistory,
    updateJobHistory,
} from "../../../../../store/slices/jobHistoryData";
import {
    fetchAllJobPositionLevels,
    fetchAllWorkFields,
    setErrors,
} from "../../../../../store/slices/jobHistoryForm";
import { PROFILE_MODES } from "../../../../../services/constants";

const useForm = () => {
    const dispatch = useDispatch();
    const userId = useSelector((state) => state.user.id);
    const personalPracticalExperienceId = useSelector(
        (state) => state.experiencesForm.id
    );
    const { mode } = useSelector((state) => state.personalInfo);
    const jobHistoryForm = useSelector((state) => state.jobHistoryForm);
    const jobHistoryData = useSelector((state) => state.jobHistoryData);

    useEffect(() => {
        dispatch(fetchAllJobHistories({ userId }));
        dispatch(fetchAllWorkFields());
        dispatch(fetchAllJobPositionLevels());
    }, [dispatch, userId]);

    useEffect(() => {
        if (mode == PROFILE_MODES.REGISTER && jobHistoryData.all.length > 0) {
            const id = jobHistoryData.all[0].id;
            dispatch(fetchOneJobHistory({ userId, id }));
        }
    }, [jobHistoryData.all]);

    const handleSubmit = async () => {
        // try {
        //     await validationSchema.validate(
        //         {
        //             spec: educationLevelForm.spec,
        //             university: educationLevelForm.university,
        //             level: educationLevelForm.level,
        //             graduated: educationLevelForm.graduated,
        //             gradDate: educationLevelForm.gradDate,
        //         },
        //         { abortEarly: false }
        //     );
        // } catch (error) {
        //     const errors = collectAllValidationErrors(error);
        //     dispatch(setErrors(errors));
        //     window.scrollTo(0, 0);
        //     toast.warn("اصلح الحقول المطلوبة");
        //     return;
        // }

        const data = {
            workFieldId: jobHistoryForm.selectedWorkField.id,
            jobPositionLevelId: jobHistoryForm.selectedJobPositionLevel.id,
            organization: jobHistoryForm.organization,
            startDate: jobHistoryForm.startDate,
            endDate: jobHistoryForm.endDate,
            current: jobHistoryForm.current,
            responsibilities:
                mode == PROFILE_MODES.REGISTER
                    ? " "
                    : jobHistoryForm.responsibilities,
            achievements:
                mode == PROFILE_MODES.REGISTER
                    ? " "
                    : jobHistoryForm.achievements,
            personalPracticalExperienceId,
        };

        if (jobHistoryData.current) {
            dispatch(
                updateJobHistory({
                    userId,
                    id: jobHistoryData.current.id,
                    data,
                })
            );
        } else {
            dispatch(
                createJobHistory({
                    userId,
                    data,
                })
            );
        }

        dispatch(setErrors(null));
    };
    return [jobHistoryForm, jobHistoryData, handleSubmit];
};

export default useForm;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
    CustomerTypeOptions,
    ExperienceTypeOptions,
    ServiceDeliveryTypeOptions,
} from "../../services/constants";
import { WorkFieldAPI } from "../../services/api/model";
import UploadAPI from "../../services/api/Upload";

const initialState = {
    experienceTypes: ExperienceTypeOptions,
    workFields: [],
    serviceDeliveryTypes: ServiceDeliveryTypeOptions,
    customerTypes: CustomerTypeOptions,
    customerNumber: 0,
    averageSalaryPerHour: 0,
    availabilityToTravel: false,
    providedHours: 0,
    hasRecordedTrainings: "no",
    recordedExerciseLink: null,
    link: "",
    contractedEntities: [],
    files: [],
    links: [],
    selectedExperienceType: null,
    selectedWorkField: null,
    selectedServiceDeliveryTypes: [],
    selectedCustomerTypes: [],
    closed: true,
    loaders: {
        workFields: {
            loading: false,
            error: null,
        },
        uploadFile: {
            loading: false,
            error: null,
        },
    },
};

export const fetchAllWorkFields = createAsyncThunk(
    "coaching/fetchAllWorkFields",
    async () => {
        const result = await WorkFieldAPI.getAll();
        return result;
    }
);

export const uploadFile = createAsyncThunk(
    "coaching/uploadFile",
    async ({ file, path }) => {
        const result = await UploadAPI.uploadFile(file, path);
        return result;
    }
);

const slice = createSlice({
    name: "coachingForm",
    initialState,
    reducers: {
        setExperienceTypes(state, action) {
            state.experienceTypes = action.payload;
        },
        setWorkFields(state, action) {
            state.workFields = action.payload;
        },
        setServiceDeliveryTypes(state, action) {
            state.serviceDeliveryTypes.forEach((item) => {
                if (item.value == action.payload) {
                    item.checked = !item.checked;
                }
            });
        },
        setCustomerTypes(state, action) {
            state.customerTypes.forEach((item) => {
                if (item.value == action.payload) {
                    item.checked = !item.checked;
                }
            });
        },
        setCustomerNumber(state, action) {
            const operation = action.payload.operation;
            if (operation == "inc") {
                state.customerNumber += 1;
            } else if (operation == "decr") {
                if (state.customerNumber > 0) state.customerNumber += -1;
            } else {
                const val =
                    action.payload.value == ""
                        ? 0
                        : parseInt(action.payload.value);
                if (!isNaN(val)) {
                    state.customerNumber = val;
                }
            }
        },
        setAverageSalaryPerHour(state, action) {
            state.averageSalaryPerHour = action.payload;
        },
        setAvailabilityToTravel(state, action) {
            state.availabilityToTravel = action.payload;
        },
        setProvidedHours(state, action) {
            const operation = action.payload.operation;
            if (operation == "inc") {
                state.providedHours += 1;
            } else if (operation == "decr") {
                if (state.providedHours > 0) state.providedHours += -1;
            } else {
                const val =
                    action.payload.value == ""
                        ? 0
                        : parseInt(action.payload.value);
                if (!isNaN(val)) {
                    state.providedHours = val;
                }
            }
        },
        setHasRecordedTrainings(state, action) {
            state.hasRecordedTrainings = action.payload;
        },
        setRecordedExerciseLink(state, action) {
            state.recordedExerciseLink = action.payload;
        },
        setContractedEntities(state, action) {
            state.contractedEntities = action.payload;
        },
        addFile(state, action) {
            state.files.push(action.payload);
        },
        removeFile(state, action) {
            state.files = state.files.filter((file) => file !== action.payload);
        },
        addLink(state, action) {
            state.links.push(action.payload);
        },
        setLink(state, action) {
            state.link = action.payload;
        },
        removeLink(state, action) {
            state.links = state.links.filter(
                (link, index) => index !== action.payload
            );
        },
        setAllFiles(state, action) {
            state.files = action.payload;
        },
        setAllLinks(state, action) {
            state.links = action.payload;
        },
        setSelectedExperienceType(state, action) {
            state.selectedExperienceType = action.payload;
        },
        setSelectedWorkField(state, action) {
            state.selectedWorkField = action.payload;
        },
        setSelectedServiceDeliveryTypes(state, action) {
            state.selectedServiceDeliveryTypes = action.payload;
        },
        setSelectedCustomerTypes(state, action) {
            state.selectedCustomerTypes = action.payload;
        },
        setErrors(state, action) {
            state.errors = action.payload;
        },
        openForm: (state) => {
            state.closed = false;
        },
        closeForm: (state) => {
            state.closed = true;
        },
        resetAll(state) {
            return initialState;
        },
        setAll(state, action) {
            console.log(action.payload);
            state.selectedExperienceType =
                action.payload.selectedExperienceType;
            state.selectedWorkField = action.payload.selectedWorkField;
            state.customerTypes = action.payload.customerTypes;
            state.customerNumber = action.payload.customerNumber;
            state.serviceDeliveryTypes = action.payload.serviceDeliveryTypes;
            state.averageSalaryPerHour = action.payload.averageSalaryPerHour;
            state.availabilityToTravel = action.payload.availabilityToTravel;
            state.providedHours = action.payload.providedHours;
            state.hasRecordedTrainings = action.payload.hasRecordedTrainings;
            state.recordedExerciseLink = action.payload.recordedExerciseLink;
            state.contractedEntities = action.payload.contractedEntities;
            state.files = action.payload.files;
            state.links = action.payload.links;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllWorkFields.pending, (state) => {
                state.loaders.workFields.loading = true;
                state.loaders.workFields.error = null;
            })
            .addCase(fetchAllWorkFields.fulfilled, (state, action) => {
                state.loaders.workFields.loading = false;
                state.workFields = action.payload;
            })
            .addCase(fetchAllWorkFields.rejected, (state, action) => {
                state.loaders.workFields.loading = false;
                state.loaders.workFields.error = action.error.message;
            })
            .addCase(uploadFile.pending, (state) => {
                state.loaders.uploadFile.loading = true;
                state.loaders.uploadFile.error = null;
            })
            .addCase(uploadFile.fulfilled, (state, action) => {
                state.loaders.uploadFile.loading = false;
                state.files.push(action.payload.url);
            })
            .addCase(uploadFile.rejected, (state, action) => {
                state.loaders.uploadFile.loading = true;
                state.loaders.uploadFile.error = action.message;
            });
    },
});

export const {
    setExperienceTypes,
    setWorkFields,
    setServiceDeliveryTypes,
    setCustomerTypes,
    setCustomerNumber,
    setAverageSalaryPerHour,
    setAvailabilityToTravel,
    setProvidedHours,
    setHasRecordedTrainings,
    setRecordedExerciseLink,
    setContractedEntities,
    addFile,
    removeFile,
    setLink,
    addLink,
    removeLink,
    setAllFiles,
    setAllLinks,
    setSelectedExperienceType,
    setSelectedWorkField,
    setSelectedServiceDeliveryTypes,
    setSelectedCustomerTypes,
    openForm,
    closeForm,
    setErrors,
    resetAll,
    setAll,
} = slice.actions;

export default slice.reducer;

import { useTheme } from "@emotion/react";
import {
    Autocomplete,
    Box,
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    TextField,
    Typography,
    useMediaQuery,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import React from "react";
import Loader from "../../../common/Loader";
import useForm from "./useForm";
import {
    setGradDate,
    setGraduated,
    setLevel,
    setSpec,
    setUniversity,
} from "../../../../store/slices/educationLevelForm";
import ValidationErrorMessage from "../../../common/ValidationErrorMessage";
import { useDispatch } from "react-redux";
import { DatePickerStyle } from "../../../../services/theme";
import dayjs from "dayjs";
import { setNext, setPrev } from "../../../../store/slices/stepper";
import XCenter from "../../../common/XCenter";

const Form = () => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const isMdScreen = useMediaQuery(theme.breakpoints.down("md"));

    const [educationLevelForm, educationLevelData, handleSubmit] = useForm();

    return (
        <Box component={"form"}>
            <Grid
                container
                direction={"row"}
                justifyContent={"center"}
                rowSpacing={5}
                columnSpacing={isMdScreen ? 0 : 10}
            >
                <Grid item xs={12}>
                    <Loader
                        loading={educationLevelForm.loaders.specs.loading}
                        error={educationLevelForm.loaders.specs.error}
                    >
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel shrink htmlFor="spec">
                                <Typography variant="heading2">
                                    {"التخصص *"}
                                </Typography>
                            </InputLabel>
                            <Autocomplete
                                variant="labeled"
                                id="spec"
                                value={educationLevelForm.spec}
                                options={educationLevelForm.specs}
                                getOptionLabel={(option) =>
                                    option ? option.nameAr : ""
                                }
                                isOptionEqualToValue={(option, value) =>
                                    option.id === value.id
                                }
                                onChange={(e, option) =>
                                    dispatch(setSpec(option))
                                }
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        placeholder="اختر من هنا"
                                    />
                                )}
                            />
                            <ValidationErrorMessage
                                errors={educationLevelForm.errors}
                                field={"spec"}
                            />
                        </FormControl>
                    </Loader>
                </Grid>
                <Grid item xs={12}>
                    <Loader
                        loading={
                            educationLevelForm.loaders.universities.loading
                        }
                        error={educationLevelForm.loaders.universities.error}
                    >
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel shrink htmlFor="university">
                                <Typography variant="heading2">
                                    {"الجامعة *"}
                                </Typography>
                            </InputLabel>
                            <Autocomplete
                                variant="labeled"
                                id="university"
                                value={educationLevelForm.university}
                                options={educationLevelForm.universities}
                                getOptionLabel={(option) =>
                                    option ? option.nameAr : ""
                                }
                                isOptionEqualToValue={(option, value) =>
                                    option.id === value.id
                                }
                                onChange={(e, option) =>
                                    dispatch(setUniversity(option))
                                }
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        placeholder="اختر من هنا"
                                    />
                                )}
                            />
                            <ValidationErrorMessage
                                errors={educationLevelForm.errors}
                                field={"university"}
                            />
                        </FormControl>
                    </Loader>
                </Grid>
                <Grid item xs={6}>
                    <Loader
                        loading={educationLevelForm.loaders.levels.loading}
                        error={educationLevelForm.loaders.levels.error}
                    >
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel shrink htmlFor="level">
                                <Typography variant="heading2">
                                    {"المستوى التعليمي *"}
                                </Typography>
                            </InputLabel>
                            <Autocomplete
                                variant="labeled"
                                id="level"
                                value={educationLevelForm.level}
                                options={educationLevelForm.levels}
                                getOptionLabel={(option) =>
                                    option ? option.nameAr : ""
                                }
                                isOptionEqualToValue={(option, value) =>
                                    option.id === value.id
                                }
                                onChange={(e, option) =>
                                    dispatch(setLevel(option))
                                }
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        placeholder="اختر من هنا"
                                    />
                                )}
                            />
                            <ValidationErrorMessage
                                errors={educationLevelForm.errors}
                                field={"level"}
                            />
                        </FormControl>
                    </Loader>
                </Grid>
                <Grid item xs={6}>
                    <Loader
                        loading={educationLevelForm.loaders.levels.loading}
                        error={educationLevelForm.loaders.levels.error}
                    >
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel shrink htmlFor="gradDate">
                                <Typography variant="heading2">
                                    {"تاريخ التخرج *"}
                                </Typography>
                            </InputLabel>
                            <DatePicker
                                id="gradDate"
                                sx={{
                                    ...DatePickerStyle,
                                }}
                                value={dayjs(educationLevelForm.gradDate)}
                                onChange={(value) =>
                                    dispatch(setGradDate(value))
                                }
                                disabled={educationLevelForm.graduated}
                            />
                            <ValidationErrorMessage
                                errors={educationLevelForm.errors}
                                field={"gradDate"}
                            />
                        </FormControl>
                    </Loader>
                </Grid>
                <Grid item xs={12}>
                    <Loader
                        loading={educationLevelForm.loaders.levels.loading}
                        error={educationLevelForm.loaders.levels.error}
                    >
                        <FormControl variant="outlined" fullWidth>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        value={!educationLevelForm.graduated}
                                        onChange={(e) =>
                                            dispatch(
                                                setGraduated(!e.target.checked)
                                            )
                                        }
                                    />
                                }
                                label="لم اتخرج بعد"
                            />
                            <ValidationErrorMessage
                                errors={educationLevelForm.errors}
                                field={"graduated"}
                            />
                        </FormControl>
                    </Loader>
                </Grid>
                <Grid item container>
                    <Grid item xs={12}>
                        <XCenter>
                            <Button
                                onClick={() => handleSubmit()}
                                sx={{
                                    textDecoration: "underline",
                                    fontSize: "1.2rem",
                                }}
                            >
                                {educationLevelData.current
                                    ? "تعديل"
                                    : "إضافة خبرة عملية أخرى"}
                            </Button>
                        </XCenter>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container justifyContent={"space-between"}>
                        <Grid item xs={3}>
                            <Button
                                fullWidth
                                variant="outlined"
                                sx={{ paddingY: 2 }}
                                onClick={() => dispatch(setPrev())}
                            >
                                {"السابق"}
                            </Button>
                        </Grid>
                        <Grid item xs={3}>
                            <Button
                                fullWidth
                                variant="main"
                                sx={{ paddingY: 2 }}
                                onClick={() => {
                                    dispatch(setNext());
                                }}
                            >
                                {"التالي"}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
};

export default Form;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { WorkFieldAPI } from "../../services/api/model";
import InterestsAPI from "../../services/api/Interests";

const initialState = {
    id: null,
    selectedWorkFields: [],
    workFields: [],
    loaders: {
        workFields: {
            loading: false,
            error: null,
        },
    },

    errors: null,
};

export const fetchAllWorkFields = createAsyncThunk(
    "interests/fetchAllWorkFields",
    async () => {
        const result = await WorkFieldAPI.getAll();
        return result;
    }
);

export const fetchInterests = createAsyncThunk(
    "interests/fetchInterests",
    async ({ userId }) => {
        console.log("testestestestestestest");
        const result = await InterestsAPI.get(userId);
        return result;
    }
);

export const createInterests = createAsyncThunk(
    "interests/createInterests",
    async ({ userId, data }, { dispatch }) => {
        const result = await InterestsAPI.create(userId, data);
        dispatch(fetchInterests(userId));
        return result;
    }
);

export const updateInterests = createAsyncThunk(
    "interests/updateInterests",
    async ({ userId, data }, { dispatch }) => {
        const result = await InterestsAPI.update(userId, data);
        dispatch(fetchInterests(userId));
        return result;
    }
);

const slice = createSlice({
    name: "interests",
    initialState,
    reducers: {
        setSelectedWorkFields: (state, action) => {
            state.selectedWorkFields = action.payload;
        },
        resetAll: (state) => {
            state.selectedWorkFields = [];
        },
        setErrors: (state, action) => {
            state.errors = action.payload;
        },
    },

    extraReducers: (builder) => {
        builder
            .addCase(fetchInterests.fulfilled, (state, action) => {
                if (action.payload) {
                    state.id = action.payload.id;
                    state.selectedWorkFields = action.payload.workFields;
                }
            })
            .addCase(fetchAllWorkFields.pending, (state) => {
                state.loaders.workFields.loading = true;
                state.loaders.workFields.error = null;
            })
            .addCase(fetchAllWorkFields.fulfilled, (state, action) => {
                state.loaders.workFields.loading = false;
                state.workFields = action.payload;
            })
            .addCase(fetchAllWorkFields.rejected, (state, action) => {
                state.loaders.workFields.loading = true;
                state.loaders.workFields.error = action.message;
            });
    },
});

export const { setSelectedWorkFields, resetAll, setErrors } = slice.actions;

export default slice.reducer;

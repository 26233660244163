import { useTheme } from "@emotion/react";
import { Grid, IconButton, Typography, useMediaQuery } from "@mui/material";
import { Box, Stack } from "@mui/system";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../../common/Loader";
import Form from "./Form";
import dayjs from "dayjs";
import { Pencil, Trash } from "@phosphor-icons/react";
import { PROFILE_MODES } from "../../../../../services/constants";
import {
    deleteJobHistory,
    fetchOneJobHistory,
} from "../../../../../store/slices/jobHistoryData";

const Item = ({
    id: itemId,
    startDate,
    endDate,
    current,
    workField,
    organization,
}) => {
    const dispatch = useDispatch();
    const userId = useSelector((state) => state.user.id);

    const theme = useTheme();
    const isMdScreen = useMediaQuery(theme.breakpoints.down("md"));

    return (
        <Box
            className="block"
            sx={{
                position: "relative",
                boxShadow: "0px 0px 10px 2px #0000000a;",
                padding: "1rem",
            }}
        >
            <Grid container rowSpacing={2} columnSpacing={isMdScreen ? 0 : 10}>
                <Grid item xs={12} sm={12} md={12}>
                    <Typography variant="heading2">
                        {/* {"التخصص: "} */}
                        <Typography variant="large">
                            {startDate} - {current ? "الآن" : endDate}
                        </Typography>
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                    <Typography variant="heading2">
                        {"المنصب الوظيفي: "}
                        <Typography variant="large">
                            {workField.nameAr}
                        </Typography>
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                    <Typography variant="heading3">{organization}</Typography>
                </Grid>
            </Grid>
            <Box
                sx={{
                    position: "absolute",
                    left: 0,
                    top: 0,
                }}
            >
                <Stack direction="row" spacing={1}>
                    <IconButton
                        onClick={() =>
                            dispatch(
                                deleteJobHistory({
                                    userId,
                                    id: itemId,
                                })
                            )
                        }
                    >
                        <Trash color="red" />
                    </IconButton>
                    <IconButton
                        onClick={() =>
                            dispatch(
                                fetchOneJobHistory({
                                    userId,
                                    id: itemId,
                                })
                            )
                        }
                    >
                        <Pencil style={{ color: theme.palette.primary.main }} />
                    </IconButton>
                </Stack>
            </Box>
        </Box>
    );
};

const List = () => {
    const { mode } = useSelector((state) => state.personalInfo);
    const { all, loaders } = useSelector((state) => state.jobHistoryData);

    const theme = useTheme();
    const isMdScreen = useMediaQuery(theme.breakpoints.down("md"));
    return (
        <Box className="block">
            <Stack gap={2}>
                {all.length > 0 ? (
                    <Typography variant="heading2" sx={{
                        fontWeight:700,
                        fontSize:'24px'
                    }}>
                        {"خبرات العمل و الوظائف"}
                    </Typography>
                ) : (
                    <></>
                )}
                {mode == PROFILE_MODES.UPDATE && (
                    <Loader
                        loading={loaders.all.loading}
                        error={loaders.all.error}
                        height={200}
                    >
                        {all.map((item) => (
                            <Box key={item.id}>
                                <Item
                                    id={item.id}
                                    startDate={item.startDate}
                                    endDate={item.endDate}
                                    current={item.current}
                                    workField={item.workField}
                                    organization={item.organization}
                                />
                            </Box>
                        ))}
                    </Loader>
                )}
                <Form />
            </Stack>
        </Box>
    );
};

export default List;

import {
    Box,
    Grid,
    Stack,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import React, { useState } from "react";

import IndividualImg from "../../../assets/img/individual.png";
import IndividualActiveImg from "../../../assets/img/individual-active.png";

import CompanyImg from "../../../assets/img/company.png";
import CompanyActiveImg from "../../../assets/img/company-active.png";
import { useDispatch, useSelector } from "react-redux";
import { setAccountType } from "../../../store/slices/register";

const TypeElement = ({ active, img, activeImg, text, OnItemClicked }) => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

    return (
        <Stack
            alignItems={"center"}
            onClick={OnItemClicked}
            gap={2}
            sx={{ cursor: "pointer" }}
        >
            <Box
                className={active ? "active" : ""}
                sx={{
                    boxShadow: "0px 0px 6px 6px #0000000a;",
                    borderRadius: 2,
                    padding: 1.5,
                    transition: "all ease .2s",
                    "&.active": {
                        boxShadow: "0px 0px 12px 12px #0000000a;",
                    },
                }}
            >
                <Box
                    component={"img"}
                    src={active ? activeImg : img}
                    sx={{
                        height: isSmallScreen ? "80px" : "125px",
                        width: isSmallScreen ? "80px" : "125px",
                        objectFit: "contain",
                        objectPosition: "center",
                        transition: "all 0.2s ease",
                        transform: active ? "scale(1.1)" : "scale(1)",
                    }}
                />
            </Box>
            <Typography variant="normal">{text}</Typography>
        </Stack>
    );
};

const AccountTypeBlock = () => {
    const dispatch = useDispatch();
    const { accountType } = useSelector((state) => state.register);

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

    return (
        <Box className={"block"} width={isSmallScreen ? "100%" : "500px"}>
            <Grid container direction={"column"} gap={2}>
                <Grid item>
                    <Typography variant="heading2">{"نوع الحساب"}</Typography>
                </Grid>
                <Grid item>
                    <Stack
                        direction={"row"}
                        justifyContent={"space-between"}
                        gap={3}
                    >
                        <Box>
                            <TypeElement
                                img={IndividualImg}
                                activeImg={IndividualActiveImg}
                                text={"أفراد"}
                                active={accountType == "individual"}
                                OnItemClicked={() =>
                                    dispatch(setAccountType("individual"))
                                }
                            />
                        </Box>
                        <Box>
                            <TypeElement
                                img={CompanyImg}
                                activeImg={CompanyActiveImg}
                                text={"مؤسسات"}
                                active={accountType == "company"}
                                OnItemClicked={() =>
                                    dispatch(setAccountType("company"))
                                }
                            />
                        </Box>
                    </Stack>
                </Grid>
            </Grid>
        </Box>
    );
};

export default AccountTypeBlock;

import {
    Box,
    Button,
    Checkbox,
    Chip,
    Divider,
    FormControlLabel,
    InputBase,
    Radio,
    RadioGroup,
    Slider,
    Stack,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import React from "react";
import {
    Autocomplete,
    FormControl,
    Grid,
    InputLabel,
    TextField,
    Typography,
} from "@mui/material";
import Loader from "../../../common/Loader";
import ValidationErrorMessage from "../../../common/ValidationErrorMessage";
import { useDispatch } from "react-redux";
import useForm from "./useForm";
import {
    openForm,
    setAvailabilityToTravel,
    setAverageSalaryPerHour,
    setContractedEntities,
    setCustomerNumber,
    setCustomerTypes,
    setHasRecordedTrainings,
    setProvidedHours,
    setRecordedExerciseLink,
    setSelectedExperienceType,
    setSelectedWorkField,
    setServiceDeliveryTypes,
} from "../../../../store/slices/coachingForm";
import XCenter from "../../../common/XCenter";
import { setNext, setPrev } from "../../../../store/slices/stepper";
import Files from "./Files";
import Links from "./Links";

const Form = () => {
    const dispatch = useDispatch();

    const theme = useTheme();
    const isMdScreen = useMediaQuery(theme.breakpoints.down("md"));

    const [coachingForm, coachingData, handleSubmit] = useForm();

    const handleAddOption = (event) => {
        if (event.key === "Enter" && event.target.value) {
            if (!coachingForm.contractedEntities.includes(event.target.value)) {
                dispatch(
                    setContractedEntities([
                        ...coachingForm.contractedEntities,
                        event.target.value,
                    ])
                );
            }
        }
    };

    const handleChipDelete = (chipToDelete) => {
        dispatch(
            setContractedEntities(
                coachingForm.contractedEntities.filter(
                    (chip) => chip !== chipToDelete
                )
            )
        );
    };

    return (
        <Box component={"form"}>
            <Grid
                container
                direction={"row"}
                justifyContent={"center"}
                rowSpacing={5}
                columnSpacing={isMdScreen ? 0 : 10}
            >
                <Grid item xs={12} sm={12} md={12}>
                    <Loader loading={false} error={null}>
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel shrink htmlFor="experienceTypes">
                                <Typography variant="heading2">
                                    {"نوع الخبرة *"}
                                </Typography>
                            </InputLabel>
                            <Autocomplete
                                variant="labeled"
                                id="experienceTypes"
                                value={coachingForm.selectedExperienceType}
                                options={coachingForm.experienceTypes}
                                getOptionLabel={(option) =>
                                    option ? option.label : ""
                                }
                                isOptionEqualToValue={(option, value) =>
                                    option.value === value.value
                                }
                                onChange={(e, option) =>
                                    dispatch(setSelectedExperienceType(option))
                                }
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        placeholder="اختر من هنا"
                                    />
                                )}
                            />
                            <ValidationErrorMessage
                                errors={coachingForm.errors}
                                field={"experienceTypes"}
                            />
                        </FormControl>
                    </Loader>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    <Loader
                        loading={coachingForm.loaders.workFields.loading}
                        error={coachingForm.loaders.workFields.error}
                    >
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel shrink htmlFor="selectedWorkField">
                                <Typography variant="heading2">
                                    {"الموضوع الرئيسي الذي تقدمه *"}
                                </Typography>
                            </InputLabel>
                            <Autocomplete
                                variant="labeled"
                                id="selectedWorkField"
                                value={coachingForm.selectedWorkField}
                                options={coachingForm.workFields}
                                getOptionLabel={(option) =>
                                    option ? option.nameAr : ""
                                }
                                isOptionEqualToValue={(option, value) =>
                                    option.id === value.id
                                }
                                onChange={(e, option) =>
                                    dispatch(setSelectedWorkField(option))
                                }
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        placeholder="اختر من هنا"
                                    />
                                )}
                            />
                            <ValidationErrorMessage
                                errors={null}
                                field={"selectedWorkField"}
                            />
                        </FormControl>
                    </Loader>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    <FormControl variant="outlined" fullWidth>
                        <InputLabel shrink htmlFor="experienceYears">
                            <Typography variant="heading2">
                                {"عدد الساعات التي قدمتها *"}
                            </Typography>
                        </InputLabel>
                        <Stack direction={"row"} gap={1} marginTop={6}>
                            <Button
                                variant="main"
                                sx={{
                                    borderRadius: 0,
                                }}
                                onClick={() =>
                                    dispatch(
                                        setProvidedHours({
                                            operation: "inc",
                                        })
                                    )
                                }
                            >
                                <Typography sx={{ transform: "scale(2)" }}>
                                    +
                                </Typography>
                            </Button>
                            <TextField
                                id="providedHours"
                                value={coachingForm.providedHours}
                                onChange={(e) =>
                                    dispatch(
                                        setProvidedHours({
                                            value: e.target.value,
                                        })
                                    )
                                }
                                sx={{
                                    width: "75px",
                                    "& .MuiInputBase-input": {
                                        textAlign: "center",
                                    },
                                }}
                            />
                            <Button
                                variant="main"
                                sx={{
                                    borderRadius: 0,
                                }}
                                onClick={() =>
                                    dispatch(
                                        setProvidedHours({
                                            operation: "decr",
                                        })
                                    )
                                }
                            >
                                <Typography sx={{ transform: "scale(2)" }}>
                                    -
                                </Typography>
                            </Button>
                        </Stack>
                        <ValidationErrorMessage
                            errors={coachingForm.errors}
                            field={"providedHours"}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                    <Loader loading={false} error={null}>
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel shrink htmlFor="customerTypes">
                                <Typography variant="heading2">
                                    {"نوع عملائك *"}
                                </Typography>
                            </InputLabel>
                        </FormControl>
                        <Stack direction={"row"} marginTop={3}>
                            {coachingForm.customerTypes.map((item) => (
                                <FormControl variant="outlined">
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={item.checked}
                                                value={item.checked}
                                                onChange={(e) =>
                                                    dispatch(
                                                        setCustomerTypes(
                                                            item.value
                                                        )
                                                    )
                                                }
                                            />
                                        }
                                        label={item.label}
                                    />
                                    <ValidationErrorMessage
                                        errors={coachingForm.errors}
                                        field={"customerTypes"}
                                    />
                                </FormControl>
                            ))}
                        </Stack>
                    </Loader>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    <Loader loading={false} error={null}>
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel shrink htmlFor="serviceDeliveryTypes">
                                <Typography variant="heading2">
                                    {"منهجية تقديم الخدمة *"}
                                </Typography>
                            </InputLabel>
                        </FormControl>
                        <Stack direction={"row"} marginTop={3}>
                            {coachingForm.serviceDeliveryTypes.map((item) => (
                                <FormControl variant="outlined">
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={item.checked}
                                                value={item.checked}
                                                onChange={(e) =>
                                                    dispatch(
                                                        setServiceDeliveryTypes(
                                                            item.value
                                                        )
                                                    )
                                                }
                                            />
                                        }
                                        label={item.label}
                                    />
                                    <ValidationErrorMessage
                                        errors={coachingForm.errors}
                                        field={"serviceDeliveryTypes"}
                                    />
                                </FormControl>
                            ))}
                        </Stack>
                    </Loader>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    <FormControl variant="outlined" fullWidth>
                        <InputLabel shrink htmlFor="experienceYears">
                            <Typography variant="heading2">
                                {"عدد عملائك *"}
                            </Typography>
                        </InputLabel>
                        <Stack direction={"row"} gap={1} marginTop={6}>
                            <Button
                                variant="main"
                                sx={{
                                    borderRadius: 0,
                                }}
                                onClick={() =>
                                    dispatch(
                                        setCustomerNumber({
                                            operation: "inc",
                                        })
                                    )
                                }
                            >
                                <Typography sx={{ transform: "scale(2)" }}>
                                    +
                                </Typography>
                            </Button>
                            <TextField
                                id="providedHours"
                                value={coachingForm.customerNumber}
                                onChange={(e) =>
                                    dispatch(
                                        setCustomerNumber({
                                            value: e.target.value,
                                        })
                                    )
                                }
                                sx={{
                                    width: "75px",
                                    "& .MuiInputBase-input": {
                                        textAlign: "center",
                                    },
                                }}
                            />
                            <Button
                                variant="main"
                                sx={{
                                    borderRadius: 0,
                                }}
                                onClick={() =>
                                    dispatch(
                                        setCustomerNumber({
                                            operation: "decr",
                                        })
                                    )
                                }
                            >
                                <Typography sx={{ transform: "scale(2)" }}>
                                    -
                                </Typography>
                            </Button>
                        </Stack>
                        <ValidationErrorMessage
                            errors={coachingForm.errors}
                            field={"providedHours"}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <Divider />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    <FormControl variant="outlined" fullWidth>
                        <InputLabel shrink htmlFor="averageSalaryPerHour">
                            <Typography variant="heading2">
                                {"متوسط الأجر في الساعة $ *"}
                            </Typography>
                        </InputLabel>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    <div dir="ltr">
                        <Slider
                            aria-label="averageSalaryPerHour"
                            value={coachingForm.averageSalaryPerHour}
                            onChange={(e, value) =>
                                dispatch(setAverageSalaryPerHour(value))
                            }
                            getAriaValueText={(value) => `${value} $`}
                            getAriaLabel={(value) => `${value} $`}
                            valueLabelDisplay="on"
                            step={1}
                            min={0}
                            max={100}
                        />
                    </div>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                    <Loader loading={false} error={null}>
                        <FormControl variant="outlined" fullWidth>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={
                                            coachingForm.availabilityToTravel
                                        }
                                        value={
                                            coachingForm.availabilityToTravel
                                        }
                                        onChange={(e) =>
                                            dispatch(
                                                setAvailabilityToTravel(
                                                    e.target.checked
                                                )
                                            )
                                        }
                                    />
                                }
                                label={"قابلية السفر لأجل التدريب/الاستشارات"}
                                sx={{
                                    "& .MuiTypography-root": {
                                        color: theme.palette.primary.main,
                                        fontSize: "1.25rem",
                                        fontWeight: "500",
                                    },
                                }}
                            />
                            <ValidationErrorMessage
                                errors={coachingForm.errors}
                                field={"availabilityToTravel"}
                            />
                        </FormControl>
                    </Loader>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                    <Loader loading={false} error={null}>
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel shrink htmlFor="recordedExerciseLink">
                                <Typography variant="heading2">
                                    {"هل لديك تدريبات مسجلة؟ *"}
                                </Typography>
                            </InputLabel>
                            <RadioGroup
                                row
                                name="recordedExerciseLink"
                                sx={{
                                    marginTop: 5,
                                }}
                                onChange={(event) =>
                                    dispatch(
                                        setHasRecordedTrainings(
                                            event.target.value
                                        )
                                    )
                                }
                                value={coachingForm.hasRecordedTrainings}
                            >
                                <FormControlLabel
                                    value={"yes"}
                                    control={<Radio />}
                                    label="نعم"
                                    sx={{
                                        "& .MuiTypography-root": {
                                            color: theme.palette.primary.main,
                                            fontSize: "1.25rem",
                                            fontWeight: "500",
                                        },
                                    }}
                                />
                                <FormControlLabel
                                    value={"no"}
                                    control={<Radio />}
                                    label="لا"
                                    sx={{
                                        "& .MuiTypography-root": {
                                            color: theme.palette.primary.main,
                                            fontSize: "1.25rem",
                                            fontWeight: "500",
                                        },
                                    }}
                                />
                            </RadioGroup>
                        </FormControl>
                    </Loader>
                </Grid>
                {coachingForm.hasRecordedTrainings == "yes" && (
                    <Grid item xs={12} sm={12} md={12}>
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel shrink htmlFor="recordedExerciseLink">
                                <Typography variant="heading2">
                                    {"أضف رابط التريب المسجل *"}
                                </Typography>
                            </InputLabel>
                            <InputBase
                                variant="labeled"
                                placeholder="اكتب هنا"
                                id="recordedExerciseLink"
                                onChange={(e) =>
                                    dispatch(
                                        setRecordedExerciseLink(e.target.value)
                                    )
                                }
                                defaultValue={coachingForm.recordedExerciseLink}
                            />
                        </FormControl>
                    </Grid>
                )}
                <Grid item xs={12} sm={12} md={12}>
                    <Loader loading={false} error={null}>
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel shrink htmlFor="contractedEntities">
                                <Typography variant="heading2">
                                    {"الجهات التي تعاقدت معها"}
                                </Typography>
                            </InputLabel>
                            <Autocomplete
                                multiple
                                variant="labeled"
                                id="contractedEntities"
                                value={
                                    coachingForm.contractedEntities
                                        ? coachingForm.contractedEntities
                                        : []
                                }
                                options={
                                    coachingForm.contractedEntities
                                        ? coachingForm.contractedEntities
                                        : []
                                }
                                onChange={(e, option) =>
                                    dispatch(setSelectedExperienceType(option))
                                }
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        placeholder="اختر من هنا"
                                        onKeyDown={handleAddOption}
                                    />
                                )}
                                renderTags={(value, getTagProps) =>
                                    coachingForm.contractedEntities.map(
                                        (option, index) => (
                                            <Chip
                                                variant="outlined"
                                                label={option}
                                                {...getTagProps({ index })}
                                                onDelete={() =>
                                                    handleChipDelete(option)
                                                }
                                            />
                                        )
                                    )
                                }
                            />
                            <ValidationErrorMessage
                                errors={coachingForm.errors}
                                field={"contractedEntities"}
                            />
                        </FormControl>
                    </Loader>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                    <FormControl variant="outlined" fullWidth>
                        <InputLabel shrink>
                            <Typography
                                sx={{
                                    fontWeight: 800,
                                    color: theme.palette.primary.main,
                                    fontSize: "1.25rem",
                                }}
                            >
                                {"المستندات الداعمة"}
                            </Typography>
                        </InputLabel>
                    </FormControl>
                </Grid>
                <Files />
                <Links />
            </Grid>
            <Grid item container marginTop={5}>
                <Grid item xs={12}>
                    <XCenter>
                        <Button
                            onClick={() => {
                                if (coachingForm.closed === true) {
                                    dispatch(openForm());
                                } else {
                                    handleSubmit();
                                }
                            }}
                            sx={{
                                textDecoration: "underline",
                                fontSize: "1.2rem",
                            }}
                        >
                            {coachingData.current
                                ? "تعديل"
                                : "إضافة خبرة تدريب / استشارات أخرى"}
                        </Button>
                    </XCenter>
                </Grid>
            </Grid>
            <Grid item container marginTop={5}>
                <Grid item xs={12}>
                    <Grid container justifyContent={"space-between"}>
                        <Grid item xs={3}>
                            <Button
                                fullWidth
                                variant="outlined"
                                sx={{ paddingY: 2 }}
                                onClick={() => dispatch(setPrev())}
                            >
                                {"السابق"}
                            </Button>
                        </Grid>
                        <Grid item xs={3}>
                            <Button
                                fullWidth
                                variant="main"
                                sx={{ paddingY: 2 }}
                                onClick={() => {
                                    dispatch(setNext());
                                }}
                            >
                                {"التالي"}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
};

export default Form;

// src/slices/birthdateSlice.js

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BirthdateGenerator } from "../../services/utils";
import { LanguageAPI } from "../../services/api/model";
import { LanguageLevelOptions } from "../../services/constants";
import LanguageExperienceAPI from "../../services/api/languageExperience";

const initialState = {
    // form values
    lang: null,
    level: null,
    current: null,
    openedForm: null,
    errors: null,
    // available values
    languages: [],
    levels: LanguageLevelOptions,
    // loaders
    loaders: {
        allLanguages: {
            loading: false,
            error: null,
        },
        oneLanguage: {
            loading: false,
            error: null,
        },
    },
};

export const fetchAllLanguages = createAsyncThunk(
    "languages/fetchAllLanguages",
    async () => {
        const result = await LanguageAPI.getAll();
        return result;
    }
);

const languageExperiencesFormSlice = createSlice({
    name: "languageExperiencesForm",
    initialState,
    reducers: {
        setLang: (state, action) => {
            state.lang = action.payload;
        },
        setLevel: (state, action) => {
            state.level = action.payload;
        },
        setCurrent: (state, action) => {
            state.current = action.payload;
        },
        setOpenedForm: (state, action) => {
            state.openedForm = action.payload;
        },
        setErrors: (state, action) => {
            state.errors = action.payload;
        },
        setAll: (state, action) => {
            state.lang = action.payload.lang;
            state.level = action.payload.level;
            state.current = action.payload.current;
        },
        resetAll: (state) => {
            state.lang = null;
            state.level = null;
            state.current = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllLanguages.pending, (state) => {
                state.loaders.allLanguages.loading = true;
                state.loaders.allLanguages.error = null;
            })
            .addCase(fetchAllLanguages.fulfilled, (state, action) => {
                state.loaders.allLanguages.loading = false;
                state.languages = action.payload;
            })
            .addCase(fetchAllLanguages.rejected, (state, action) => {
                state.loaders.allLanguages.loading = true;
                state.loaders.allLanguages.error = action.message;
            });
    },
});

export const {
    setLang,
    setLevel,
    setCurrent,
    setOpenedForm,
    setErrors,
    setAll,
    resetAll,
} = languageExperiencesFormSlice.actions;

export default languageExperiencesFormSlice.reducer;

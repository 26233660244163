import { CITIES, COUNTRIES } from "../constants";
import API from "./Api";

export class NationalityAPI {
    static async getAll() {
        const result = await API.get("/nationality");
        return result;
    }

    static async getOne(id) {
        const result = await API.get(`/nationality/${id}`);
        return result;
    }
}

export class LanguageAPI {
    static async getAll() {
        const result = await API.get("/language");
        return result;
    }

    static async getOne(id) {
        const result = await API.get(`/language/${id}`);
        return result;
    }
}

export class UniversityAPI {
    static async getAll() {
        const result = await API.get("/college");
        return result;
    }

    static async getOne(id) {
        const result = await API.get(`/college/${id}`);
        return result;
    }
}

export class SpecAPI {
    static async getAll() {
        const result = await API.get("/specialization");
        return result;
    }

    static async getOne(id) {
        const result = await API.get(`/specialization/${id}`);
        return result;
    }
}

export class EducationLevelAPI {
    static async getAll() {
        const result = await API.get("/education-level");
        return result;
    }

    static async getOne(id) {
        const result = await API.get(`/education-level/${id}`);
        return result;
    }
}

export class WorkFieldAPI {
    static async getAll() {
        const result = await API.get("/work-fields");
        return result;
    }

    static async getOne(id) {
        const result = await API.get(`/work-fields/${id}`);
        return result;
    }
}

export class CurrencyAPI {
    static async getAll() {
        const result = await API.get("/currencies");
        return result;
    }

    static async getOne(id) {
        const result = await API.get(`/currencies/${id}`);
        return result;
    }
}

export class FreelanceWorkFieldAPI {
    static async getAll() {
        const result = await API.get("/freelance-work-field");
        return result;
    }

    static async getOne(id) {
        const result = await API.get(`/freelance-work-field/${id}`);
        return result;
    }
}

export class SkillAPI {
    static async getAll() {
        const result = await API.get("/skills");
        return result;
    }

    static async getOne(id) {
        const result = await API.get(`/skills/${id}`);
        return result;
    }
}

export class TheoreticalKnowledgeAPI {
    static async getAll() {
        const result = await API.get("/theoretical-knowledge");
        return result;
    }

    static async getOne(id) {
        const result = await API.get(`/theoretical-knowledge/${id}`);
        return result;
    }
}

export class CountryAPI {
    static async getAll() {
        const result = await API.get("/country");
        return result;
    }

    static async getOne(id) {
        const result = await API.get(`/country/${id}`);
        return result;
    }
}

export class CityAPI {
    static async getAll(country_id) {
        const result = await API.get("/city");
        return result.filter((item) => item.country.id == country_id);
    }

    static async getOne(id) {
        const result = await API.get(`/city/${id}`);
        return result;
    }
}

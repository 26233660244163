import React, { useEffect } from "react";
import {
    createServices,
    fetchAllCurrencies,
    fetchAllWorkFields,
    fetchServices,
    setErrors,
    updateServices,
} from "../../../../store/slices/servicesForm";
import { useDispatch, useSelector } from "react-redux";

const useForm = () => {
    const dispatch = useDispatch();
    const servicesForm = useSelector((state) => state.servicesForm);

    useEffect(() => {
        dispatch(fetchAllWorkFields());
        dispatch(fetchAllCurrencies());
        dispatch(fetchServices());
    }, [dispatch]);

    const handleSubmit = async () => {
        // try {
        //     await validationSchema.validate(
        //         {
        //             firstNameAr: services.firstNameAr,
        //             lastNameAr: services.lastNameAr,
        //             firstNameEn: services.firstNameEn,
        //             lastNameEn: services.lastNameEn,
        //             year: services.year,
        //             month: services.month,
        //             day: services.day,
        //             gender: services.gender,
        //             nationality1: services.nationality1,
        //         },
        //         { abortEarly: false }
        //     );
        // } catch (error) {
        //     const errors = collectAllValidationErrors(error);
        //     dispatch(setErrors(errors));
        //     window.scrollTo(0, 0);
        //     toast.warn("اصلح الحقول المطلوبة");
        //     return;
        // }

        const data = {
            freelanceWorkFieldIds: servicesForm.selectedFreelanceWorkField.map(
                (item) => item.id
            ),
            description: servicesForm.description,
            availableToWorkRemotely: servicesForm.availableToWorkRemotely,
            contactToPrice: servicesForm.contactToPrice,
            currencyId: servicesForm.selectedCurrency.id,
            price: parseFloat(servicesForm.price),
            files: servicesForm.files,
            links: servicesForm.links,
        };

        if (servicesForm.id) {
            dispatch(
                updateServices({
                    id: servicesForm.id,
                    data,
                })
            );
        } else {
            dispatch(
                createServices({
                    data,
                })
            );
        }

        dispatch(setErrors(null));
    };

    return [servicesForm, handleSubmit];
};

export default useForm;

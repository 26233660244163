import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    createExperience,
    fetchAllExperiences,
    fetchAllSkills,
    fetchAllTheoreticalKnowledges,
    fetchAllWorkFields,
    setErrors,
    updateExperience,
} from "../../../../store/slices/experiencesForm";
import validationSchema from "./validationSchema";
import { collectAllValidationErrors } from "../../../../services/utils";
import { toast } from "react-toastify";

const useForm = () => {
    const dispatch = useDispatch();
    const userId = useSelector((state) => state.user.id);
    const experiencesForm = useSelector((state) => state.experiencesForm);

    useEffect(() => {
        dispatch(fetchAllExperiences({ userId }));
        dispatch(fetchAllSkills());
        dispatch(fetchAllWorkFields());
        dispatch(fetchAllTheoreticalKnowledges());
    }, [dispatch, userId]);

    const handleSubmit = async () => {
        try {
            await validationSchema.validate(
                {
                    workField: experiencesForm.selectedWorkField,
                    experienceYears: experiencesForm.experienceYears,
                    skills: experiencesForm.selectedSkills.map(
                        (item) => item.id
                    ),
                    theoreticalKnowledge:
                        experiencesForm.selectedTheoreticalKnowledge.map(
                            (item) => item.id
                        ),
                    cv: experiencesForm.cv,
                    cvFile: experiencesForm.cvFile,
                    availabilityToTravel: experiencesForm.availabilityToTravel,
                    whenCanStart: experiencesForm.whenCanStart,
                },
                { abortEarly: false }
            );
        } catch (error) {
            const errors = collectAllValidationErrors(error);
            dispatch(setErrors(errors));
            window.scrollTo(0, 0);
            toast.warn("اصلح الحقول المطلوبة");
            return;
        }

        const data = {
            workFieldId: experiencesForm.selectedWorkField.id,
            yearsOfExperience: experiencesForm.experienceYears,
            resume: experiencesForm.cv,
            whenCanStart: experiencesForm.whenCanStart,
            availabilityToTravel: experiencesForm.availabilityToTravel,
            skills: experiencesForm.selectedSkills.map((item) => item.id),
            theoreticalKnowledge:
                experiencesForm.selectedTheoreticalKnowledge.map(
                    (item) => item.id
                ),
        };

        if (experiencesForm.id) {
            dispatch(
                updateExperience({
                    userId,
                    id: experiencesForm.id,
                    data,
                })
            );
        } else {
            dispatch(
                createExperience({
                    userId,
                    data,
                })
            );
        }

        dispatch(setErrors(null));
    };
    return [experiencesForm, handleSubmit];
};

export default useForm;

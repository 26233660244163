import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    createInterests,
    fetchAllWorkFields,
    fetchInterests,
    setErrors,
    updateInterests,
} from "../../../../store/slices/interests";

const useForm = () => {
    const dispatch = useDispatch();
    const interests = useSelector((state) => state.interests);
    const userId = useSelector((state) => state.user.id);

    useEffect(() => {
        dispatch(fetchAllWorkFields());
        console.log('temp1');
        dispatch(fetchInterests({ userId }));console.log('temp2');
    }, []);

    const handleSubmit = async () => {
        // try {
        //     await validationSchema.validate(
        //         {
        //             firstNameAr: personalInfo.firstNameAr,
        //             lastNameAr: personalInfo.lastNameAr,
        //             firstNameEn: personalInfo.firstNameEn,
        //             lastNameEn: personalInfo.lastNameEn,
        //             year: personalInfo.year,
        //             month: personalInfo.month,
        //             day: personalInfo.day,
        //             gender: personalInfo.gender,
        //             nationality1: personalInfo.nationality1,
        //         },
        //         { abortEarly: false }
        //     );
        // } catch (error) {
        //     const errors = collectAllValidationErrors(error);
        //     dispatch(setErrors(errors));
        //     window.scrollTo(0, 0);
        //     toast.warn("اصلح الحقول المطلوبة");
        //     return;
        // }

        const data = {
            workFieldIds: interests.selectedWorkFields.map(
                (item) => item.id
            ),
        };

        if (interests.id) {
            dispatch(
                updateInterests({
                    userId,
                    data,
                })
            );
        } else {
            dispatch(
                createInterests({
                    userId,
                    data,
                })
            );
        }

        dispatch(setErrors(null));
    };

    return [interests, handleSubmit];
};

export default useForm;

import { useTheme } from "@emotion/react";
import {
    Box,
    Grid,
    IconButton,
    Link as MuiLink,
    Stack,
    Typography,
    useMediaQuery,
} from "@mui/material";
import { Pencil, Trash } from "@phosphor-icons/react";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../common/Loader";
import dayjs from "dayjs";
import {
    deleteCertificate,
    fetchOneCertificate,
} from "../../../../store/slices/certificatesData";
import { Link } from "react-router-dom";
import {
    deleteReference,
    fetchOneReference,
} from "../../../../store/slices/referencesData";
import CheckMark from "../../../../assets/svg/checkMark.svg";
const Item = ({
    id: itemId,
    name,
    relationship,
    jobPosition,
    email,
    phone,
    scrollRef,
}) => {
    const dispatch = useDispatch();

    const theme = useTheme();
    const isMdScreen = useMediaQuery(theme.breakpoints.down("md"));

    return (
        <Box
            className="block"
            sx={{
                position: "relative",
                boxShadow: "0px 0px 10px 2px #0000000a;",
                padding: "1rem",
            }}
        >
            <Grid container rowSpacing={2} columnSpacing={isMdScreen ? 0 : 10}>
                <Grid item xs={12} sm={6} md={6}>
                    <Stack direction={"row"} gap={1} alignItems={"center"}>
                        <Typography variant="heading2">
                            {"اسم المرجع: "}
                            <Typography variant="large">{name}</Typography>
                        </Typography>
                        <Box
                            component={"img"}
                            src={CheckMark}
                            sx={{
                                objectFit: "contain",
                                objectPosition: "center",
                                height: "25px",
                            }}
                        />
                    </Stack>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <Typography variant="heading2">
                        {"العلاقة: "}
                        <Typography variant="large">{relationship}</Typography>
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                    <Typography variant="heading2">
                        {"الموضع في ذاك الوقت: "}
                        <Typography variant="large">
                            {jobPosition.nameAr}
                        </Typography>
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                    <Typography variant="heading2">
                        {"البريد الالكتروني: "}
                        <Typography variant="large">{email}</Typography>
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                    <Typography variant="heading2">
                        {"رقم الهاتف: "}
                        <Typography variant="large">{phone}</Typography>
                    </Typography>
                </Grid>
            </Grid>
            <Box
                sx={{
                    position: "absolute",
                    left: 0,
                    top: 0,
                }}
            >
                <Stack direction="row" spacing={1}>
                    <IconButton
                        onClick={() =>
                            dispatch(
                                deleteReference({
                                    id: itemId,
                                })
                            )
                        }
                    >
                        <Trash color="red" />
                    </IconButton>
                    <IconButton
                        onClick={() => {
                            dispatch(
                                fetchOneReference({
                                    id: itemId,
                                })
                            );
                            scrollRef.current.scrollIntoView();
                        }}
                    >
                        <Pencil style={{ color: theme.palette.primary.main }} />
                    </IconButton>
                </Stack>
            </Box>
        </Box>
    );
};

const List = ({ scrollRef }) => {
    const { all, loaders } = useSelector((state) => state.referencesData);
    const theme = useTheme();
    const isMdScreen = useMediaQuery(theme.breakpoints.down("md"));

    return (
        <Box className="block">
            <Stack gap={2}>
                <Loader
                    loading={loaders.all.loading}
                    error={loaders.all.error}
                    height={200}
                >
                    {all.map((item) => (
                        <Box key={item.id}>
                            <Item
                                id={item.id}
                                name={item.name}
                                relationship={item.relationship}
                                jobPosition={item.jobPosition}
                                email={item.email}
                                phone={`${item.countryCode}${item.phoneNumber}`}
                                scrollRef={scrollRef}
                            />
                        </Box>
                    ))}
                </Loader>
            </Stack>
        </Box>
    );
};

export default List;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    showPassword: false,
    email: null,
    password: null,
};

const slice = createSlice({
    name: "login",
    initialState,
    reducers: {
        setShowPassword: (state, action) => {
            state.showPassword = action.payload;
        },
        setEmail: (state, action) => {
            state.email = action.payload;
        },
        setPassword: (state, action) => {
            state.password = action.payload;
        },
        resetLogin: (state) => {
            state.showPassword = false;
            state.email = null;
            state.password = null;
        },
    },
});

export const { setShowPassword, setEmail, setPassword, resetLogin } =
    slice.actions;

export default slice.reducer;

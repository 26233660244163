import { createTheme } from "@mui/material/styles";

const theme = createTheme({
    direction: "rtl",
    breakpoints: {
        values: {
            xs: 320,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1536,
        },
    },
    palette: {
        mode: "light",
        primary: {
            main: "#1C3664",
            second: "rgb(26,35,75)",
            transparent: "rgba(26,43,83,0.3)",
        },
        secondary: {
            main: "#A3C4FF66",
        },
        danger: {
            main: "rgb(231,86,73)",
            second: "rgb(231,80,70)",
        },
        boxShadow: {
            main1: `0px 0px 20px 2px rgba(26,43,83,0.1)`,
        },
    },
    typography: {
        fontFamily: '"Cairo", sans-serif',
    },
    components: {
        MuiTypography: {
            variants: [
                {
                    props: { variant: "heading1" },
                    style: ({ theme }) => ({
                        color: theme.palette.primary.main,
                        fontSize: "48px",
                        fontWeight: 400,
                        lineHeight: "89.95px",
                    }),
                },
                {
                    props: { variant: "heading2" },
                    style: ({ theme }) => ({
                        color: theme.palette.primary.main,
                        fontSize: "1.25rem",
                        fontWeight: "500",
                    }),
                },
                {
                    props: { variant: "large" },
                    style: ({ theme }) => ({
                        color: theme.palette.primary.main,
                        fontSize: "1.25rem",
                        fontWeight: "normal",
                    }),
                },
                {
                    props: { variant: "normal" },
                    style: ({ theme }) => ({
                        color: "#000",
                        fontSize: "1rem",
                        fontWeight: "normal",
                    }),
                },
                {
                    props: { variant: "normal-main" },
                    style: ({ theme }) => ({
                        color: theme.palette.primary.main,
                        fontSize: "1rem",
                        fontWeight: "normal",
                    }),
                },
            ],
        },
        MuiInputLabel: {
            styleOverrides: {
                root: ({ theme }) => ({
                    textAlign: theme.direction === "rtl" ? "right" : "left",
                    right: theme.direction === "rtl" ? 0 : "unset",
                    left: theme.direction === "rtl" ? "unset" : 0,
                    transform: "unset",
                    "& .MuiTypography-heading2": {
                        fontWeight: 600,
                        fontSize: "22px",
                        lineHeight: "41.23px",
                        color: theme.palette.primary.main,
                        [theme.breakpoints.down("sm")]: {
                            fontSize: "16px",
                        },
                    },
                }),
            },
        },
        MuiLink: {
            styleOverrides: {
                root: ({ theme }) => ({
                    color: theme.palette.primary.main,
                    textDecoration: "underline",
                }),
            },
        },
        MuiInputBase: {
            variants: [
                {
                    props: { variant: "labeled" },
                    style: ({ theme }) => ({
                        boxShadow: "0px 0px 10px 2px #0000000a;",
                        "label + &": {
                            marginTop: theme.spacing(6),
                        },
                        "& .MuiInputBase-input": {
                            borderRadius: 4,
                            position: "relative",
                            backgroundColor:
                                theme.palette.mode === "light"
                                    ? "#fff"
                                    : "#1A2027",
                            border: "1px solid",
                            borderColor: "#fff",
                            fontSize: 16,
                            // width: "100%",
                            padding: "12px 10px",
                            transition: theme.transitions.create([
                                "border-color",
                                "background-color",
                                "box-shadow",
                            ]),
                            "&:focus": {
                                borderColor: theme.palette.primary.main,
                            },
                        },
                    }),
                },
            ],
        },
        MuiButton: {
            styleOverrides: {
                root: ({ theme }) => ({
                    "& .MuiButton-endIcon": {
                        marginRight: theme.direction === "rtl" ? "8px" : "-4px",
                        marginLeft: theme.direction === "rtl" ? "-4px" : "8px",
                        borderRadius: "0.5rem",
                    },
                    "& .MuiButton-startIcon": {
                        marginLeft: theme.direction === "rtl" ? "8px" : "-4px",
                        marginRight: theme.direction === "rtl" ? "-4px" : "8px",
                        borderRadius: "0.5rem",
                    },
                }),
                outlined: ({ theme }) => ({
                    padding: "12px 12px",
                    "&:hover": {
                        color: "#fff",
                        backgroundColor: theme.palette.primary.main,
                        borderColor: theme.palette.primary.second,
                        boxShadow: "none",
                    },
                }),
                dashed: ({ theme }) => ({
                    padding: "12px 12px",
                    backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23333' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
                    "&:hover": {
                        color: theme.palette.primary.main,
                        backgroundColor: "none",
                        borderColor: "transparent",
                        boxShadow: "none",
                    },
                }),
            },
            variants: [
                {
                    props: { variant: "main" },
                    style: ({ theme }) => ({
                        boxShadow: "none",
                        textTransform: "none",
                        fontSize: 16,
                        padding: "12px 12px",
                        border: "1px solid",
                        lineHeight: 1.5,
                        backgroundColor: theme.palette.primary.main,
                        borderColor: "none",
                        color: "#fff",
                        "&:hover": {
                            backgroundColor: theme.palette.primary.second,
                            borderColor: theme.palette.primary.second,
                            boxShadow: "none",
                        },
                        "&:active": {
                            boxShadow: "none",
                            backgroundColor: theme.palette.primary.second,
                            borderColor: theme.palette.primary.second,
                        },
                    }),
                },
                {
                    props: { variant: "danger" },
                    style: ({ theme }) => ({
                        boxShadow: "none",
                        textTransform: "none",
                        fontSize: 16,
                        padding: "12px 12px",
                        border: "1px solid",
                        lineHeight: 1.5,
                        backgroundColor: theme.palette.danger.main,
                        borderColor: "none",
                        color: "#fff",
                        "&:hover": {
                            backgroundColor: theme.palette.danger.second,
                            borderColor: theme.palette.danger.second,
                            boxShadow: "none",
                        },
                        "&:active": {
                            boxShadow: "none",
                            backgroundColor: theme.palette.danger.second,
                            borderColor: theme.palette.danger.second,
                        },
                    }),
                },
            ],
        },
        MuiStepper: {
            styleOverrides: {
                root: ({ theme }) => ({
                    textAlign: theme.direction === "rtl" ? "right" : "left",
                    right: theme.direction === "rtl" ? 0 : "unset",
                    left: theme.direction === "rtl" ? "unset" : 0,
                    transform: "unset",
                    "&.MuiStepper-root.MuiStepper-horizontal": {},
                    "& .MuiStepLabel-label": {
                        fontWeight: 400,
                        fontSize: "18px",
                        lineHeight: "35.99px",
                        color: theme.palette.primary.main,
                        "&.Mui-completed": {
                            color: "#B2B9CC",
                        },
                        "&.Mui-disabled": {
                            color: "#B2B9CC",
                        },
                        [theme.breakpoints.down("sm")]: {
                            fontSize: "14px",
                            lineHeight: "20px",
                        },
                    },
                    "& .MuiStepConnector-horizontal": {
                        left:
                            theme.direction === "ltr"
                                ? "calc(-25% + 16px)"
                                : "calc(25% + 16px)",
                        right:
                            theme.direction === "ltr"
                                ? "calc(25% + 16px)"
                                : "calc(-25% + 16px)",
                        top: "calc(53.21px / 2)",
                        width: "50%",
                        "& .MuiStepConnector-line": {
                            borderTopWidth: "10px",
                            borderRadius: "62.6px",
                        },
                        "&.Mui-completed .MuiStepConnector-line": {
                            borderColor: theme.palette.primary.main,
                        },
                        "&.Mui-active .MuiStepConnector-line": {
                            borderColor: theme.palette.primary.main,
                        },
                    },
                    "&.MuiStepper-vertical .MuiStepContent-root": {
                        borderLeft: "none",
                        borderRight: "1px solid #bdbdbd",
                        marginLeft: "unset",
                        marginRight: "20px",
                        paddingLeft: "8px",
                        paddingRight: "20px",
                    },
                    "&.MuiStepper-vertical .MuiStepConnector-vertical .MuiStepConnector-lineVertical":
                        {
                            borderLeftStyle: "none",
                            borderLeftWidth: "0",
                            borderRightStyle: "solid",
                            borderRightWidth: "1px",
                            marginRight: "20px",
                            paddingLeft: "8px",
                            paddingRight: "20px",
                        },
                    "&.MuiStepper-vertical .MuiStepLabel-label": {
                        textAlign: theme.direction === "rtl" ? "right" : "left",
                        paddingLeft: "0.5rem",
                        paddingRight: "0.5rem",
                    },
                    "& .MuiStepLabel-iconContainer": {
                        "& .MuiSvgIcon-root": {
                            width: "53.21px",
                            height: "53.21px",
                            [theme.breakpoints.down("sm")]: {
                                width: "25px",
                                height: "25px",
                            },
                        },
                        "&.Mui-active .MuiSvgIcon-root": {
                            fill: theme.palette.secondary.main,
                            "& .MuiStepIcon-text": {
                                fill: theme.palette.primary.main,
                            },
                        },
                        "&.Mui-disabled .MuiSvgIcon-root": {
                            fill: "#EFF0F6",
                            "& .MuiStepIcon-text": {
                                fill: "#6F6C90",
                            },
                        },
                    },
                }),
            },
        },
        MuiAutocomplete: {
            variants: [
                {
                    props: { variant: "labeled" },
                    style: ({ theme }) => ({
                        "&.MuiAutocomplete-hasPopupIcon .MuiOutlinedInput-root":
                            {
                                paddingTop: "12px",
                                paddingBottom: "12px",
                                paddingLeft: "10px",
                                paddingRight: "10px",
                            },
                        "& .MuiInputBase-root": {
                            boxShadow: "0px 0px 10px 2px #0000000a;",
                            "label + &": {
                                marginTop: theme.spacing(6),
                            },
                            borderRadius: 4,
                            position: "relative",
                            backgroundColor:
                                theme.palette.mode === "light"
                                    ? "#fff"
                                    : "#1A2027",
                            fontSize: 16,
                            paddingTop: "12px",
                            paddingBottom: "12px",
                            paddingLeft: "10px",
                            paddingRight: "10px",
                            transition: theme.transitions.create([
                                "border-color",
                                "background-color",
                                "box-shadow",
                            ]),

                            "& .MuiAutocomplete-endAdornment": {
                                right:
                                    theme.direction === "rtl" ? "unset" : "9px",
                                left:
                                    theme.direction === "rtl" ? "9px" : "unset",
                            },
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                            borderWidth: 0,
                            borderColor: theme.palette.primary.main,
                        },
                        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                            {
                                borderWidth: 0,
                                borderColor: theme.palette.primary.main,
                            },
                        "& .MuiChip-root": {
                            backgroundColor: theme.palette.primary.main,
                            color: "white",
                            borderRadius: 4,
                            "& .MuiSvgIcon-root": {
                                color: "white",
                                margin: "0 -6px 0 5px",
                            },
                        },
                    }),
                },
            ],
        },
    },
});

export const DatePickerStyle = {
    "&.MuiAutocomplete-hasPopupIcon .MuiOutlinedInput-root": {
        paddingTop: "12px",
        paddingBottom: "12px",
        paddingLeft: "10px",
        paddingRight: "10px",
    },
    "& .MuiInputBase-root": {
        boxShadow: "0px 0px 10px 2px #0000000a;",
        "label + &": {
            marginTop: theme.spacing(6),
        },
        position: "relative",
        backgroundColor: theme.palette.mode === "light" ? "#fff" : "#1A2027",
        fontSize: 16,
        paddingTop: "4px",
        paddingBottom: "4px",
        paddingLeft: "10px",
        paddingRight: "10px",
        transition: theme.transitions.create([
            "border-color",
            "background-color",
            "box-shadow",
        ]),

        "& .MuiAutocomplete-endAdornment": {
            right: theme.direction === "rtl" ? "unset" : "9px",
            left: theme.direction === "rtl" ? "9px" : "unset",
        },
    },
    "& .MuiOutlinedInput-notchedOutline": {
        borderWidth: 0,
        borderColor: theme.palette.primary.main,
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderWidth: 0,
        borderColor: theme.palette.primary.main,
    },
};

export default theme;

import {
    Box,
    Button,
    FormControl,
    IconButton,
    InputBase,
    InputLabel,
    Stack,
    Typography,
    Link as MuiLink,
    useMediaQuery,
} from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setEmail } from "../../../store/slices/forgetPassword";
import API from "../../../services/api/Api";
import AuthAPI from "../../../services/api/auth";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@emotion/react";

const Form = () => {
    const navigate = useNavigate();

    const dispatch = useDispatch();
    const { email } = useSelector((state) => state.forgetPassword);

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

    const OnSubmit = async () => {
        await AuthAPI.forgetPassword(email);
        navigate("/auth/login");
    };
    return (
        <Box
            component="form"
            noValidate
            width={isSmallScreen ? "100%" : "500px"}
        >
            <Stack gap={2}>
                <Typography variant={"heading2"}>
                    {
                        "أدخل البريد الإلكتروني الذي استخدمته لإنشاء حسابك حتى نتمكن من إرسال تعليمات لك حول كيفية إعادة تعيين كلمة المرور الخاصة بك."
                    }
                </Typography>
                <FormControl variant="outlined" sx={{ width: "100%" }}>
                    <InputLabel shrink htmlFor="email">
                        <Typography variant="heading2">
                            {"البريد الالكتروني"}
                        </Typography>
                    </InputLabel>
                    <InputBase
                        type="email"
                        variant="labeled"
                        placeholder="اكتب هنا"
                        id="email"
                        defaultValue={email}
                        onChange={(e) => dispatch(setEmail(e.target.value))}
                    />
                </FormControl>
                <FormControl variant="outlined" sx={{ width: "100%" }}>
                    <Button variant="main" onClick={() => OnSubmit()}>
                        {"إرسال"}
                    </Button>
                </FormControl>
                <FormControl variant="outlined" sx={{ width: "100%" }}>
                    <Button
                        variant="outlined"
                        sx={{}}
                        onClick={() => navigate("/auth/login")}
                    >
                        {"عودة الى تسجيل الدخول"}
                    </Button>
                </FormControl>
            </Stack>
        </Box>
    );
};

export default Form;

import API from "./Api";

class LanguageExperienceAPI {

    static async getAll(userId) {
        const result = await API.get(`/language-experiences?user=${userId}`);
        return result;
    }

    static async getOne(userId, id) {
        const result = await API.get(
            `/language-experiences/${id}?user=${userId}`
        );
        return result;
    }

    static async create(userId, { languageId, languageLevel }) {
        const result = await API.post(`/language-experiences?user=${userId}`, {
            languageId,
            languageLevel,
        });
        return result;
    }

    static async update(userId, id, { languageId, languageLevel }) {
        const result = await API.put(
            `/language-experiences/${id}?user=${userId}`,
            {
                languageId,
                languageLevel,
            }
        );
        return result;
    }

    static async delete(userId, id) {
        const result = await API.delete(
            `/language-experiences/${id}?user=${userId}`
        );
        return result;
    }
}

export default LanguageExperienceAPI;

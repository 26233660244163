import { Box, Grid, Stack, Typography, Link as MuiLink } from "@mui/material";
import React from "react";
import AccountTypeBlock from "../../../components/pages/account-type/AccountTypeBlock";
import Form from "../../../components/pages/account-type/Form";
import { Link } from "react-router-dom";
import XCenter from "../../../components/common/XCenter";

const AccountType = () => {
    return (
        <Box className="page">
            <Stack
                alignItems={"center"}
                gap={4}
                sx={{ "& > *": { width: "100%" } }}
            >
                <Box>
                    <XCenter>
                        <Typography variant={"heading1"}>
                            {"إنشاء الحساب"}
                        </Typography>
                    </XCenter>
                </Box>
                <Box>
                    <XCenter>
                        <AccountTypeBlock />
                    </XCenter>
                </Box>
                <Box>
                    <XCenter>
                        <Form />
                    </XCenter>
                </Box>
                <Box>
                    <XCenter>
                        <Typography component={"p"} variant="normal">
                            <Typography component={"span"}>
                                {"هل لديك حساب من قبل؟ "}
                            </Typography>
                            <Typography component={"span"}>
                                <MuiLink component={Link} to="/auth/login">
                                    {"قم بتسجيل الدخول"}
                                </MuiLink>
                            </Typography>
                        </Typography>
                    </XCenter>
                </Box>
            </Stack>
        </Box>
    );
};

export default AccountType;

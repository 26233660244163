import API from "./Api";

export default class ServicesAPI {
    static async getAll(id) {
        const result = await API.get(`/freelance-services`);
        return result;
    }

    static async getOne(id) {
        const result = await API.get(`/freelance-services/${id}`);
        return result;
    }

    static async create(data) {
        const result = await API.post(`/freelance-services`, data);
        return result;
    }

    static async update(id, data) {
        const result = await API.put(`/freelance-services/${id}`, data);
        return result;
    }
}

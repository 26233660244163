import { Box, Button } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { logoutThunk } from "../store/slices/user";
import { REGISTERTATION_TYPES } from "../services/constants";
import ReceivedInterestRequests from "./individual/ReceivedInterestRequests";
import JobSeekers from "./enterprise/JobSeekers";
import { Navigate } from "react-router-dom";
import ProfileMe from "./individual/ProfileMe";

const Home = () => {
    const dispatch = useDispatch();
    const role = useSelector((state) => state.user.role);

    const renderPage = (role) => {
        switch (role) {
            case REGISTERTATION_TYPES.JOB_SEEKER:
            case REGISTERTATION_TYPES.FREELANCER:
            case REGISTERTATION_TYPES.TRAINER:
                return <ProfileMe />;
            case REGISTERTATION_TYPES.ENTERPRISE:
                return <JobSeekers />;
            default:
                return <></>;
        }
    };

    return renderPage(role);
};

export default Home;

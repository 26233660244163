import { Skeleton } from "@mui/material";
import React from "react";

const Loader = ({ children, loading, error, height }) => {
    return loading && !error ? (
        <Skeleton
            variant="rectangular"
            sx={{ width: "100%" }}
            height={height ? height : 50}
        />
    ) : (
        children
    );
};

export default Loader;

import React from "react";
import Card from "../../common/Card";
import {
    DotOutline,
    DownloadSimple,
    FileText,
    Square,
    SuitcaseSimple,
} from "@phosphor-icons/react";
import {
    Checkbox,
    Chip,
    FormControlLabel,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Stack,
    Typography,
    useTheme,
    Link as MuiLink,
} from "@mui/material";
import { Link } from "react-router-dom";
import { getFileName } from "../../../services/utils";

const Services = ({ user }) => {
    const theme = useTheme();
    return (
        <Card title={"الخدمات"} icon={<SuitcaseSimple size={32} />}>
            <Grid container rowSpacing={2}>
                <Grid item xs={12}>
                    <Stack direction={"row"} alignItems={"center"}>
                        <DotOutline size={50} weight="fill" color="#69728B" />
                        <Typography
                            variant="heading2"
                            sx={{ fontSize: "32px", fontWeight: "600" }}
                        >
                            {"الخدمات"}
                        </Typography>
                    </Stack>
                </Grid>
                <Grid item xs={12}>
                    <Stack direction={"row"} gap={2} paddingX={2}>
                        {user.freelanceService.freelanceWorkFields.map(
                            (service) => (
                                <Chip
                                    label={service.nameAr}
                                    color="primary"
                                    sx={{
                                        paddingY: 3,
                                        paddingX: 4,
                                        borderRadius: "6px",
                                        "& .MuiChip-label": {
                                            fontSize: "20px",
                                            fontWeight: "400",
                                        },
                                    }}
                                />
                            )
                        )}
                    </Stack>
                </Grid>
                <Grid item xs={12}>
                    <Stack direction={"row"} gap={2}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={
                                        user.freelanceService
                                            .availableToWorkRemotely
                                    }
                                />
                            }
                            label={"متاح للعمل عن بعد"}
                            sx={{
                                "& .MuiTypography-root": {
                                    fontSize: "22px",
                                    fontWeight: "600",
                                    color: theme.palette.primary.main,
                                },
                            }}
                        />
                    </Stack>
                </Grid>
                <Grid item xs={12}>
                    <Typography
                        variant="heading2"
                        sx={{ fontSize: "32px", fontWeight: "600" }}
                    >
                        {"وصف الخدمات"}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography
                        component={"p"}
                        sx={{
                            fontSize: "24px",
                            fontWeight: "600",
                            color: "#4C576A",
                        }}
                    >
                        {user.freelanceService.description}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography
                        variant="heading2"
                        sx={{ fontSize: "32px", fontWeight: "600" }}
                    >
                        {"محفظة الأعمال"}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <List>
                        {user.freelanceService.files.map((file) => (
                            <ListItem
                                secondaryAction={
                                    <IconButton edge="end">
                                        <DownloadSimple
                                            size={32}
                                            color={"#4C576A"}
                                        />
                                    </IconButton>
                                }
                            >
                                <ListItemText>
                                    <MuiLink
                                        component={Link}
                                        download
                                        to={`${file}`}
                                        target="_blank"
                                        color={"#4C576A"}
                                        sx={{
                                            textDecoration: "none",
                                        }}
                                    >
                                        {getFileName(file)}
                                    </MuiLink>
                                </ListItemText>
                                <ListItemIcon>
                                    <FileText size={32} color={"#4C576A"} />
                                </ListItemIcon>
                            </ListItem>
                        ))}
                    </List>
                </Grid>
                <Grid item xs={12}>
                    <Typography
                        variant="heading2"
                        sx={{ fontSize: "32px", fontWeight: "600" }}
                    >
                        {"التسعير"}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Stack direction={"row"} alignItems={"center"} gap={2}>
                        <Square size={10} weight="fill" color="#69728B" />
                        <Typography
                            variant="heading2"
                            sx={{
                                fontSize: "24px",
                                fontWeight: "600",
                                color: "#69728B",
                            }}
                        >
                            {`الأسعار تبدأ من ${user.freelanceService.price} ${
                                user.freelanceService.currency.symbol
                            } بالساعة  ${
                                user.freelanceService.contactToPrice
                                    ? "(تواصل من أجل السعر)"
                                    : ""
                            }`}
                        </Typography>
                    </Stack>
                </Grid>
            </Grid>
        </Card>
    );
};

export default Services;

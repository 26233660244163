import API from "./Api";

export default class CertificatesAPI {
    static async getAll() {
        const result = await API.get(`/professional-certificates`);
        return result;
    }

    static async getOne(id) {
        const result = await API.get(`/professional-certificates/${id}`);
        return result;
    }

    static async create(data) {
        const result = await API.post(`/professional-certificates`, data);
        return result;
    }

    static async update(id, data) {
        const result = await API.put(`/professional-certificates/${id}`, data);
        return result;
    }

    static async delete(id) {
        const result = await API.delete(`/professional-certificates/${id}`);
        return result;
    }
}

import { useTheme } from "@emotion/react";
import {
    Box,
    Grid,
    IconButton,
    Typography,
    useMediaQuery,
} from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";
import {
    deleteEducation,
    fetchOneEducation,
} from "../../../../store/slices/educationLevelData";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../common/Loader";
import Form from "./Form";
import { Pencil, Trash } from "@phosphor-icons/react";
import dayjs from "dayjs";

const Item = ({ id: itemId, spec, university, level, gradDate, scrollRef }) => {
    const dispatch = useDispatch();
    const userId = useSelector((state) => state.user.id);

    const theme = useTheme();
    const isMdScreen = useMediaQuery(theme.breakpoints.down("md"));

    return (
        <Box
            className="block"
            sx={{
                position: "relative",
                boxShadow: "0px 0px 10px 2px #0000000a;",
                padding: "1rem",
            }}
        >
            <Grid container rowSpacing={5} columnSpacing={isMdScreen ? 0 : 10}>
                <Grid item xs={12} sm={12} md={12}>
                    <Typography variant="heading2">
                        {"التخصص: "}
                        <Typography variant="large">{spec.nameAr}</Typography>
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <Typography variant="heading2">
                        {"المستوى العلمي: "}
                        <Typography variant="large">{level.nameAr}</Typography>
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <Typography variant="heading2">
                        {"تاريخ التخرج: "}
                        <Typography variant="large">
                            {dayjs(gradDate).format("DD-MM-YYYY")}
                        </Typography>
                    </Typography>
                </Grid>
            </Grid>
            <Box
                sx={{
                    position: "absolute",
                    left: 0,
                    top: 0,
                }}
            >
                <Stack direction="row" spacing={1}>
                    <IconButton
                        onClick={() =>
                            dispatch(
                                deleteEducation({
                                    userId,
                                    id: itemId,
                                })
                            )
                        }
                    >
                        <Trash color="red" />
                    </IconButton>
                    <IconButton
                        onClick={() => {
                            dispatch(
                                fetchOneEducation({
                                    userId,
                                    id: itemId,
                                })
                            );
                            scrollRef.current.scrollIntoView();
                        }}
                    >
                        <Pencil style={{ color: theme.palette.primary.main }} />
                    </IconButton>
                </Stack>
            </Box>
        </Box>
    );
};

const List = ({ scrollRef }) => {
    const { all, loaders } = useSelector((state) => state.educationLevelData);
    const theme = useTheme();
    const isMdScreen = useMediaQuery(theme.breakpoints.down("md"));
    return (
        <Box className="block">
            <Stack gap={2}>
                {all.length > 0 ? (
                    <Typography variant="heading2">
                        {"مستوى التعليم"}
                    </Typography>
                ) : (
                    <></>
                )}
                <Loader
                    loading={loaders.all.loading}
                    error={loaders.all.error}
                    height={200}
                >
                    {all.map((item) => (
                        <Box key={item.id}>
                            <Item
                                id={item.id}
                                spec={item.specialization}
                                university={item.college}
                                level={item.educationLevel}
                                gradDate={item.graduationDate}
                                scrollRef={scrollRef}
                            />
                        </Box>
                    ))}
                </Loader>
            </Stack>
        </Box>
    );
};

export default List;

import { DotOutline, SuitcaseSimple } from "@phosphor-icons/react";
import React, { useState } from "react";
import Card from "../../common/Card";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    Stack,
    Typography,
    useTheme,
} from "@mui/material";

const PracticalExperiences = ({ user }) => {
    const theme = useTheme();
    const [expanded, setExpanded] = useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    return (
        <Card title={"الخبرات العملية"} icon={<SuitcaseSimple size={32} />}>
            {user.personalPracticalExperiences.jobHistories.map((job) => (
                <Accordion
                    expanded={expanded === "panel1"}
                    onChange={handleChange("panel1")}
                    sx={{
                        "&.MuiPaper-root": {
                            boxShadow: "none",
                            borderBottom: "1px solid #D0D4E0",
                            borderRadius: "0",
                        },
                    }}
                >
                    <AccordionSummary id="panel1bh-header">
                        <Stack width={"100%"}>
                            <Stack
                                direction={"row"}
                                justifyContent={"space-between"}
                                alignItems={"center"}
                            >
                                <Typography
                                    variant="heading2"
                                    sx={{ fontSize: "36px", fontWeight: "700" }}
                                >
                                    {job.workField.nameAr}
                                </Typography>
                                <Typography
                                    variant="normal-main"
                                    sx={{ fontSize: "24px", fontWeight: "400" }}
                                >
                                    {`${job.startDate} - ${job.current ? 'الآن' : job.endDate}`}
                                </Typography>
                            </Stack>
                            <Stack
                                direction={"row"}
                                justifyContent={"space-between"}
                                alignItems={"center"}
                            >
                                <Typography
                                    sx={{
                                        fontSize: "24px",
                                        fontWeight: "400",
                                        color: "#4C576A",
                                    }}
                                >
                                    {job.organization}
                                </Typography>
                                <Button
                                    sx={{
                                        textDecoration: "underline",
                                        fontSize: "1.2rem",
                                    }}
                                >
                                    {expanded == "panel1"
                                        ? "إخفاء التفاصيل"
                                        : "عرض التفاصيل"}
                                </Button>
                            </Stack>
                        </Stack>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Stack width={"100%"}>
                            <Stack direction={"row"} alignItems={"center"}>
                                <DotOutline
                                    size={50}
                                    weight="fill"
                                    color="#69728B"
                                />
                                <Typography
                                    variant="heading2"
                                    sx={{ fontSize: "36px", fontWeight: "600" }}
                                >
                                    {"المسؤوليات"}
                                </Typography>
                            </Stack>
                            <Box>
                                <Typography
                                    component={"p"}
                                    sx={{ fontSize: "24px", fontWeight: "600" }}
                                >
                                    {job.responsibilities}
                                </Typography>
                            </Box>
                            <Stack direction={"row"} alignItems={"center"}>
                                <DotOutline
                                    size={50}
                                    weight="fill"
                                    color="#69728B"
                                />
                                <Typography
                                    variant="heading2"
                                    sx={{ fontSize: "36px", fontWeight: "600" }}
                                >
                                    {"الإنجازات"}
                                </Typography>
                            </Stack>
                            <Box>
                                <Typography
                                    component={"p"}
                                    sx={{ fontSize: "24px", fontWeight: "600" }}
                                >
                                    {job.achievements}
                                </Typography>
                            </Box>
                        </Stack>
                    </AccordionDetails>
                </Accordion>
            ))}
        </Card>
    );
};

export default PracticalExperiences;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    accountType: null,
    email: null,
    role: null,
};

const slice = createSlice({
    name: "register",
    initialState,
    reducers: {
        setEmail: (state, action) => {
            state.email = action.payload;
        },
        setRole: (state, action) => {
            if (state.accountType && state.accountType == "company") {
                state.role = "COMPANY";
            } else {
                state.role = action.payload;
            }
        },
        setAccountType: (state, action) => {
            state.accountType = action.payload;
        },
        resetRegister: (state) => {
            state.email = null;
            state.role = null;
            state.accountType = null;
        },
    },
});

export const { setEmail, setRole, setAccountType, resetRegister } =
    slice.actions;

export default slice.reducer;

import {
    Box,
    Button,
    Link as MuiLink,
    Stack,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import LogoutBtn from "./LogoutBtn";
import Logo from "./Logo";
import CMenu from "../common/Menu";
import MenuLink from "./MenuLink";
import { useSelector } from "react-redux";
import {
    MenuLinks,
    ProfileLinks,
    REGISTERTATION_TYPES,
} from "../../services/constants";
import TmpImage from "../../assets/img/logo.png";

const Navbar = () => {
    const navigate = useNavigate();

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

    const { role, email } = useSelector((state) => state.user);
    const fullName = useSelector((state) => {
        return `${state.personalInfo.firstNameAr} ${state.personalInfo.lastNameAr}`;
    });

    const links = MenuLinks[role];
    const profilelinks = ProfileLinks[role];

    const renderLinks = (links) => {
        return links.map((link) => {
            if (link.children && link.children.length > 0) {
                return (
                    <CMenu parent={link.title}>
                        {renderLinks(link.children)}
                    </CMenu>
                );
            } else {
                return (
                    <MenuLink
                        key={link.title}
                        route={link.route}
                        title={link.title}
                    />
                );
            }
        });
    };

    return (
        <Stack
            direction={"row"}
            padding={isSmallScreen ? "1rem" : "2rem"}
            gap={2}
            justifyContent={"space-between"}
        >
            <Stack direction={"row"} gap={2}>
                <MuiLink component={Link} to={"/"}>
                    <Logo />
                </MuiLink>
                {renderLinks(links)}
            </Stack>

            <CMenu
                parent={role == REGISTERTATION_TYPES.ADMIN ? email : fullName}
                image={TmpImage}
                items={profilelinks}
                onItemClick={(item) => navigate(item.route)}
            >
                <LogoutBtn />
            </CMenu>
        </Stack>
    );
};

export default Navbar;

import { Box, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import Card from "../../components/common/Card";
import { SuitcaseSimple } from "@phosphor-icons/react";
import PracticalExperiences from "../../components/pages/profile-me/PracticalExperiences";
import Coaching from "../../components/pages/profile-me/Coaching";
import Services from "../../components/pages/profile-me/Services";
import SkillsAndKnowledge from "../../components/pages/profile-me/SkillsAndKnowledge";
import Educations from "../../components/pages/profile-me/Educations";
import Certifications from "../../components/pages/profile-me/Certifications";
import Interests from "../../components/pages/profile-me/Interests";
import Languages from "../../components/pages/profile-me/Languages";
import References from "../../components/pages/profile-me/References";
import Initial from "../../components/pages/profile-me/Initial";
import Loader from "../../components/common/Loader";
import { useSelector } from "react-redux";
import UserAPI from "../../services/api/User";
import { REGISTERTATION_TYPES } from "../../services/constants";
const ProfileMe = () => {
    const [user, setUser] = useState(null);

    const { id: userId, role } = useSelector((state) => state.user);

    useEffect(() => {
        const fetchUser = async () => {
            const result = await UserAPI.getOne(userId);
            setUser(result);
        };

        fetchUser();
    }, [userId]);
    return (
        <Box className="page">
            <Loader loading={!user} error={false}>
                <Box
                    paddingY={4}
                    paddingX={8}
                    borderRadius={"15px"}
                    border={"3px solid #D0D4E0"}
                    position={"relative"}
                >
                    <Stack gap={4}>
                        <Initial user={user} />
                        <PracticalExperiences user={user} />
                        {role == REGISTERTATION_TYPES.TRAINER && (
                            <Coaching user={user} />
                        )}
                        {role == REGISTERTATION_TYPES.FREELANCER && (
                            <Services user={user} />
                        )}

                        <SkillsAndKnowledge />
                        <Educations />
                        <Certifications />
                        <Interests />
                        <Languages />
                        <References />
                    </Stack>
                </Box>
            </Loader>
        </Box>
    );
};

export default ProfileMe;

import { Box, Divider, Stack, Typography, useTheme } from "@mui/material";
import React from "react";

const Card = ({ title, icon, children }) => {
    const theme = useTheme();
    return (
        <Box
            className="block card"
            padding={2}
            borderRadius={4}
            boxShadow={theme.palette.boxShadow.main1}
        >
            <Stack gap={2}>
                <Stack direction={"row"} gap={2} alignItems={"center"}>
                    <Box>{icon}</Box>
                    <Typography
                        variant="heading2"
                        sx={{ fontSize: "36px", fontWeight: "600" }}
                    >
                        {title}
                    </Typography>
                </Stack>
                <Divider />
                <Box>{children}</Box>
            </Stack>
        </Box>
    );
};

export default Card;

import * as Yup from "yup";

const arabicCharRegex = /^[\u0600-\u06FF\u0750-\u077F\u08A0-\u08FF\s]+$/;
const latinCharRegex = /^[A-Za-z\s]+$/;

const validationSchema = Yup.object().shape({
    firstNameAr: Yup.string()
        .matches(
            arabicCharRegex,
            "الاسم الاول باللغة العربية يجب أن يحتوي على أحرف عربية فقط"
        )
        .required("الاسم الاول باللغة العربية مطلوب"),
    lastNameAr: Yup.string()
        .matches(
            arabicCharRegex,
            "الكنية باللغة العربية يجب أن تحتوي على أحرف عربية فقط"
        )
        .required("الكنية باللغة العربية مطلوبة"),
    firstNameEn: Yup.string()
        .matches(
            latinCharRegex,
            "الاسم الاول بالاحرف اللاتينية يجب أن يحتوي على أحرف لاتينية فقط"
        )
        .required("الاسم الاول بالاحرف اللاتينية مطلوب"),
    lastNameEn: Yup.string()
        .matches(
            latinCharRegex,
            "الكنية بالاحرف اللاتينية يجب أن تحتوي على أحرف لاتينية فقط"
        )
        .required("الكنية بالاحرف اللاتينية مطلوبة"),
    year: Yup.object().required("السنة مطلوبة"),
    month: Yup.object().required("الشهر مطلوب"),
    day: Yup.object().required("اليوم مطلوب"),
    gender: Yup.object().nullable().required("الجنس مطلوب"),
    nationality1: Yup.object().nullable().required("الجنسية مطلوبة"),
});

export default validationSchema;

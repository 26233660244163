import { useTheme } from "@emotion/react";
import {
    Box,
    Grid,
    IconButton,
    Link as MuiLink,
    Stack,
    Typography,
    useMediaQuery,
} from "@mui/material";
import { Pencil, Trash } from "@phosphor-icons/react";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../common/Loader";
import dayjs from "dayjs";
import {
    deleteCertificate,
    fetchOneCertificate,
} from "../../../../store/slices/certificatesData";
import { Link } from "react-router-dom";
const Item = ({
    id: itemId,
    name,
    certificateInstitution,
    releaseDate,
    expirationDate,
    certificateUrl,
    scrollRef,
}) => {
    const dispatch = useDispatch();
    const userId = useSelector((state) => state.user.id);

    const theme = useTheme();
    const isMdScreen = useMediaQuery(theme.breakpoints.down("md"));

    return (
        <Box
            className="block"
            sx={{
                position: "relative",
                boxShadow: "0px 0px 10px 2px #0000000a;",
                padding: "1rem",
            }}
        >
            <Grid container rowSpacing={2} columnSpacing={isMdScreen ? 0 : 10}>
                <Grid item xs={12} sm={12} md={12}>
                    <Typography variant="heading2">{name}</Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                    <Typography variant="heading2">
                        {"المؤسسة المانحة للشهادة :"}
                        <Typography variant="large">
                            {certificateInstitution}
                        </Typography>
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <Typography variant="heading2">
                        {"تاريخ الإصدار: "}
                        <Typography variant="large">
                            {dayjs(releaseDate).format("DD-MM-YYYY")}
                        </Typography>
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <Typography variant="heading2">
                        {"تاريخ انتهاء الصلاحية: "}
                        <Typography variant="large">
                            {dayjs(expirationDate).format("DD-MM-YYYY")}
                        </Typography>
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                    <Typography variant="heading2">
                        {"رابط التحقق من الشهادة :"}
                        <Typography variant="large">
                            <MuiLink
                                to={certificateUrl}
                                component={Link}
                                target="_blank"
                            >
                                {certificateUrl}
                            </MuiLink>
                        </Typography>
                    </Typography>
                </Grid>
            </Grid>
            <Box
                sx={{
                    position: "absolute",
                    left: 0,
                    top: 0,
                }}
            >
                <Stack direction="row" spacing={1}>
                    <IconButton
                        onClick={() =>
                            dispatch(
                                deleteCertificate({
                                    id: itemId,
                                })
                            )
                            
                        }
                    >
                        <Trash color="red" />
                    </IconButton>
                    <IconButton
                        onClick={() => {
                            dispatch(
                                fetchOneCertificate({
                                    id: itemId,
                                })
                            );
                            scrollRef.current.scrollIntoView();
                        }}
                    >
                        <Pencil style={{ color: theme.palette.primary.main }} />
                    </IconButton>
                </Stack>
            </Box>
        </Box>
    );
};

const List = ({ scrollRef }) => {
    const { all, loaders } = useSelector((state) => state.certificatesData);
    const theme = useTheme();
    const isMdScreen = useMediaQuery(theme.breakpoints.down("md"));

    return (
        <Box className="block">
            <Stack gap={2}>
                <Loader
                    loading={loaders.all.loading}
                    error={loaders.all.error}
                    height={200}
                >
                    {all.map((item) => (
                        <Box key={item.id}>
                            <Item
                                id={item.id}
                                name={item.name}
                                certificateInstitution={
                                    item.certificateInstitution
                                }
                                releaseDate={item.releaseDate}
                                expirationDate={item.expirationDate}
                                certificateUrl={item.certificateUrl}
                                scrollRef={scrollRef}
                            />
                        </Box>
                    ))}
                </Loader>
            </Stack>
        </Box>
    );
};

export default List;

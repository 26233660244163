// src/slices/birthdateSlice.js

import { createSlice } from "@reduxjs/toolkit";
import {
    PROFILE_STEPS_ROLE,
    REGISTERTATION_TYPES,
} from "../../services/constants";

const initialState = {
    current: -1,
    steps: [],
};

const profileStepperSlice = createSlice({
    name: "profileStepper",
    initialState,
    reducers: {
        setStepper: (state, action) => {
            let steps = [];
            switch (action.payload.role) {
                case REGISTERTATION_TYPES.JOB_SEEKER:
                    steps = PROFILE_STEPS_ROLE[REGISTERTATION_TYPES.JOB_SEEKER];
                    break;
                case REGISTERTATION_TYPES.FREELANCER:
                    steps = PROFILE_STEPS_ROLE[REGISTERTATION_TYPES.FREELANCER];
                    break;
                case REGISTERTATION_TYPES.TRAINER:
                    steps = PROFILE_STEPS_ROLE[REGISTERTATION_TYPES.TRAINER];
                    break;
                case REGISTERTATION_TYPES.ENTERPRISE:
                    steps = PROFILE_STEPS_ROLE[REGISTERTATION_TYPES.ENTERPRISE];
                    break;
                default:
                    steps = [];
                    break;
            }
            state.steps = steps;
            setCurrent(steps[action.payload.step]);
        },
        setNext: (state, action) => {
            const nextStep = state.current + 1;
            if (nextStep <= state.steps.length) {
                state.current = nextStep;
            } else {
                state.current = -1;
            }
            window.scrollTo(0,0);
        },

        setPrev: (state, action) => {
            const prevStep = state.current - 1;
            if (prevStep >= 0) {
                state.current = prevStep;
            }
            window.scrollTo(0,0);
        },

        setCurrent: (state, action) => {
            state.current = action.payload;
        },
    },
});

export const { setStepper, setNext, setPrev, setCurrent } =
    profileStepperSlice.actions;

export default profileStepperSlice.reducer;

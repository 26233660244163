import {
    Avatar,
    Box,
    Button,
    CircularProgress,
    FormControl,
    Grid,
    IconButton,
    Stack,
    Typography,
    styled,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import { CloudArrowUp, PencilSimple, Trash } from "@phosphor-icons/react";
import React from "react";
import {
    setProfileImage,
    setProfileImageFile,
} from "../../../../store/slices/images";
import { useDispatch, useSelector } from "react-redux";
import { toBase64 } from "../../../../services/utils";
import UserAPI from "../../../../services/api/User";
import { fetchUser, uploadProfileImage,deleteProfileImage } from "../../../../store/slices/user";

const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
});

const ProfileImage = () => {
    const dispatch = useDispatch();

    const { id, iconUrl, loaders } = useSelector((state) => state.user);

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

    return (
        <Grid container direction={"row"} alignItems={"center"} rowSpacing={2}>
            <Grid item xs={12} sm={12} md={3}>
                <Box
                    sx={{
                        position: "relative",
                    }}
                >
                    <Avatar
                        sx={{
                            width: isSmallScreen ? "100px" : "175px",
                            height: isSmallScreen ? "100px" : "175px",
                        }}
                        src={iconUrl}
                        imgProps={{
                            crossOrigin: "anonymous",
                        }}
                    />
                    <IconButton
                        sx={{
                            backgroundColor: "#B2B9CCA3",
                            position: "absolute",
                            bottom: 0,
                            right: 0,
                        }}
                        onClick={() =>
                            dispatch(
                                deleteProfileImage({
                                    file: iconUrl,
                                })
                            )
                        }
                    >
                        <Trash size={22} style={{ color: "#F15E58" }} />
                    </IconButton>
                </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={9}>
                <Stack gap={2}>
                    <Button
                        component={"label"}
                        role={undefined}
                        variant="outlined"
                        tabIndex={-1}
                        sx={{
                            borderRadius: "2rem",
                            width: "50%",
                        }}
                        startIcon={<CloudArrowUp size={32} />}
                    >
                        <Typography>{"اختر الملف"}</Typography>
                        <VisuallyHiddenInput
                            type="file"
                            accept="image/*"
                            onChange={(e) => {
                                console.log(e.target.files[0]);
                                dispatch(
                                    uploadProfileImage({
                                        file: e.target.files[0],
                                    })
                                );
                            }}
                        />
                    </Button>
                    {loaders.profile_image.loading && (
                        <Box sx={{ display: "flex" }}>
                            <CircularProgress />
                        </Box>
                    )}

                    <Typography variant="heading2">
                        {"على الأقل يجب تحميل صورة بأبعاد 256x256 "}
                    </Typography>
                </Stack>
            </Grid>
        </Grid>
    );
};

export default ProfileImage;

import {
    Autocomplete,
    Button,
    FormControl,
    Grid,
    InputLabel,
    TextField,
    Typography,
    useMediaQuery,
} from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    fetchAllLanguages,
    setLang,
    setLevel,
} from "../../../../../store/slices/languageExperiencesForm";
import { useTheme } from "@emotion/react";
import XCenter from "../../../../common/XCenter";
import {
    createLanguageExperience,
    updateLanguageExperience,
} from "../../../../../store/slices/languageExperiencesData";
import Loader from "../../../../common/Loader";
import useForm from "./useForm";
import ValidationErrorMessage from "../../../../common/ValidationErrorMessage";

const Form = () => {
    const dispatch = useDispatch();

    const [languageExperiencesForm, handleSubmit] = useForm();

    const { allLanguages: allLanguagesLoader } = useSelector(
        (state) => state.languageExperiencesForm.loaders
    );

    const { oneLanguageExperience: oneLanguageExperienceLoader } = useSelector(
        (state) => state.languageExperiencesData.loaders
    );

    const theme = useTheme();
    const isMdScreen = useMediaQuery(theme.breakpoints.down("md"));

    return (
        <>
            <Grid
                container
                rowSpacing={5}
                columnSpacing={isMdScreen ? 0 : 10}
                marginTop={"1rem"}
            >
                <Grid item xs={6}>
                    <Loader
                        loading={allLanguagesLoader.loading}
                        error={allLanguagesLoader.error}
                    >
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel shrink htmlFor="language">
                                <Typography variant="heading2">
                                    {"اللغة *"}
                                </Typography>
                            </InputLabel>
                            <Autocomplete
                                variant="labeled"
                                id="language"
                                value={languageExperiencesForm.lang}
                                options={languageExperiencesForm.languages}
                                getOptionLabel={(option) =>
                                    option ? option.nameAr : ""
                                }
                                isOptionEqualToValue={(option, value) =>
                                    option.id === value.id
                                }
                                onChange={(e, option) =>
                                    dispatch(setLang(option))
                                }
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        placeholder="اختر من هنا"
                                    />
                                )}
                                sx={{
                                    "& .MuiInputBase-root": {
                                        boxShadow:
                                            oneLanguageExperienceLoader.loading
                                                ? `0px 0px 10px 2px ${theme.palette.primary.transparent}`
                                                : "0px 0px 10px 2px #0000000a;",
                                    },
                                }}
                            />
                            <ValidationErrorMessage
                                errors={languageExperiencesForm.errors}
                                field={"language"}
                            />
                        </FormControl>
                    </Loader>
                </Grid>
                <Grid item xs={6}>
                    {!languageExperiencesForm.levels.loading && (
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel shrink htmlFor="level">
                                <Typography variant="heading2">
                                    {"المستوى *"}
                                </Typography>
                            </InputLabel>
                            <Autocomplete
                                variant="labeled"
                                id="level"
                                value={languageExperiencesForm.level}
                                options={languageExperiencesForm.levels}
                                getOptionLabel={(option) =>
                                    option ? option.label : ""
                                }
                                isOptionEqualToValue={(option, value) =>
                                    option.value === value.value
                                }
                                onChange={(e, option) =>
                                    dispatch(setLevel(option))
                                }
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        placeholder="اختر من هنا"
                                    />
                                )}
                                sx={{
                                    "& .MuiInputBase-root": {
                                        boxShadow:
                                            oneLanguageExperienceLoader.loading
                                                ? `0px 0px 10px 2px ${theme.palette.primary.transparent}`
                                                : "0px 0px 10px 2px #0000000a;",
                                    },
                                }}
                            />
                            <ValidationErrorMessage
                                errors={languageExperiencesForm.errors}
                                field={"level"}
                            />
                        </FormControl>
                    )}
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12}>
                    <XCenter>
                        <Button
                            onClick={() => handleSubmit()}
                            sx={{
                                marginTop: "1rem",
                                textDecoration: "underline",
                                fontSize: "1.2rem",
                            }}
                        >
                            {languageExperiencesForm.current
                                ? "تعديل"
                                : "إضافة لغة أخرى"}
                        </Button>
                    </XCenter>
                </Grid>
            </Grid>
        </>
    );
};

export default Form;

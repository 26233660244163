import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ExperienceAPI from "../../services/api/Experience";
import {
    SkillAPI,
    TheoreticalKnowledgeAPI,
    WorkFieldAPI,
} from "../../services/api/model";
import UploadAPI from "../../services/api/Upload";
import dayjs from "dayjs";

const initialState = {
    id: null,
    selectedWorkField: null,
    experienceYears: 0,
    selectedSkills: [],
    selectedTheoreticalKnowledge: [],
    whenCanStart: null,
    availabilityToTravel: false,
    cv: null,
    cvFile: null,
    errors: null,
    workFields: [],
    skills: [],
    theoreticalKnowledge: [],
    loaders: {
        workFields: {
            loading: false,
            error: null,
        },
        theoreticalKnowledge: {
            loading: false,
            error: null,
        },
        skills: {
            loading: false,
            error: null,
        },
        cv: {
            loading: false,
            error: null,
        },
    },
};

export const fetchAllWorkFields = createAsyncThunk(
    "workFields/fetchAllWorkFields",
    async () => {
        const result = await WorkFieldAPI.getAll();
        return result;
    }
);

export const fetchAllSkills = createAsyncThunk(
    "skills/fetchAllSkills",
    async () => {
        const result = await SkillAPI.getAll();
        return result;
    }
);

export const fetchAllTheoreticalKnowledges = createAsyncThunk(
    "theoreticalKnowledges/fetchAllTheoreticalKnowledges",
    async () => {
        const result = await TheoreticalKnowledgeAPI.getAll();
        return result;
    }
);

export const fetchAllExperiences = createAsyncThunk(
    "experiences/fetchAllExperiences",
    async ({ userId }) => {
        const result = await ExperienceAPI.getAll(userId);
        if (result.length > 0) {
            return result[0];
        } else {
            return null;
        }
    }
);

export const createExperience = createAsyncThunk(
    "experiences/createExperience",
    async ({ userId, data }, { dispatch }) => {
        const result = await ExperienceAPI.create(userId, data);
        dispatch(fetchAllExperiences(userId));
        return result;
    }
);

export const updateExperience = createAsyncThunk(
    "experiences/updateExperience",
    async ({ userId, id, data }, { dispatch }) => {
        const result = await ExperienceAPI.update(userId, id, data);
        dispatch(fetchAllExperiences(userId));
        return result;
    }
);

export const uploadCV = createAsyncThunk("cv/uploadCV", async ({ file }) => {
    const result = await UploadAPI.uploadFile(
        file,
        "training_and_certificates"
    );
    return result;
});

const experiencesFormSlice = createSlice({
    name: "experiencesForm",
    initialState,
    reducers: {
        setSelectedWorkField(state, action) {
            state.selectedWorkField = action.payload;
        },
        setExperienceYears(state, action) {
            const operation = action.payload.operation;
            if (operation == "inc") {
                state.experienceYears += 1;
            } else if (operation == "decr") {
                if (state.experienceYears > 0) state.experienceYears += -1;
            } else {
                const val =
                    action.payload.value == ""
                        ? 0
                        : parseInt(action.payload.value);
                if (!isNaN(val)) {
                    state.experienceYears = val;
                }
            }
        },
        setSelectedSkills(state, action) {
            state.selectedSkills = action.payload;
        },
        setSelectedTheoreticalKnowledge(state, action) {
            state.selectedTheoreticalKnowledge = action.payload;
        },
        setWhenCanStart(state, action) {
            state.whenCanStart = action.payload.toISOString().split("T")[0];
        },
        setAvailabilityToTravel(state, action) {
            state.availabilityToTravel = action.payload;
        },
        setCv(state, action) {
            state.cv = action.payload;
        },
        setCvFile(state, action) {
            state.cvFile = action.payload;
        },
        setAll(state, action) {
            state.id = action.payload.id;
            state.selectedWorkField = action.payload.workField;
            state.experienceYears = action.payload.yearsOfExperience;
            state.selectedSkills = action.payload.skills;
            state.selectedTheoreticalKnowledge =
                action.payload.theoreticalKnowledge;
            state.whenCanStart = action.payload.whenCanStart;
            state.availabilityToTravel = action.payload.availabilityToTravel;
            state.cv = action.payload.resume;
        },
        resetAll(state) {
            return initialState;
        },
        setErrors: (state, action) => {
            state.errors = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllExperiences.fulfilled, (state, action) => {
                if (action.payload) {
                    state.id = action.payload.id;
                    state.selectedWorkField = action.payload.workField;
                    state.experienceYears = action.payload.yearsOfExperience;
                    state.selectedSkills = action.payload.skills;
                    state.selectedTheoreticalKnowledge =
                        action.payload.theoreticalKnowledge;
                    state.whenCanStart = action.payload.whenCanStart;
                    state.availabilityToTravel =
                        action.payload.availabilityToTravel;
                    state.cv = action.payload.resume;
                }
            })
            .addCase(fetchAllWorkFields.pending, (state) => {
                state.loaders.workFields.loading = true;
                state.loaders.workFields.error = null;
            })
            .addCase(fetchAllWorkFields.fulfilled, (state, action) => {
                state.loaders.workFields.loading = false;
                state.workFields = action.payload;
            })
            .addCase(fetchAllWorkFields.rejected, (state, action) => {
                state.loaders.workFields.loading = true;
                state.loaders.workFields.error = action.message;
            })
            .addCase(fetchAllSkills.pending, (state) => {
                state.loaders.skills.loading = true;
                state.loaders.skills.error = null;
            })
            .addCase(fetchAllSkills.fulfilled, (state, action) => {
                state.loaders.skills.loading = false;
                state.skills = action.payload;
            })
            .addCase(fetchAllSkills.rejected, (state, action) => {
                state.loaders.skills.loading = true;
                state.loaders.skills.error = action.message;
            })
            .addCase(fetchAllTheoreticalKnowledges.pending, (state) => {
                state.loaders.theoreticalKnowledge.loading = true;
                state.loaders.theoreticalKnowledge.error = null;
            })
            .addCase(
                fetchAllTheoreticalKnowledges.fulfilled,
                (state, action) => {
                    state.loaders.theoreticalKnowledge.loading = false;
                    state.theoreticalKnowledge = action.payload;
                }
            )
            .addCase(
                fetchAllTheoreticalKnowledges.rejected,
                (state, action) => {
                    state.loaders.theoreticalKnowledge.loading = true;
                    state.loaders.theoreticalKnowledge.error = action.message;
                }
            )
            .addCase(uploadCV.pending, (state) => {
                state.loaders.cv.loading = true;
                state.loaders.cv.error = null;
            })
            .addCase(uploadCV.fulfilled, (state, action) => {
                state.loaders.cv.loading = false;
                state.cv = action.payload.url;
                state.cvFile = null;
            })
            .addCase(uploadCV.rejected, (state, action) => {
                state.loaders.cv.loading = true;
                state.loaders.cv.error = action.message;
            });
    },
});

export const {
    setSelectedWorkField,
    setExperienceYears,
    setSelectedSkills,
    setSelectedTheoreticalKnowledge,
    setWhenCanStart,
    setAvailabilityToTravel,
    setCv,
    setCvFile,
    setWorkFields,
    setTheoreticalKnowledge,
    setAll,
    resetAll,
    setErrors,
} = experiencesFormSlice.actions;

export default experiencesFormSlice.reducer;

import React from "react";
import Card from "../../common/Card";
import {
    GlobeHemisphereWest,
} from "@phosphor-icons/react";

const Languages = () => {
    return (
        <Card title={"اللغات"} icon={<GlobeHemisphereWest size={32} />}></Card>
    );
};

export default Languages;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    name: null,
    certificateInstitution: null,
    releaseDate: null,
    expirationDate: null,
    certificateUrl: null,
    closed: true,
    errors: null,
};

const slice = createSlice({
    name: "certificatesForm",
    initialState,
    reducers: {
        setName(state, action) {
            state.name = action.payload;
        },
        setCertificateInstitution(state, action) {
            state.certificateInstitution = action.payload;
        },
        setReleaseDate(state, action) {
            state.releaseDate = action.payload;
        },
        setExpirationDate(state, action) {
            state.expirationDate = action.payload;
        },
        setCertificateUrl(state, action) {
            state.certificateUrl = action.payload;
        },
        setErrors(state, action) {
            state.errors = action.payload;
        },
        openForm: (state) => {
            state.closed = false;
        },
        closeForm: (state) => {
            state.closed = true;
        },
        setAll(state, action) {
            state.name = action.payload.name;
            state.certificateInstitution =
                action.payload.certificateInstitution;
            state.releaseDate = action.payload.releaseDate;
            state.expirationDate = action.payload.expirationDate;
            state.certificateUrl = action.payload.certificateUrl;
        },
        resetAll() {
            return initialState;
        },
    },
});

export const {
    setName,
    setCertificateInstitution,
    setReleaseDate,
    setExpirationDate,
    setCertificateUrl,
    openForm,
    closeForm,
    setErrors,
    setAll,
    resetAll,
} = slice.actions;

export default slice.reducer;

import {
    Autocomplete,
    Box,
    Button,
    FormControl,
    Grid,
    InputBase,
    InputLabel,
    TextField,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import {
    setDay,
    setFirstNameAr,
    setFirstNameEn,
    setGender,
    setLastNameAr,
    setLastNameEn,
    setMonth,
    setYear,
} from "../../../../store/slices/personalInfo";
import {
    setNationality1,
    setNationality2,
} from "../../../../store/slices/personalInfo";
import { default as LanguageExperiencesList } from "../personal-info/language-experiences/List";
import { GenderOptions, PROFILE_MODES } from "../../../../services/constants";
import Loader from "../../../common/Loader";
import ValidationErrorMessage from "../../../common/ValidationErrorMessage";
import useForm from "./useForm";
import { setNext } from "../../../../store/slices/stepper";
import ProfileImage from "./ProfileImage";

const Form = () => {
    const dispatch = useDispatch();
    const { personalInfo, handleSubmit } = useForm();

    const theme = useTheme();
    const isMdScreen = useMediaQuery(theme.breakpoints.down("md"));

    return (
        <Box component={"form"}>
            <Grid container direction={"row"} justifyContent={"center"}>
                <Grid
                    container
                    item
                    direction={"row"}
                    rowSpacing={5}
                    columnSpacing={isMdScreen ? 0 : 10}
                    md={9}
                    sm={12}
                    xs={12}
                >
                    {personalInfo.mode == PROFILE_MODES.UPDATE && (
                        <Grid item xs={12}>
                            <ProfileImage />
                        </Grid>
                    )}

                    <Grid item xs={12} sm={12} md={6}>
                        <Loader
                            loading={personalInfo.loaders.personalInfo.loading}
                            error={personalInfo.loaders.personalInfo.error}
                        >
                            <FormControl variant="outlined" fullWidth>
                                <InputLabel shrink htmlFor="firstNameAr">
                                    <Typography variant="heading2">
                                        {"الاسم الأول بالعربية *"}
                                    </Typography>
                                </InputLabel>
                                <InputBase
                                    type="text"
                                    variant="labeled"
                                    placeholder="اكتب هنا"
                                    id="firstNameAr"
                                    value={personalInfo.firstNameAr}
                                    onChange={(e) =>
                                        dispatch(setFirstNameAr(e.target.value))
                                    }
                                />
                                <ValidationErrorMessage
                                    errors={personalInfo.errors}
                                    field={"firstNameAr"}
                                />
                            </FormControl>
                        </Loader>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <Loader
                            loading={personalInfo.loaders.personalInfo.loading}
                            error={personalInfo.loaders.personalInfo.error}
                        >
                            <FormControl variant="outlined" fullWidth>
                                <InputLabel shrink htmlFor="lastNameAr">
                                    <Typography variant="heading2">
                                        {"الكنية بالعربية *"}
                                    </Typography>
                                </InputLabel>
                                <InputBase
                                    type="text"
                                    variant="labeled"
                                    placeholder="اكتب هنا"
                                    id="lastNameAr"
                                    value={personalInfo.lastNameAr}
                                    onChange={(e) =>
                                        dispatch(setLastNameAr(e.target.value))
                                    }
                                />
                                <ValidationErrorMessage
                                    errors={personalInfo.errors}
                                    field={"lastNameAr"}
                                />
                            </FormControl>
                        </Loader>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <Loader
                            loading={personalInfo.loaders.personalInfo.loading}
                            error={personalInfo.loaders.personalInfo.error}
                        >
                            <FormControl variant="outlined" fullWidth>
                                <InputLabel shrink htmlFor="firstNameEn">
                                    <Typography variant="heading2">
                                        {"الاسم الأول بالأحرف الللاتينية *"}
                                    </Typography>
                                </InputLabel>
                                <InputBase
                                    type="text"
                                    variant="labeled"
                                    placeholder="اكتب هنا"
                                    id="firstNameEn"
                                    value={personalInfo.firstNameEn}
                                    onChange={(e) =>
                                        dispatch(setFirstNameEn(e.target.value))
                                    }
                                />
                                <ValidationErrorMessage
                                    errors={personalInfo.errors}
                                    field={"firstNameEn"}
                                />
                            </FormControl>
                        </Loader>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <Loader
                            loading={personalInfo.loaders.personalInfo.loading}
                            error={personalInfo.loaders.personalInfo.error}
                        >
                            <FormControl variant="outlined" fullWidth>
                                <InputLabel shrink htmlFor="lastNameEn">
                                    <Typography variant="heading2">
                                        {"الكنية بالأحرف اللاتينية *"}
                                    </Typography>
                                </InputLabel>
                                <InputBase
                                    type="text"
                                    variant="labeled"
                                    placeholder="اكتب هنا"
                                    id="lastNameEn"
                                    value={personalInfo.lastNameEn}
                                    onChange={(e) =>
                                        dispatch(setLastNameEn(e.target.value))
                                    }
                                />
                                <ValidationErrorMessage
                                    errors={personalInfo.errors}
                                    field={"lastNameEn"}
                                />
                            </FormControl>
                        </Loader>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <Loader
                            loading={personalInfo.loaders.personalInfo.loading}
                            error={personalInfo.loaders.personalInfo.error}
                        >
                            <FormControl variant="outlined" fullWidth>
                                <InputLabel shrink htmlFor="gender">
                                    <Typography variant="heading2">
                                        {"الجنس *"}
                                    </Typography>
                                </InputLabel>
                                <Autocomplete
                                    variant="labeled"
                                    id="gender"
                                    value={personalInfo.gender}
                                    options={GenderOptions}
                                    getOptionLabel={(option) =>
                                        option ? option.label : ""
                                    }
                                    isOptionEqualToValue={(option, value) =>
                                        option.value === value.value
                                    }
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            placeholder="اكتب هنا"
                                        />
                                    )}
                                    onChange={(e, option) =>
                                        dispatch(setGender(option))
                                    }
                                />
                                <ValidationErrorMessage
                                    errors={personalInfo.errors}
                                    field={"gender"}
                                />
                            </FormControl>
                        </Loader>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                        <Loader
                            loading={personalInfo.loaders.personalInfo.loading}
                            error={personalInfo.loaders.personalInfo.error}
                        >
                            <FormControl variant="outlined" fullWidth>
                                <InputLabel shrink htmlFor="year">
                                    <Typography variant="heading2">
                                        {"تاريخ الميلاد *"}
                                    </Typography>
                                </InputLabel>
                                <Autocomplete
                                    variant="labeled"
                                    id="year"
                                    value={personalInfo.year}
                                    options={personalInfo.availableYears}
                                    getOptionLabel={(option) =>
                                        option ? option.label : ""
                                    }
                                    isOptionEqualToValue={(option, value) =>
                                        option.value === value.value
                                    }
                                    onChange={(e, option) =>
                                        dispatch(setYear(option))
                                    }
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            placeholder="السنة"
                                        />
                                    )}
                                />
                                <ValidationErrorMessage
                                    errors={personalInfo.errors}
                                    field={"year"}
                                />
                            </FormControl>
                        </Loader>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                        <Loader
                            loading={personalInfo.loaders.personalInfo.loading}
                            error={personalInfo.loaders.personalInfo.error}
                        >
                            <FormControl variant="outlined" fullWidth>
                                <InputLabel shrink htmlFor="month">
                                    <Typography variant="heading2">
                                        {""}
                                    </Typography>
                                </InputLabel>
                                <Autocomplete
                                    variant="labeled"
                                    id="month"
                                    value={personalInfo.month}
                                    options={personalInfo.availableMonths}
                                    getOptionLabel={(option) =>
                                        option ? option.label : ""
                                    }
                                    isOptionEqualToValue={(option, value) =>
                                        option.value === value.value
                                    }
                                    onChange={(e, option) =>
                                        dispatch(setMonth(option))
                                    }
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            placeholder="الشهر"
                                        />
                                    )}
                                />
                                <ValidationErrorMessage
                                    errors={personalInfo.errors}
                                    field={"month"}
                                />
                            </FormControl>
                        </Loader>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                        <Loader
                            loading={personalInfo.loaders.personalInfo.loading}
                            error={personalInfo.loaders.personalInfo.error}
                        >
                            <FormControl variant="outlined" fullWidth>
                                <InputLabel shrink htmlFor="day">
                                    <Typography variant="heading2">
                                        {""}
                                    </Typography>
                                </InputLabel>
                                <Autocomplete
                                    variant="labeled"
                                    id="day"
                                    value={personalInfo.day}
                                    options={personalInfo.availableDays}
                                    getOptionLabel={(option) =>
                                        option ? option.label : ""
                                    }
                                    isOptionEqualToValue={(option, value) =>
                                        option.value === value.value
                                    }
                                    onChange={(e, option) =>
                                        dispatch(setDay(option))
                                    }
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            placeholder="اليوم"
                                        />
                                    )}
                                />
                                <ValidationErrorMessage
                                    errors={personalInfo.errors}
                                    field={"day"}
                                />
                            </FormControl>
                        </Loader>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <Loader
                            loading={personalInfo.loaders.personalInfo.loading}
                            error={personalInfo.loaders.personalInfo.error}
                        >
                            <FormControl variant="outlined" fullWidth>
                                <InputLabel shrink htmlFor="nationality1">
                                    <Typography variant="heading2">
                                        {"الجنسية *"}
                                    </Typography>
                                </InputLabel>
                                <Autocomplete
                                    variant="labeled"
                                    id="nationality1"
                                    value={personalInfo.nationality1}
                                    options={
                                        personalInfo.availableNationalities
                                    }
                                    getOptionLabel={(option) =>
                                        option ? option.nameAr : ""
                                    }
                                    isOptionEqualToValue={(option, value) =>
                                        option.id === value.id
                                    }
                                    onChange={(e, option) =>
                                        dispatch(setNationality1(option))
                                    }
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            placeholder="اختر من هنا"
                                        />
                                    )}
                                />
                                <ValidationErrorMessage
                                    errors={personalInfo.errors}
                                    field={"nationality1"}
                                />
                            </FormControl>
                        </Loader>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <Loader
                            loading={personalInfo.loaders.personalInfo.loading}
                            error={personalInfo.loaders.personalInfo.error}
                        >
                            <FormControl variant="outlined" fullWidth>
                                <InputLabel shrink htmlFor="nationality2">
                                    <Typography variant="heading2">
                                        {"الجنسية الثانية"}
                                    </Typography>
                                </InputLabel>
                                <Autocomplete
                                    variant="labeled"
                                    id="nationality2"
                                    value={personalInfo.nationality2}
                                    options={
                                        personalInfo.availableNationalities
                                    }
                                    getOptionLabel={(option) =>
                                        option ? option.nameAr : ""
                                    }
                                    isOptionEqualToValue={(option, value) =>
                                        option.id === value.id
                                    }
                                    onChange={(e, option) =>
                                        dispatch(setNationality2(option))
                                    }
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            placeholder="اختر من هنا"
                                        />
                                    )}
                                />
                            </FormControl>
                        </Loader>
                    </Grid>
                    <Grid item xs={12}>
                        <LanguageExperiencesList />
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container justifyContent={"space-between"}>
                            <Grid item xs={3}>
                                <Button
                                    fullWidth
                                    variant="outlined"
                                    sx={{ paddingY: 2 }}
                                    disabled
                                >
                                    {"السابق"}
                                </Button>
                            </Grid>
                            <Grid item xs={3}>
                                <Button
                                    fullWidth
                                    variant="main"
                                    sx={{ paddingY: 2 }}
                                    onClick={() => {
                                        handleSubmit();
                                        dispatch(setNext());
                                    }}
                                >
                                    {"التالي"}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
};

export default Form;

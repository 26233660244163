import { Box, Stack } from "@mui/material";
import React from "react";
import Progressor from "../../components/pages/register/Progressor";
import Form from "../../components/pages/register/personal-info/Form";
const PersonalInfo = () => {
    return (
        <Box className="page">
            <Stack gap={4} sx={{ "& > *": { width: "100%" } }}>
                <Form />
            </Stack>
        </Box>
    );
};

export default PersonalInfo;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
    CustomerTypeOptions,
    ExperienceTypeOptions,
    ServiceDeliveryTypeOptions,
} from "../../services/constants";
import {
    CurrencyAPI,
    FreelanceWorkFieldAPI,
    WorkFieldAPI,
} from "../../services/api/model";
import UploadAPI from "../../services/api/Upload";
import ServicesAPI from "../../services/api/Services";

const initialState = {
    id: null,
    freelanceWorkFields: [],
    currencies: [],
    description: null,
    availableToWorkRemotely: false,
    contactToPrice: false,
    price: 0,
    link: "",
    files: [],
    links: [],
    closed: true,
    selectedFreelanceWorkField: [],
    selectedCurrency: null,
    loaders: {
        services: {
            loading: false,
            error: null,
        },
        workFields: {
            loading: false,
            error: null,
        },
        currencies: {
            loading: false,
            error: null,
        },
        uploadFile: {
            loading: false,
            error: null,
        },
    },
};

export const fetchServices = createAsyncThunk(
    "services/fetchServices",
    async () => {
        const result = await ServicesAPI.getAll();
        return result;
    }
);

export const createServices = createAsyncThunk(
    "services/createServices",
    async ({ data }, { dispatch }) => {
        const result = await ServicesAPI.create(data);
        dispatch(fetchServices());
        return result;
    }
);

export const updateServices = createAsyncThunk(
    "services/updateServices",
    async ({ id, data }, { dispatch }) => {
        const result = await ServicesAPI.update(id, data);
        dispatch(fetchServices());
        return result;
    }
);

export const fetchAllWorkFields = createAsyncThunk(
    "services/fetchAllWorkFields",
    async () => {
        const result = await FreelanceWorkFieldAPI.getAll();
        return result;
    }
);

export const fetchAllCurrencies = createAsyncThunk(
    "services/fetchAllCurrencies",
    async () => {
        const result = await CurrencyAPI.getAll();
        return result;
    }
);

export const uploadFile = createAsyncThunk(
    "services/uploadFile",
    async ({ file, path }) => {
        const result = await UploadAPI.uploadFile(file, path);
        return result;
    }
);

const slice = createSlice({
    name: "servicesForm",
    initialState,
    reducers: {
        setSelectedFreelanceWorkField(state, action) {
            state.selectedFreelanceWorkField = action.payload;
        },
        setSelectedCurrency(state, action) {
            state.selectedCurrency = action.payload;
        },
        setDescription(state, action) {
            state.description = action.payload;
        },
        setAvailableToWorkRemotely(state, action) {
            state.availableToWorkRemotely = action.payload;
        },
        setContactToPrice(state, action) {
            state.contactToPrice = action.payload;
        },
        setPrice(state, action) {
            state.price = action.payload;
        },
        addFile(state, action) {
            state.files.push(action.payload);
        },
        removeFile(state, action) {
            state.files = state.files.filter((file) => file !== action.payload);
        },
        addLink(state, action) {
            state.links.push(action.payload);
        },
        setLink(state, action) {
            state.link = action.payload;
        },
        removeLink(state, action) {
            state.links = state.links.filter(
                (link, index) => index !== action.payload
            );
        },
        setAllFiles(state, action) {
            state.files = action.payload;
        },
        setAllLinks(state, action) {
            state.links = action.payload;
        },

        setErrors(state, action) {
            state.errors = action.payload;
        },
        openForm: (state) => {
            state.closed = false;
        },
        closeForm: (state) => {
            state.closed = true;
        },
        resetAll(state) {
            return initialState;
        },
        setAll(state, action) {
            return {
                ...state,
                ...action.payload,
            };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchServices.pending, (state) => {
                state.loaders.services.loading = true;
                state.loaders.services.error = null;
            })
            .addCase(fetchServices.fulfilled, (state, action) => {
                state.loaders.services.loading = false;
                if (
                    action.payload &&
                    Array.isArray(action.payload) &&
                    action.payload.length > 0
                ) {
                    action.payload = action.payload[0];
                    state.id = action.payload.id;
                    state.selectedFreelanceWorkField =
                        action.payload.freelanceWorkFields;
                    state.selectedCurrency = action.payload.currency;
                    state.description = action.payload.description;
                    state.availableToWorkRemotely =
                        action.payload.availableToWorkRemotely;
                    state.contactToPrice = action.payload.contactToPrice;
                    state.price = action.payload.price;
                    state.files = action.payload.files;
                    state.links = action.payload.links;
                }
            })
            .addCase(fetchServices.rejected, (state, action) => {
                state.loaders.services.loading = false;
                state.loaders.services.error = action.error.message;
            })
            .addCase(fetchAllWorkFields.pending, (state) => {
                state.loaders.workFields.loading = true;
                state.loaders.workFields.error = null;
            })
            .addCase(fetchAllWorkFields.fulfilled, (state, action) => {
                state.loaders.workFields.loading = false;
                state.freelanceWorkFields = action.payload;
            })
            .addCase(fetchAllWorkFields.rejected, (state, action) => {
                state.loaders.workFields.loading = false;
                state.loaders.workFields.error = action.error.message;
            })
            .addCase(fetchAllCurrencies.pending, (state) => {
                state.loaders.currencies.loading = true;
                state.loaders.currencies.error = null;
            })
            .addCase(fetchAllCurrencies.fulfilled, (state, action) => {
                state.loaders.currencies.loading = false;
                state.currencies = action.payload;
            })
            .addCase(fetchAllCurrencies.rejected, (state, action) => {
                state.loaders.currencies.loading = false;
                state.loaders.currencies.error = action.error.message;
            })
            .addCase(uploadFile.pending, (state) => {
                state.loaders.uploadFile.loading = true;
                state.loaders.uploadFile.error = null;
            })
            .addCase(uploadFile.fulfilled, (state, action) => {
                state.loaders.uploadFile.loading = false;
                state.files.push(action.payload.url);
            })
            .addCase(uploadFile.rejected, (state, action) => {
                state.loaders.uploadFile.loading = true;
                state.loaders.uploadFile.error = action.message;
            });
    },
});

export const {
    setSelectedFreelanceWorkField,
    setSelectedCurrency,
    setDescription,
    setAvailableToWorkRemotely,
    setContactToPrice,
    setPrice,
    addFile,
    removeFile,
    addLink,
    setLink,
    removeLink,
    setAllFiles,
    setAllLinks,
    setErrors,
    openForm,
    closeForm,
    resetAll,
    setAll,
} = slice.actions;

export default slice.reducer;

import {
    Box,
    Button,
    FormControl,
    IconButton,
    InputBase,
    InputLabel,
    Stack,
    Typography,
    Link as MuiLink,
    useMediaQuery,
} from "@mui/material";
import React, { useState } from "react";

import { Eye, EyeSlash } from "@phosphor-icons/react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
    setEmail,
    setPassword,
    setShowPassword,
} from "../../../store/slices/login";
import AuthAPI from "../../../services/api/auth";
import { login, loginThunk } from "../../../store/slices/user";
import { useTheme } from "@emotion/react";

const Form = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { showPassword, email, password } = useSelector(
        (state) => state.login
    );

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

    const OnSubmit = async () => {
        dispatch(loginThunk({ email, password }));
    };

    return (
        <Box
            component="form"
            noValidate
            width={isSmallScreen ? "100%" : "500px"}
        >
            <Stack gap={2} sx={{ "& > *": { width: "100%" } }}>
                <FormControl variant="outlined">
                    <InputLabel shrink htmlFor="email">
                        <Typography variant="heading2">
                            {"البريد الالكتروني"}
                        </Typography>
                    </InputLabel>
                    <InputBase
                        type="email"
                        variant="labeled"
                        placeholder="اكتب هنا"
                        id="email"
                        defaultValue={email}
                        onChange={(e) => dispatch(setEmail(e.target.value))}
                    />
                </FormControl>
                <FormControl variant="outlined">
                    <InputLabel shrink htmlFor="password">
                        <Typography variant="heading2">
                            {"كلمة المرور"}
                        </Typography>
                    </InputLabel>
                    <InputBase
                        type={showPassword ? "text" : "password"}
                        variant="labeled"
                        placeholder="اكتب هنا"
                        id="password"
                        defaultValue={password}
                        onChange={(e) => dispatch(setPassword(e.target.value))}
                        endAdornment={
                            <IconButton
                                onClick={() =>
                                    dispatch(setShowPassword(!showPassword))
                                }
                            >
                                {showPassword ? (
                                    <EyeSlash size={32} />
                                ) : (
                                    <Eye size={32} />
                                )}
                            </IconButton>
                        }
                    />
                    <MuiLink
                        sx={{
                            color: "",
                            textDecoration: "none",
                        }}
                        component={Link}
                        to="/auth/reset-password"
                    >
                        {"هل نسيت كلمة المرور ؟"}
                    </MuiLink>
                </FormControl>
                <FormControl variant="outlined">
                    <Button variant="main" onClick={() => OnSubmit()}>
                        {"تسجيل الدخول"}
                    </Button>
                </FormControl>
            </Stack>
        </Box>
    );
};

export default Form;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { LanguageAPI } from "../../services/api/model";
import LanguageExperienceAPI from "../../services/api/languageExperience";
import { resetAll, setAll } from "./languageExperiencesForm";
import { LanguageLevelLabels } from "../../services/constants";

const initialState = {
    all: [],
    loaders: {
        allLanguageExperiences: {
            loading: false,
            error: null,
        },
        oneLanguageExperience: {
            loading: false,
            error: null,
        },
    },
};

export const fetchAllLanguageExperiences = createAsyncThunk(
    "languages/fetchAllLanguageExperiences",
    async ({ userId }) => {
        const result = await LanguageExperienceAPI.getAll(userId);
        return result;
    }
);

export const fetchOneLanguageExperience = createAsyncThunk(
    "languages/fetchOneLanguageExperience",
    async ({ userId, id }, { dispatch }) => {
        const result = await LanguageExperienceAPI.getOne(userId, id);
        dispatch(
            setAll({
                lang: result.language,
                level: {
                    label: LanguageLevelLabels[result.languageLevel],
                    value: result.languageLevel,
                },
                current: result,
            })
        );
        return result;
    }
);

export const createLanguageExperience = createAsyncThunk(
    "languages/createLanguageExperience",
    async ({ userId, languageId, level }, { dispatch }) => {
        const result = await LanguageExperienceAPI.create(userId, {
            languageId,
            languageLevel: level,
        });
        dispatch(fetchAllLanguageExperiences({ userId }));
        dispatch(resetAll());
        return result;
    }
);

export const updateLanguageExperience = createAsyncThunk(
    "languages/updateLanguageExperience",
    async ({ userId, id, languageId, level }, { dispatch }) => {
        const result = await LanguageExperienceAPI.update(userId, id, {
            languageId: languageId,
            languageLevel: level,
        });
        dispatch(fetchAllLanguageExperiences({ userId }));
        dispatch(resetAll());
        return result;
    }
);

export const deleteLanguageExperience = createAsyncThunk(
    "languages/deleteLanguageExperience",
    async ({ userId, id }, { dispatch }) => {
        const result = await LanguageExperienceAPI.delete(userId, id);
        dispatch(fetchAllLanguageExperiences({ userId }));
        return result;
    }
);

const languageExperiencesSlice = createSlice({
    name: "languageExperiences",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllLanguageExperiences.pending, (state) => {
                state.loaders.allLanguageExperiences.loading = true;
                state.loaders.allLanguageExperiences.error = null;
            })
            .addCase(fetchAllLanguageExperiences.fulfilled, (state, action) => {
                state.loaders.allLanguageExperiences.loading = false;
                state.all = action.payload;
            })
            .addCase(fetchAllLanguageExperiences.rejected, (state, action) => {
                state.loaders.allLanguageExperiences.loading = true;
                state.loaders.allLanguageExperiences.error = action.message;
            })
            .addCase(fetchOneLanguageExperience.pending, (state) => {
                state.loaders.oneLanguageExperience.loading = true;
                state.loaders.oneLanguageExperience.error = null;
            })
            .addCase(fetchOneLanguageExperience.fulfilled, (state, action) => {
                state.loaders.oneLanguageExperience.loading = false;
            })
            .addCase(fetchOneLanguageExperience.rejected, (state, action) => {
                state.loaders.oneLanguageExperience.loading = true;
                state.loaders.oneLanguageExperience.error = action.message;
            });
    },
});

export const {} = languageExperiencesSlice.actions;

export default languageExperiencesSlice.reducer;

import API from "./Api";

export default class ExperienceAPI {
    static async getAll(userId) {
        const result = await API.get(
            `/personal-practical-experience?user=${userId}`
        );
        return result;
    }

    static async getOne(userId, id) {
        const result = await API.get(
            `/personal-practical-experience/${id}?user=${userId}`
        );
        return result;
    }

    static async create(userId, data) {
        const result = await API.post(
            `/personal-practical-experience?user=${userId}`,
            data
        );
        return result;
    }

    static async update(userId, id, data) {
        const result = await API.put(
            `/personal-practical-experience/${id}?user=${userId}`,
            data
        );
        return result;
    }
}

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import CoachingAPI from "../../services/api/Coaching";
import {
    CustomerTypeOptions,
    ExperienceTypeOptions,
    ServiceDeliveryTypeOptions,
} from "../../services/constants";
import { closeForm, openForm, resetAll, setAll } from "./coachingForm";

const initialState = {
    all: [],
    current: null,
    loaders: {
        all: {
            loading: false,
            error: null,
        },
        current: {
            loading: false,
            error: null,
        },
    },
};

export const fetchAllCoaching = createAsyncThunk(
    "coaching/fetchAllCoaching",
    async () => {
        const result = await CoachingAPI.getAll();
        return result;
    }
);

export const fetchOneCoaching = createAsyncThunk(
    "coaching/fetchOneCoaching",
    async ({ id }, { dispatch }) => {
        const result = await CoachingAPI.getOne(id);

        const selectedExperienceType = ExperienceTypeOptions.find(
            (item) => item.value == result.experienceType
        );
        const customerTypes = CustomerTypeOptions.map((item) => {
            const newItem = { ...item };
            newItem.checked = result.customerType.includes(newItem.value);
            return newItem;
        });
        const serviceDeliveryTypes = ServiceDeliveryTypeOptions.map((item) => {
            const newItem = { ...item };
            newItem.checked = result.serviceDeliveryType.includes(
                newItem.value
            );
            return newItem;
        });
        console.log(serviceDeliveryTypes);
        dispatch(
            setAll({
                selectedExperienceType,
                selectedWorkField: result.workField,
                customerTypes,
                serviceDeliveryTypes,
                customerNumber: result.customerNumber,
                averageSalaryPerHour: result.averageSalaryPerHour,
                availabilityToTravel: result.availabilityToTravel,
                providedHours: result.providedHours,
                hasRecordedTrainings: result.recordedExerciseLink
                    ? "yes"
                    : "no",
                recordedExerciseLink: result.recordedExerciseLink,
                contractedEntities: result.contractedEntities,
                files: result.files,
                links: result.links,
            })
        );
        dispatch(openForm());
        return result;
    }
);

export const createCoaching = createAsyncThunk(
    "coaching/createCoaching",
    async ({ data }, { dispatch }) => {
        const result = await CoachingAPI.create(data);
        dispatch(fetchAllCoaching());
        dispatch(resetAll());
        dispatch(closeForm());
        return result;
    }
);

export const updateCoaching = createAsyncThunk(
    "coaching/updateCoaching",
    async ({ id, data }, { dispatch }) => {
        const result = await CoachingAPI.update(id, data);
        dispatch(fetchAllCoaching());
        dispatch(resetAll());
        dispatch(closeForm());
        return result;
    }
);

export const deleteCoaching = createAsyncThunk(
    "coaching/deleteCoaching",
    async ({ id }, { dispatch }) => {
        const result = await CoachingAPI.delete(id);
        dispatch(fetchAllCoaching());
        dispatch(resetAll());
        return result;
    }
);

const slice = createSlice({
    name: "coachingData",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllCoaching.pending, (state) => {
                state.loaders.all.loading = true;
                state.loaders.all.error = null;
            })
            .addCase(fetchAllCoaching.fulfilled, (state, action) => {
                state.loaders.all.loading = false;
                state.all = action.payload;
            })
            .addCase(fetchAllCoaching.rejected, (state, action) => {
                state.loaders.all.loading = true;
                state.loaders.all.error = action.message;
            })
            .addCase(fetchOneCoaching.pending, (state) => {
                state.loaders.current.loading = true;
                state.loaders.current.error = null;
            })
            .addCase(fetchOneCoaching.fulfilled, (state, action) => {
                state.loaders.current.loading = false;
                state.current = action.payload;
            })
            .addCase(fetchOneCoaching.rejected, (state, action) => {
                state.loaders.current.loading = true;
                state.loaders.current.error = action.message;
            })
            .addCase(updateCoaching.fulfilled, (state, action) => {
                state.current = null;
            })
            .addCase(deleteCoaching.fulfilled, (state, action) => {
                state.current = null;
            });
    },
});

export const {} = slice.actions;

export default slice.reducer;

import {
    Avatar,
    Box,
    Button,
    Collapse,
    Divider,
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemButton,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    Link as MuiLink,
    Stack,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import LogoutBtn from "./LogoutBtn";
import Logo from "./Logo";
import CMenu from "../common/Menu";
import MenuLink from "./MenuLink";
import { useDispatch, useSelector } from "react-redux";
import { MenuLinks, ProfileLinks } from "../../services/constants";
import TmpImage from "../../assets/img/logo.png";
import {
    CaretDown,
    CaretUp,
    List as ListIcon,
    SignOut,
} from "@phosphor-icons/react";
import { logoutThunk } from "../../store/slices/user";
import { closeList, openList } from "../../store/slices/list";

const NavbarMobile = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const allLists = useSelector((state) => state.list.all);
    const role = useSelector((state) => state.user.role);
    const fullName = useSelector((state) => {
        return `${state.personalInfo.firstNameAr} ${state.personalInfo.lastNameAr}`;
    });

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

    const links = MenuLinks[role];
    const profilelinks = ProfileLinks[role];

    const renderLinks = (links, parent = false) => {
        return links.map((link) => {
            if (link.children && link.children.length > 0) {
                return (
                    <List>
                        <ListItemButton
                            onClick={(event) => {
                                event.stopPropagation();
                                allLists?.[link.id]?.opened
                                    ? dispatch(closeList(link.id))
                                    : dispatch(openList(link.id));
                            }}
                        >
                            <ListItemText
                                primary={link.title}
                                sx={{ textAlign: "right" }}
                            />
                            {allLists?.[link.id]?.opened ? (
                                <CaretUp size={20} />
                            ) : (
                                <CaretDown size={20} />
                            )}
                        </ListItemButton>
                        {renderLinks(link.children, link.id)}
                    </List>
                );
            } else {
                if (parent) {
                    return (
                        <Collapse
                            in={allLists?.[parent]?.opened}
                            timeout="auto"
                            unmountOnExit
                        >
                            <ListItemButton
                                key={link.title}
                                onClick={() => navigate(link.route)}
                                sx={{ pr: parent ? 4 : 2 }}
                            >
                                <ListItemText
                                    primary={link.title}
                                    sx={{ textAlign: "right" }}
                                />
                            </ListItemButton>
                        </Collapse>
                    );
                } else {
                    return (
                        <ListItemButton
                            key={link.title}
                            onClick={() => navigate(link.route)}
                            sx={{ pr: parent ? 4 : 2 }}
                        >
                            <ListItemText
                                primary={link.title}
                                sx={{ textAlign: "right" }}
                            />
                        </ListItemButton>
                    );
                }
            }
        });
    };

    const [open, setOpen] = React.useState(false);

    const toggleDrawer = (newOpen) => () => {
        setOpen(newOpen);
    };

    return (
        <Stack
            direction={"row"}
            padding={isSmallScreen ? "1rem" : "2rem"}
            gap={2}
            justifyContent={"space-between"}
        >
            <Stack direction={"row"} gap={2}>
                <MuiLink component={Link} to={"/"}>
                    <Logo />
                </MuiLink>
            </Stack>

            <Button onClick={toggleDrawer(true)}>
                <ListIcon size={32} />
            </Button>
            <Drawer open={open} onClose={toggleDrawer(false)} anchor="right">
                <Box
                    sx={{ width: 250 }}
                    role="presentation"
                    onClick={toggleDrawer(false)}
                    anc
                >
                    <List>
                        <ListItem>
                            <Logo variant="small" />
                        </ListItem>
                        {renderLinks(links)}
                        <Divider />
                        <ListItemButton
                            onClick={(event) => {
                                event.stopPropagation();
                                allLists?.["PROFILE"]?.opened
                                    ? dispatch(closeList("PROFILE"))
                                    : dispatch(openList("PROFILE"));
                            }}
                        >
                            <ListItemText
                                primary={fullName}
                                sx={{ textAlign: "start" }}
                            />
                            <ListItemAvatar>
                                <Avatar src={TmpImage}></Avatar>
                            </ListItemAvatar>
                            {allLists?.["PROFILE"]?.opened ? (
                                <CaretUp size={20} />
                            ) : (
                                <CaretDown size={20} />
                            )}
                        </ListItemButton>
                        <Collapse
                            in={allLists?.["PROFILE"]?.opened}
                            timeout="auto"
                            unmountOnExit
                        >
                            <List component="div" disablePadding>
                                {profilelinks.map((link) => (
                                    <ListItemButton
                                        key={link.title}
                                        sx={{ pr: 4 }}
                                        onClick={() => navigate(link.route)}
                                    >
                                        <ListItemText
                                            primary={link.title}
                                            sx={{ textAlign: "start" }}
                                        />
                                    </ListItemButton>
                                ))}
                                <ListItemButton
                                    onClick={() => dispatch(logoutThunk())}
                                    sx={{ pr: 4 }}
                                >
                                    <ListItemText
                                        primary={"تسجيل الخروج"}
                                        sx={{ textAlign: "start" }}
                                    />
                                    <ListItemIcon>
                                        <SignOut size={20} />
                                    </ListItemIcon>
                                </ListItemButton>
                            </List>
                        </Collapse>
                    </List>
                </Box>
            </Drawer>
        </Stack>
    );
};

export default NavbarMobile;

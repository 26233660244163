import API from "./Api";

export default class EducationAPI {
    static async getAll(userId) {
        const result = await API.get(`/education?user=${userId}`);
        return result;
    }

    static async getOne(userId, id) {
        const result = await API.get(`/education/${id}?user=${userId}`);
        return result;
    }

    static async create(userId, data) {
        const result = await API.post(`/education?user=${userId}`, data);
        return result;
    }

    static async update(userId, id, data) {
        const result = await API.put(`/education/${id}?user=${userId}`, data);
        return result;
    }

    static async delete(userId, id) {
        const result = await API.delete(`/education/${id}?user=${userId}`);
        return result;
    }
}

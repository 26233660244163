import {
    Autocomplete,
    Box,
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    Grid,
    InputBase,
    InputLabel,
    TextField,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import useForm from "./useForm";
import { setNext, setPrev } from "../../../../store/slices/stepper";
import {
    setAvailableToWorkRemotely,
    setContactToPrice,
    setDescription,
    setPrice,
    setSelectedCurrency,
    setSelectedFreelanceWorkField,
} from "../../../../store/slices/servicesForm";
import ValidationErrorMessage from "../../../common/ValidationErrorMessage";
import Loader from "../../../common/Loader";
import Files from "./Files";
import Links from "./Links";

const Form = () => {
    const dispatch = useDispatch();

    const theme = useTheme();
    const isMdScreen = useMediaQuery(theme.breakpoints.down("md"));

    const [servicesForm, handleSubmit] = useForm();

    return (
        <Box component={"form"}>
            <Grid
                container
                direction={"row"}
                justifyContent={"center"}
                rowSpacing={5}
                columnSpacing={isMdScreen ? 0 : 10}
            >
                <Grid item xs={12} sm={12} md={12}>
                    <Loader
                        loading={servicesForm.loaders.workFields.loading}
                        error={servicesForm.loaders.workFields.error}
                    >
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel
                                shrink
                                htmlFor="selectedFreelanceWorkField"
                            >
                                <Typography variant="heading2">
                                    {"الخدمات *"}
                                </Typography>
                            </InputLabel>
                            <Autocomplete
                                multiple
                                variant="labeled"
                                id="selectedFreelanceWorkField"
                                value={servicesForm.selectedFreelanceWorkField}
                                options={servicesForm.freelanceWorkFields}
                                getOptionLabel={(option) =>
                                    option ? option.nameAr : ""
                                }
                                isOptionEqualToValue={(option, value) =>
                                    option.id === value.id
                                }
                                onChange={(e, option) =>
                                    dispatch(
                                        setSelectedFreelanceWorkField(option)
                                    )
                                }
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        placeholder="اختر من هنا"
                                    />
                                )}
                            />
                            <ValidationErrorMessage
                                errors={servicesForm.errors}
                                field={"selectedFreelanceWorkField"}
                            />
                        </FormControl>
                    </Loader>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                    <FormControl variant="outlined" fullWidth>
                        <InputLabel shrink htmlFor="description">
                            <Typography variant="heading2">
                                {"وصف الخدمات*"}
                            </Typography>
                        </InputLabel>
                        <InputBase
                            multiline
                            minRows={5}
                            maxRows={10}
                            variant="labeled"
                            id="description"
                            value={servicesForm.description}
                            onChange={(e) =>
                                dispatch(setDescription(e.target.value))
                            }
                            placeholder="اكتب هنا"
                        />
                        <ValidationErrorMessage
                            errors={servicesForm.errors}
                            field={"description"}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                    <Loader loading={false} error={null}>
                        <FormControl variant="outlined" fullWidth>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={
                                            servicesForm.availableToWorkRemotely
                                        }
                                        value={
                                            servicesForm.availableToWorkRemotely
                                        }
                                        onChange={(e) =>
                                            dispatch(
                                                setAvailableToWorkRemotely(
                                                    e.target.checked
                                                )
                                            )
                                        }
                                    />
                                }
                                label={"متاح للعمل عن بعد"}
                                sx={{
                                    "& .MuiTypography-root": {
                                        color: theme.palette.primary.main,
                                        fontSize: "1.25rem",
                                        fontWeight: "500",
                                    },
                                }}
                            />
                            <ValidationErrorMessage
                                errors={servicesForm.errors}
                                field={"availableToWorkRemotely"}
                            />
                        </FormControl>
                    </Loader>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                    <FormControl variant="outlined" fullWidth>
                        <InputLabel shrink>
                            <Typography
                                sx={{
                                    fontWeight: 800,
                                    color: theme.palette.primary.main,
                                    fontSize: "1.25rem",
                                }}
                            >
                                {"التسعير"}
                            </Typography>
                        </InputLabel>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    <Loader
                        loading={servicesForm.loaders.currencies.loading}
                        error={servicesForm.loaders.currencies.error}
                    >
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel shrink htmlFor="selectedCurrency">
                                <Typography variant="heading2">
                                    {"العملة *"}
                                </Typography>
                            </InputLabel>
                            <Autocomplete
                                variant="labeled"
                                id="selectedCurrency"
                                value={servicesForm.selectedCurrency}
                                options={servicesForm.currencies}
                                getOptionLabel={(option) =>
                                    option ? option.nameAr : ""
                                }
                                isOptionEqualToValue={(option, value) =>
                                    option.id === value.id
                                }
                                onChange={(e, option) =>
                                    dispatch(setSelectedCurrency(option))
                                }
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        placeholder="اختر من هنا"
                                    />
                                )}
                            />
                            <ValidationErrorMessage
                                errors={servicesForm.errors}
                                field={"selectedCurrency"}
                            />
                        </FormControl>
                    </Loader>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    <FormControl variant="outlined" fullWidth>
                        <InputLabel shrink htmlFor="price">
                            <Typography variant="heading2">
                                {"السعر"}
                            </Typography>
                        </InputLabel>
                        <InputBase
                            variant="labeled"
                            id="price"
                            value={servicesForm.price}
                            onChange={(e) => dispatch(setPrice(e.target.value))}
                            placeholder="اكتب هنا"
                            sx={{
                                "& .MuiInputBase-input": {
                                    paddingY: 2.25,
                                },
                            }}
                        />
                        <ValidationErrorMessage
                            errors={servicesForm.errors}
                            field={"price"}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                    <Loader loading={false} error={null}>
                        <FormControl variant="outlined" fullWidth>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={servicesForm.contactToPrice}
                                        value={servicesForm.contactToPrice}
                                        onChange={(e) =>
                                            dispatch(
                                                setContactToPrice(
                                                    e.target.checked
                                                )
                                            )
                                        }
                                    />
                                }
                                label={"تواصل من أجل السعر"}
                                sx={{
                                    "& .MuiTypography-root": {
                                        color: theme.palette.primary.main,
                                        fontSize: "1.25rem",
                                        fontWeight: "500",
                                    },
                                }}
                            />
                            <ValidationErrorMessage
                                errors={servicesForm.errors}
                                field={"contactToPrice"}
                            />
                        </FormControl>
                    </Loader>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                    <FormControl variant="outlined" fullWidth>
                        <InputLabel shrink>
                            <Typography
                                sx={{
                                    fontWeight: 800,
                                    color: theme.palette.primary.main,
                                    fontSize: "1.25rem",
                                }}
                            >
                                {"المستندات الداعمة"}
                            </Typography>
                        </InputLabel>
                    </FormControl>
                </Grid>
                <Files />
                <Links />

                <Grid item xs={12}>
                    <Grid container justifyContent={"space-between"}>
                        <Grid item xs={3}>
                            <Button
                                fullWidth
                                variant="outlined"
                                sx={{ paddingY: 2 }}
                                onClick={() => {
                                    dispatch(setPrev());
                                }}
                            >
                                {"السابق"}
                            </Button>
                        </Grid>
                        <Grid item xs={3}>
                            <Button
                                fullWidth
                                variant="main"
                                sx={{ paddingY: 2 }}
                                onClick={() => {
                                    handleSubmit();
                                    dispatch(setNext());
                                }}
                            >
                                {"التالي"}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
};

export default Form;

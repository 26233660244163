import API from "./Api";

export default class UploadAPI {
    static async uploadFile(file, path) {
        const result = await API.post(
            `/upload-service`,
            {
                file,
                path,
            },
            {
                headers: {
                    "Content-Type": "multipart/form-data; ",
                },
            }
        );
        return result;
    }

    static async deleteFile(fileName, path) {
        const result = await API.delete(`/upload-service`, {
            data: {
                path,
                fileName,
            },
        });
        return result;
    }

    static async uploadImage(file) {
        const result = await API.post(
            `/upload-service/image`,
            {
                file,
            },
            {
                headers: {
                    "Content-Type": "multipart/form-data; ",
                },
            }
        );
        return result;
    }
}

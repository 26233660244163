import {
    Box,
    Grid,
    Stack,
    Step,
    StepContent,
    StepLabel,
    Stepper,
    Typography,
    useTheme,
} from "@mui/material";
import React from "react";
import TmpImage from "../../assets/img/logo.png";
import { Circle } from "@phosphor-icons/react";
import { useNavigate } from "react-router-dom";

const RequestItem = () => {
    const navigate = useNavigate();
    const theme = useTheme();

    return (
        <Box
            className="block"
            sx={{
                width: "100%",
                padding: 4,
                position: "relative",
                borderRadius: 2,
                boxShadow: theme.palette.boxShadow.main1,
                cursor: "pointer",
            }}
            onClick={() => {
                navigate("/received-requests/2");
            }}
        >
            <Stack gap={2}>
                <Stack direction={"row"} gap={2}>
                    <Box
                        component={"img"}
                        src={TmpImage}
                        alt="نادي الخريجين"
                        sx={{
                            objectFit: "contain",
                            objectPosition: "center",
                            height: "4rem",
                        }}
                    />
                    <Stack gap={2}>
                        <Typography fontWeight={"500"} variant="heading2">
                            {"مؤسسة ألفا"}
                        </Typography>
                        <Typography variant="normal">
                            {"مطور واجهات أمامية"}
                        </Typography>
                    </Stack>
                </Stack>
                <Typography variant="heading2" fontWeight={"600"}>
                    {"حالة الطلب"}
                </Typography>
                <Stepper activeStep={0} orientation="vertical">
                    <Step>
                        <StepLabel>{'تمت الاجابة ب "مهتم"'}</StepLabel>
                        <StepContent>{"تم الارسال في  ...."}</StepContent>
                    </Step>
                    <Step>
                        <StepLabel>{"الاجابة على اسئلة الاختبار"}</StepLabel>
                        <StepContent>{"تم الارسال في  ...."}</StepContent>
                    </Step>
                </Stepper>
            </Stack>
            <Box sx={{ position: "absolute", top: 8, right: 8 }}>
                <Circle size={12} weight="fill" color="red" />
            </Box>
            <Box sx={{ position: "absolute", top: 8, left: 8 }}>
                <Typography fontSize={"0.9rem"} color={"gray"}>
                    {"11-2-2022"}
                </Typography>
            </Box>
        </Box>
    );
};

export default RequestItem;

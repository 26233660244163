import {
    DownloadSimple,
    FileText,
    SuitcaseSimple,
    Link as LinkIcon
} from "@phosphor-icons/react";
import React, { useState } from "react";
import Card from "../../common/Card";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    Chip,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Stack,
    Typography,
    useTheme,
    Link as MuiLink,
} from "@mui/material";
import {
    CustomerTypeLabel,
    ExperienceTypeLabel,
    ServiceDeliveryTypeLabel,
} from "../../../services/constants";
import { getFileName } from "../../../services/utils";
import { Link } from "react-router-dom";
const Coaching = ({ user }) => {
    const theme = useTheme();
    const [expanded, setExpanded] = useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    return (
        <Card
            title={"خبرات التدريب و الكوتشينغ"}
            icon={<SuitcaseSimple size={32} />}
        >
            {user.trainingAndCoaching.map((item) => (
                <Accordion
                    expanded={expanded === item.id}
                    onChange={handleChange(item.id)}
                    sx={{
                        "&.MuiPaper-root": {
                            boxShadow: "none",
                            borderBottom: "1px solid #D0D4E0",
                            borderRadius: "0",
                        },
                    }}
                >
                    <AccordionSummary id="panel1bh-header">
                        <Grid container rowSpacing={2}>
                            <Grid item xs={12}>
                                <Chip
                                    label={
                                        ExperienceTypeLabel[item.experienceType]
                                    }
                                    color="primary"
                                    sx={{
                                        paddingY: 3,
                                        paddingX: 4,
                                        borderRadius: "6px",
                                        "& .MuiChip-label": {
                                            fontSize: "20px",
                                            fontWeight: "400",
                                        },
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography
                                    variant="heading2"
                                    sx={{ fontSize: "36px", fontWeight: "700" }}
                                >
                                    {item.workField.nameAr}
                                </Typography>
                            </Grid>

                            <Grid item container alignItems={"center"}>
                                <Grid xs={12} sm={6} md={6}>
                                    <Typography
                                        variant="normal-main"
                                        sx={{
                                            fontSize: "24px",
                                            fontWeight: "600",
                                        }}
                                    >
                                        {`منهجية تقديم الخدمة: ${item.serviceDeliveryType
                                            .map(
                                                (type) =>
                                                    ServiceDeliveryTypeLabel[
                                                        type
                                                    ]
                                            )
                                            .join(", ")}`}
                                    </Typography>
                                </Grid>
                                <Grid xs={12} sm={6} md={6}>
                                    <Typography
                                        variant="normal-main"
                                        sx={{
                                            fontSize: "24px",
                                            fontWeight: "600",
                                        }}
                                    >
                                        {`عدد الساعات: ${item.providedHours} ساعة`}
                                    </Typography>
                                </Grid>
                                <Grid xs={12} sm={6} md={6}>
                                    <Typography
                                        variant="normal-main"
                                        sx={{
                                            fontSize: "24px",
                                            fontWeight: "600",
                                        }}
                                    >
                                        {`نوع العملاء: ${item.customerType
                                            .map(
                                                (type) =>
                                                    CustomerTypeLabel[type]
                                            )
                                            .join(", ")}`}
                                    </Typography>
                                </Grid>
                                <Grid xs={12} sm={6} md={6}>
                                    <Typography
                                        variant="normal-main"
                                        sx={{
                                            fontSize: "24px",
                                            fontWeight: "600",
                                        }}
                                    >
                                        {`عدد العملاء: ${item.customerNumber} عميل`}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid item container justifyContent={"end"}>
                                <Grid item>
                                    <Button
                                        sx={{
                                            textDecoration: "underline",
                                            fontSize: "1.2rem",
                                        }}
                                    >
                                        {expanded == item.id
                                            ? "إخفاء التفاصيل"
                                            : "عرض التفاصيل"}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container rowSpacing={2}>
                            <Grid item xs={12}>
                                <Typography
                                    variant="heading2"
                                    sx={{ fontSize: "36px", fontWeight: "600" }}
                                >
                                    {"الجهات التي تم التعاقد معها"}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Stack direction={"row"} gap={2}>
                                    {item.contractedEntities.map((entity) => (
                                        <Chip
                                            label={entity}
                                            color="primary"
                                            sx={{
                                                paddingY: 3,
                                                paddingX: 4,
                                                borderRadius: "6px",
                                                "& .MuiChip-label": {
                                                    fontSize: "20px",
                                                    fontWeight: "400",
                                                },
                                            }}
                                        />
                                    ))}
                                </Stack>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography
                                    variant="heading2"
                                    sx={{ fontSize: "36px", fontWeight: "600" }}
                                >
                                    {"المستندات الداعمة"}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <List>
                                    {item.files.map((file) => (
                                        <ListItem
                                            secondaryAction={
                                                <IconButton edge="end">
                                                    <DownloadSimple
                                                        size={32}
                                                        color={"#4C576A"}
                                                    />
                                                </IconButton>
                                            }
                                        >
                                            <ListItemText>
                                                <MuiLink
                                                    component={Link}
                                                    download
                                                    to={`${file}`}
                                                    target="_blank"
                                                    color={"#4C576A"}
                                                    sx={{
                                                        textDecoration: "none",
                                                    }}
                                                >
                                                    {getFileName(file)}
                                                </MuiLink>
                                            </ListItemText>
                                            <ListItemIcon>
                                                <FileText
                                                    size={32}
                                                    color={"#4C576A"}
                                                />
                                            </ListItemIcon>
                                        </ListItem>
                                    ))}
                                </List>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography
                                    variant="heading2"
                                    sx={{ fontSize: "36px", fontWeight: "600" }}
                                >
                                    {"الروابط الداعمة"}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <List>
                                    {item.links.map((link) => (
                                        <ListItem>
                                            <ListItemText>
                                                <MuiLink
                                                    component={Link}
                                                    to={`${link}`}
                                                    target="_blank"
                                                    color={"#4C576A"}
                                                    sx={{
                                                        textDecoration: "none",
                                                    }}
                                                >
                                                    {link}
                                                </MuiLink>
                                            </ListItemText>
                                            <ListItemIcon>
                                                <LinkIcon
                                                    size={32}
                                                    color={"#4C576A"}
                                                />
                                            </ListItemIcon>
                                        </ListItem>
                                    ))}
                                </List>
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            ))}
        </Card>
    );
};

export default Coaching;

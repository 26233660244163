import {
    Box,
    Button,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    Stack,
    styled,
    Typography,
    useTheme,
    Link as MuiLink,
} from "@mui/material";
import { Trash, UploadSimple } from "@phosphor-icons/react";
import React from "react";
import XCenter from "../../../common/XCenter";
import { useDispatch, useSelector } from "react-redux";
import { getFileName } from "../../../../services/utils";
import { removeFile, uploadFile } from "../../../../store/slices/servicesForm";
import { Link } from "react-router-dom";

const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
});

const Files = () => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const files = useSelector((state) => state.servicesForm.files);
    return (
        <Grid item container justifyContent={"center"}>
            <Grid item xs={12}>
                <Button
                    component={"label"}
                    role={undefined}
                    tabIndex={-1}
                    sx={{
                        marginTop: 4,
                        paddingY: 2,
                        boxShadow: theme.palette.boxShadow.main1,
                    }}
                    fullWidth
                >
                    <Stack
                        direction={"row"}
                        alignItems={"center"}
                        justifyContent={"space-between"}
                        sx={{
                            width: "100%",
                        }}
                    >
                        <Typography>{"اضغط لتحميل الملف"}</Typography>
                        <VisuallyHiddenInput
                            type="file"
                            id="cvFile"
                            onChange={(e) => {
                                console.log(e.target.files[0]);
                                dispatch(
                                    uploadFile({
                                        file: e.target.files[0],
                                        path: "training_and_certificates",
                                    })
                                );
                            }}
                        />
                        <UploadSimple size={32} />
                    </Stack>
                </Button>
            </Grid>
            <Grid item xs={12}>
                <XCenter>
                    <Button
                        component="label"
                        sx={{
                            textDecoration: "underline",
                            fontSize: "1.2rem",
                        }}
                    >
                        <VisuallyHiddenInput
                            type="file"
                            id="cvFile"
                            onChange={(e) => {
                                console.log(e.target.files[0]);
                                dispatch(
                                    uploadFile({
                                        file: e.target.files[0],
                                        path: "training_and_certificates",
                                    })
                                );
                            }}
                        />
                        {"إضافة ملف آخر"}
                    </Button>
                </XCenter>
            </Grid>
            <Grid item xs={6}>
                <List>
                    {files.map((file) => (
                        <ListItem
                            secondaryAction={
                                <IconButton
                                    edge="end"
                                    onClick={() => dispatch(removeFile(file))}
                                >
                                    <Trash color="red" />
                                </IconButton>
                            }
                        >
                            <MuiLink
                                component={Link}
                                download
                                to={file}
                                target="_blank"
                            >
                                {getFileName(file)}
                            </MuiLink>
                        </ListItem>
                    ))}
                </List>
            </Grid>
        </Grid>
    );
};

export default Files;

import { useTheme } from "@emotion/react";
import {
    Button,
    FormControl,
    Grid,
    InputBase,
    InputLabel,
    Typography,
    useMediaQuery,
} from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import useForm from "./useForm";
import { Box } from "@mui/system";
import {
    openForm,
    setCertificateInstitution,
    setCertificateUrl,
    setExpirationDate,
    setName,
    setReleaseDate,
} from "../../../../store/slices/certificatesForm";
import { DatePickerStyle } from "../../../../services/theme";
import ValidationErrorMessage from "../../../common/ValidationErrorMessage";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import XCenter from "../../../common/XCenter";

const Form = () => {
    const dispatch = useDispatch();

    const theme = useTheme();
    const isMdScreen = useMediaQuery(theme.breakpoints.down("md"));

    const [certificatesForm, certificatesData, handleSubmit] = useForm();
    return (
        <Box component={"form"}>
            {certificatesForm.closed === false && (
                <Grid
                    container
                    direction={"row"}
                    justifyContent={"center"}
                    rowSpacing={5}
                    columnSpacing={isMdScreen ? 0 : 10}
                >
                    <Grid item xs={12}>
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel shrink htmlFor="certificateInstitution">
                                <Typography variant="heading2">
                                    {"المؤسسة المانحة للشهادة *"}
                                </Typography>
                            </InputLabel>
                            <InputBase
                                variant="labeled"
                                placeholder="اكتب هنا"
                                id="certificateInstitution"
                                onChange={(e) =>
                                    dispatch(
                                        setCertificateInstitution(
                                            e.target.value
                                        )
                                    )
                                }
                                value={certificatesForm.certificateInstitution}
                            />
                            <ValidationErrorMessage
                                errors={certificatesForm.errors}
                                field={"certificateInstitution"}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel shrink htmlFor="name">
                                <Typography variant="heading2">
                                    {"اسم الشهادة *"}
                                </Typography>
                            </InputLabel>
                            <InputBase
                                variant="labeled"
                                placeholder="اكتب هنا"
                                id="name"
                                onChange={(e) =>
                                    dispatch(setName(e.target.value))
                                }
                                value={certificatesForm.name}
                            />
                            <ValidationErrorMessage
                                errors={certificatesForm.errors}
                                field={"name"}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel shrink htmlFor="releaseDate">
                                <Typography variant="heading2">
                                    {"تاريخ الإصدار *"}
                                </Typography>
                            </InputLabel>
                            <DatePicker
                                id="releaseDate"
                                value={dayjs(certificatesForm.releaseDate)}
                                onChange={(value) =>
                                    dispatch(setReleaseDate(value))
                                }
                                sx={{ ...DatePickerStyle }}
                            />
                            <ValidationErrorMessage
                                errors={certificatesForm.errors}
                                field={"releaseDate"}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel shrink htmlFor="expirationDate">
                                <Typography variant="heading2">
                                    {"تاريخ انتهاء الصلاحية"}
                                </Typography>
                            </InputLabel>
                            <DatePicker
                                id="expirationDate"
                                value={dayjs(certificatesForm.expirationDate)}
                                onChange={(value) =>
                                    dispatch(setExpirationDate(value))
                                }
                                sx={{ ...DatePickerStyle }}
                            />
                            <ValidationErrorMessage
                                errors={certificatesForm.errors}
                                field={"expirationDate"}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel shrink htmlFor="certificateUrl">
                                <Typography variant="heading2">
                                    {"اسم الشهادة *"}
                                </Typography>
                            </InputLabel>
                            <InputBase
                                variant="labeled"
                                placeholder="اكتب هنا"
                                id="certificateUrl"
                                onChange={(e) =>
                                    dispatch(setCertificateUrl(e.target.value))
                                }
                                value={certificatesForm.certificateUrl}
                            />
                            <ValidationErrorMessage
                                errors={certificatesForm.errors}
                                field={"certificateUrl"}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            )}
            <Grid item container marginTop={5}>
                <Grid item xs={12}>
                    <XCenter>
                        <Button
                            onClick={() => {
                                if (certificatesForm.closed === true) {
                                    dispatch(openForm());
                                } else {
                                    handleSubmit();
                                }
                            }}
                            sx={{
                                textDecoration: "underline",
                                fontSize: "1.2rem",
                            }}
                        >
                            {certificatesData.current
                                ? "تعديل"
                                : "إضافة شهادة احترافية أخرى"}
                        </Button>
                    </XCenter>
                </Grid>
            </Grid>
        </Box>
    );
};

export default Form;

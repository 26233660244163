import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    createdAt: null,
    deletedAt: null,
    email: null,
    graduationImage: null,
    iconUrl: null,
    id: null,
    idImage: null,
    isVerified: false,
    role: null,
    status: null,
    updatedAt: null,
};

const slice = createSlice({
    name: "profile",
    initialState,
    reducers: {
        setProfile: (state, action) => {
            state.createdAt = action.payload.createdAt;
            state.deletedAt = action.payload.deletedAt;
            state.email = action.payload.email;
            state.graduationImage = action.payload.graduationImage;
            state.iconUrl = action.payload.iconUrl;
            state.id = action.payload.id;
            state.idImage = action.payload.idImage;
            state.isVerified = action.payload.isVerified;
            state.role = action.payload.role;
            state.status = action.payload.status;
            state.updatedAt = action.payload.updatedAt;
        },
        resetProfile: (state) => {
            state.createdAt = null;
            state.deletedAt = null;
            state.email = null;
            state.graduationImage = null;
            state.iconUrl = null;
            state.id = null;
            state.idImage = null;
            state.isVerified = false;
            state.role = null;
            state.status = null;
            state.updatedAt = null;
        },
    },
});

export const { setProfile, resetProfile } = slice.actions;

export default slice.reducer;

import axios from "axios";
import { toast } from "react-toastify";
import { getTokenFromLocalStorage } from "../utils";

import { getStore, getLogout } from "../apiMiddleware";

const API = axios.create({
    baseURL: process.env.REACT_APP_API_BASE,
});

API.interceptors.request.use(
    (config) => {
        const store = getStore();
        if (store) {
            const state = store.getState();
            const token = state.user.accessToken; // Retrieve the token

            if (token) {
                config.headers.Authorization = `Bearer ${token}`; // Add the token to the Authorization header
            }
        }

        return config;
    },
    (error) => {
        toast.error(error.message);
        return Promise.reject(error);
    }
);

API.interceptors.response.use(
    (response) => {
        const result = response.data;
        // if (result.message) toast.success(result.message);
        return result.data ? result.data : result;
    },
    (error) => {
        if (error?.response.status == 401) {
            const store = getStore();
            const logout = getLogout();
            if (store && logout) {
                console.log(401);
                store.dispatch(logout());
            }
        } else {
            const errorMessage =
                error?.response?.data?.message ?? error.message;
            toast.error(errorMessage);
        }
        return Promise.reject(error);
    }
);

export default API;

// src/slices/birthdateSlice.js

import { createSlice } from "@reduxjs/toolkit";
import { BirthdateGenerator } from "../../services/utils";

const initialState = {
    all: null,
};

const listSlice = createSlice({
    name: "list",
    initialState,
    reducers: {
        openList: (state, action) => {
            if (state.all && state.all[action.payload]) {
                state.all[action.payload].opened = true;
            } else {
                state.all = { ...state.all };
                state.all[action.payload] = { opened: true };
            }
        },
        closeList: (state, action) => {
            if (state.all && state.all[action.payload]) {
                state.all[action.payload].opened = false;
            }
        },
    },
});

export const { openList, closeList } = listSlice.actions;

export default listSlice.reducer;

import {
    Box,
    Button,
    Checkbox,
    FormControl,
    Stack,
    Typography,
    useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { REGISTERTATION_TYPES } from "../../../services/constants";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setRole, resetRegister } from "../../../store/slices/register";
import AuthAPI from "../../../services/api/auth";
import { useTheme } from "@emotion/react";

const Form = () => {
    const navigate = useNavigate();

    const dispatch = useDispatch();
    const { accountType, email, role } = useSelector((state) => state.register);

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

    const OnTypeChanged = (type) => {
        dispatch(setRole(type));
    };

    const OnSubmit = async () => {
        try {
            await AuthAPI.register({ email, role });
            dispatch(resetRegister());
            navigate("/auth/login");
        } catch (e) {
            navigate("/auth/signup");
        }
    };

    useEffect(() => {
        if (accountType != "individual") {
            navigate("/");
        }
    }, []);

    return (
        <Box
            component="form"
            noValidate
            width={isSmallScreen ? "100%" : "500px"}
        >
            <Stack gap={2}>
                <Typography variant="heading2">
                    {"قم باختيار نوع التسجيل الذي تقوم به"}
                </Typography>
                <Stack
                    direction={"row"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    sx={{
                        cursor: "pointer",
                        borderRadius: "0.5rem",
                        paddingX: "0.5rem",
                        direction: "ltr",
                        boxShadow: "0px 0px 14px 3px #0000000a;",
                    }}
                    onClick={() =>
                        OnTypeChanged(REGISTERTATION_TYPES.JOB_SEEKER)
                    }
                >
                    <Checkbox
                        size="large"
                        checked={role == REGISTERTATION_TYPES.JOB_SEEKER}
                    />
                    <Typography variant="normal" fontSize={"1.3rem"}>
                        {"باحث عن عمل"}
                    </Typography>
                </Stack>
                <Stack
                    direction={"row"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    sx={{
                        cursor: "pointer",
                        borderRadius: "0.5rem",
                        paddingX: "0.5rem",
                        direction: "ltr",
                        boxShadow: "0px 0px 14px 3px #0000000a;",
                    }}
                    onClick={() => OnTypeChanged(REGISTERTATION_TYPES.TRAINER)}
                >
                    <Checkbox
                        size="large"
                        checked={role == REGISTERTATION_TYPES.TRAINER}
                    />
                    <Typography variant="normal" fontSize={"1.3rem"}>
                        {"مدرب"}
                    </Typography>
                </Stack>
                <Stack
                    direction={"row"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    sx={{
                        cursor: "pointer",
                        borderRadius: "0.5rem",
                        paddingX: "0.5rem",
                        direction: "ltr",
                        boxShadow: "0px 0px 14px 3px #0000000a;",
                    }}
                    onClick={() =>
                        OnTypeChanged(REGISTERTATION_TYPES.FREELANCER)
                    }
                >
                    <Checkbox
                        size="large"
                        checked={role == REGISTERTATION_TYPES.FREELANCER}
                    />
                    <Typography variant="normal" fontSize={"1.3rem"}>
                        {"مستقل"}
                    </Typography>
                </Stack>
                <FormControl variant="outlined" sx={{ width: "100%" }}>
                    <Button variant="main" onClick={() => OnSubmit()}>
                        {"تأكيد الحساب"}
                    </Button>
                </FormControl>
            </Stack>
        </Box>
    );
};

export default Form;

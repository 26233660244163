import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    email: null,
};

const slice = createSlice({
    name: "forgetPassword",
    initialState,
    reducers: {
        setEmail: (state, action) => {
            state.email = action.payload;
        },
    },
});

export const { setEmail } = slice.actions;

export default slice.reducer;

import API from "./Api";

class AuthAPI {
    static async register({ email, role }) {
        const result = await API.post("/auth/register", { email, role });
        return result;
    }

    static async login({ email, password }) {
        const result = await API.post("/auth/login", { email, password });
        return result;
    }

    static async forgetPassword(email) {
        const result = await API.post("/auth/forget-password", { email });
        return result;
    }
}

export default AuthAPI;

import {
    Box,
    Grid,
    IconButton,
    Stack,
    Typography,
    useMediaQuery,
} from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Form from "./Form";
import {
    deleteLanguageExperience,
    fetchAllLanguageExperiences,
    fetchOneLanguageExperience,
} from "../../../../../store/slices/languageExperiencesData";
import { useTheme } from "@emotion/react";
import {
    LanguageLevelLabels,
    LanguageLevelStar,
    PROFILE_MODES,
} from "../../../../../services/constants";
import { Pencil, Translate, Trash } from "@phosphor-icons/react";
import Review from "../../../../common/Review";
import Loader from "../../../../common/Loader";

const Item = ({ id: itemId, language, level }) => {
    const dispatch = useDispatch();

    const { id } = useSelector((state) => state.user);

    const theme = useTheme();
    const isMdScreen = useMediaQuery(theme.breakpoints.down("md"));

    return (
        <Box
            className="block"
            sx={{
                position: "relative",
                boxShadow: "0px 0px 10px 2px #0000000a;",
                padding: "1rem",
            }}
        >
            <Grid container rowSpacing={5} columnSpacing={isMdScreen ? 0 : 10}>
                <Grid item xs={12} sm={6} md={6}>
                    <Stack direction={"row"} gap={1}>
                        <Translate size={28} />
                        <Typography variant="heading2">{language}</Typography>
                    </Stack>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <Stack direction={"row"} gap={1} alignItems={"center"}>
                        <Review
                            starCount={5}
                            level={LanguageLevelStar[level]}
                        />
                        <Typography variant="heading2">
                            {LanguageLevelLabels[level]}
                        </Typography>
                    </Stack>
                </Grid>
            </Grid>
            <Box
                sx={{
                    position: "absolute",
                    left: 0,
                    top: "50%",
                    transform: "translateY(-50%)",
                }}
            >
                <Stack direction="row" spacing={1}>
                    <IconButton
                        onClick={() =>
                            dispatch(
                                deleteLanguageExperience({
                                    userId: id,
                                    id: itemId,
                                })
                            )
                        }
                    >
                        <Trash color="red" />
                    </IconButton>
                    <IconButton
                        onClick={() =>
                            dispatch(
                                fetchOneLanguageExperience({
                                    userId: id,
                                    id: itemId,
                                })
                            )
                        }
                    >
                        <Pencil style={{ color: theme.palette.primary.main }} />
                    </IconButton>
                </Stack>
            </Box>
        </Box>
    );
};

const List = () => {
    const dispatch = useDispatch();

    const { id } = useSelector((state) => state.user);
    const { mode } = useSelector((state) => state.personalInfo);
    const { all } = useSelector((state) => state.languageExperiencesData);
    const { allLanguageExperiences } = useSelector(
        (state) => state.languageExperiencesData.loaders
    );

    useEffect(() => {
        dispatch(fetchAllLanguageExperiences({ userId: id }));
    }, [dispatch]);
    return (
        <Box className="block">
            <Stack gap={2}>
                {mode == PROFILE_MODES.UPDATE && (
                    <>
                        <Typography variant="heading2">{"اللغات"}</Typography>
                        <Loader
                            loading={allLanguageExperiences.loading}
                            error={allLanguageExperiences.error}
                            height={200}
                        >
                            {all &&
                                all.map((item) => (
                                    <Box key={item.id}>
                                        <Item
                                            id={item.id}
                                            language={item.language.nameAr}
                                            level={item.languageLevel}
                                        />
                                    </Box>
                                ))}
                        </Loader>
                    </>
                )}
                <Form />
            </Stack>
        </Box>
    );
};

export default List;

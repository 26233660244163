import {
    FormControl,
    Grid,
    IconButton,
    InputBase,
    InputLabel,
    List,
    ListItem,
    Typography,
    useTheme,
    Link as MuiLink,
    Button,
} from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    addLink,
    removeLink,
    setErrors,
    setLink,
} from "../../../../store/slices/servicesForm";
import { Trash } from "@phosphor-icons/react";
import { Link } from "react-router-dom";
import XCenter from "../../../common/XCenter";
import ValidationErrorMessage from "../../../common/ValidationErrorMessage";

const Links = () => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const servicesForm = useSelector((state) => state.servicesForm);

    return (
        <Grid item container justifyContent={"center"}>
            <Grid item xs={12}>
                <FormControl variant="outlined" fullWidth>
                    <InputLabel shrink htmlFor="link">
                        <Typography variant="heading2">{"الروابط"}</Typography>
                    </InputLabel>
                    <InputBase
                        variant="labeled"
                        placeholder="أضف هنا"
                        id="links"
                        onChange={(e) => dispatch(setLink(e.target.value))}
                        value={servicesForm.link}
                    />
                    <ValidationErrorMessage
                        errors={servicesForm.errors}
                        field={"link"}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <XCenter>
                    <Button
                        component="label"
                        sx={{
                            textDecoration: "underline",
                            fontSize: "1.2rem",
                        }}
                        onClick={() => {
                            if (
                                servicesForm.link == null ||
                                servicesForm.link == undefined ||
                                servicesForm.link.trim() == ""
                            ) {
                                dispatch(
                                    setErrors({
                                        ...servicesForm.errors,
                                        link: "املا الحقل",
                                    })
                                );
                            } else {
                                dispatch(setLink(null));
                                dispatch(addLink(servicesForm.link));
                                dispatch(
                                    setErrors({
                                        ...servicesForm.errors,
                                        link: null,
                                    })
                                );
                            }
                        }}
                    >
                        {"إضافة رابط آخر"}
                    </Button>
                </XCenter>
            </Grid>
            <Grid item xs={8}>
                <List>
                    {servicesForm.links.map((link, index) => (
                        <ListItem
                            secondaryAction={
                                <IconButton
                                    edge="end"
                                    onClick={() => dispatch(removeLink(index))}
                                >
                                    <Trash color="red" />
                                </IconButton>
                            }
                        >
                            <MuiLink component={Link} to={link} target="_blank">
                                {link}
                            </MuiLink>
                        </ListItem>
                    ))}
                </List>
            </Grid>
        </Grid>
    );
};

export default Links;

import {
    Autocomplete,
    Box,
    Button,
    FormControl,
    Grid,
    InputBase,
    InputLabel,
    TextField,
    Typography,
    useMediaQuery,
} from "@mui/material";
import React from "react";
import ValidationErrorMessage from "../../../common/ValidationErrorMessage";
import { useDispatch } from "react-redux";
import { useTheme } from "@emotion/react";
import { setNext, setPrev } from "../../../../store/slices/stepper";
import useForm from "./useForm";
import {
    setLinkedin,
    setPhoneNumber,
    setSelectedCity,
    setSelectedCountry,
    setSelectedCountryCode,
} from "../../../../store/slices/contactInfo";
import Loader from "../../../common/Loader";

const Form = () => {
    const dispatch = useDispatch();

    const [contactInfo, handleSubmit] = useForm();

    const theme = useTheme();
    const isMdScreen = useMediaQuery(theme.breakpoints.down("md"));

    return (
        <Box component={"form"}>
            <Grid
                container
                direction={"row"}
                justifyContent={"center"}
                rowSpacing={5}
                columnSpacing={isMdScreen ? 0 : 10}
            >
                <Grid item xs={12} sm={12} md={6}>
                    <FormControl variant="outlined" fullWidth>
                        <InputLabel shrink htmlFor="country">
                            <Typography variant="heading2">
                                {"بلد الاقامة *"}
                            </Typography>
                        </InputLabel>
                        <Autocomplete
                            variant="labeled"
                            id="country"
                            value={contactInfo.selectedCountry}
                            options={contactInfo.countries}
                            getOptionLabel={(option) =>
                                option ? option.nameAr : ""
                            }
                            isOptionEqualToValue={(option, value) =>
                                option.id === value.id
                            }
                            onChange={(e, option) =>
                                dispatch(setSelectedCountry(option))
                            }
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    placeholder="اختر من هنا"
                                />
                            )}
                        />
                        <ValidationErrorMessage
                            errors={null}
                            field={"country"}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    <FormControl variant="outlined" fullWidth>
                        <InputLabel shrink htmlFor="city">
                            <Typography variant="heading2">
                                {"مدينة الاقامة *"}
                            </Typography>
                        </InputLabel>
                        <Autocomplete
                            variant="labeled"
                            id="city"
                            value={contactInfo.selectedCity}
                            options={contactInfo.cities}
                            getOptionLabel={(option) =>
                                option ? option.nameAr : ""
                            }
                            isOptionEqualToValue={(option, value) =>
                                option.id === value.id
                            }
                            onChange={(e, option) =>
                                dispatch(setSelectedCity(option))
                            }
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    placeholder="اختر من هنا"
                                />
                            )}
                        />
                        <ValidationErrorMessage errors={null} field={"city"} />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                    <Loader
                        loading={contactInfo.loaders.countries.loading}
                        error={contactInfo.loaders.countries.error}
                    >
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel shrink htmlFor="phone">
                                <Typography variant="heading2">
                                    {"رقم الهاتف *"}
                                </Typography>
                            </InputLabel>
                            <Grid item container marginTop={4}>
                                <Grid xs={1.25}>
                                    <Autocomplete
                                        variant="labeled"
                                        id="phonecode"
                                        value={contactInfo.selectedCountryCode}
                                        options={contactInfo.countries.map(
                                            (item) => item.phoneCode + ""
                                        )}
                                        getOptionLabel={(option) =>
                                            option ? "+" + option : ""
                                        }
                                        onChange={(e, option) =>
                                            dispatch(
                                                setSelectedCountryCode(option)
                                            )
                                        }
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                placeholder="اختر من هنا"
                                            />
                                        )}
                                        sx={{
                                            "& .MuiInputBase-root": {
                                                paddingRight: "10px !important",
                                            },
                                        }}
                                    />
                                </Grid>
                                <Grid xs={10.75}>
                                    <InputBase
                                        variant="labeled"
                                        id="phone"
                                        fullWidth
                                        sx={{
                                            paddingY: "6px",
                                        }}
                                        value={contactInfo.phoneNumber}
                                        onChange={(e) =>
                                            dispatch(
                                                setPhoneNumber(e.target.value)
                                            )
                                        }
                                        placeholder="اكتب هنا"
                                    />
                                </Grid>
                            </Grid>

                            <ValidationErrorMessage
                                errors={null}
                                field={"city"}
                            />
                        </FormControl>
                    </Loader>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                    <FormControl variant="outlined" fullWidth>
                        <InputLabel shrink htmlFor="linkedin">
                            <Typography variant="heading2">
                                {"رابط لينكد ان"}
                            </Typography>
                        </InputLabel>
                        <InputBase
                            variant="labeled"
                            id="linkedin"
                            value={contactInfo.linkedin}
                            onChange={(e) =>
                                dispatch(setLinkedin(e.target.value))
                            }
                            placeholder="اكتب هنا"
                        />
                        <ValidationErrorMessage
                            errors={null}
                            field={"linkedin"}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <Grid container justifyContent={"space-between"}>
                        <Grid item xs={3}>
                            <Button
                                fullWidth
                                variant="outlined"
                                sx={{ paddingY: 2 }}
                                onClick={() => {
                                    dispatch(setPrev());
                                }}
                            >
                                {"السابق"}
                            </Button>
                        </Grid>
                        <Grid item xs={3}>
                            <Button
                                fullWidth
                                variant="main"
                                sx={{ paddingY: 2 }}
                                onClick={() => {
                                    handleSubmit();
                                    dispatch(setNext());
                                }}
                            >
                                {"التالي"}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
};

export default Form;

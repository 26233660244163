import { useTheme } from "@emotion/react";
import {
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    Grid,
    Typography,
    useMediaQuery,
    Link as MuiLink,
} from "@mui/material";
import { Box, Stack, styled } from "@mui/system";
import React, { useEffect } from "react";
import XCenter from "../../components/common/XCenter";
import IDImage from "../../assets/img/id.png";
import GraduateImage from "../../assets/img/garduate.png";
import ValidationErrorMessage from "../../components/common/ValidationErrorMessage";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
    setAgreed,
    uploadGraduationImage,
    uploadIdImage,
} from "../../store/slices/verifyAccountForm";
import { Link } from "react-router-dom";
import { getFileName } from "../../services/utils";
import {
    uploadProfileGraduationImage,
    uploadProfileIDImage,
} from "../../store/slices/user";

const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
});

const VerifyAccount = () => {
    const dispatch = useDispatch();

    const theme = useTheme();
    const isMdScreen = useMediaQuery(theme.breakpoints.down("md"));

    const { graduationImage, idImage } = useSelector((state) => state.user);
    const {
        graduationImage: graduationImageTmp,
        idImage: idImageTmp,
        agreed,
    } = useSelector((state) => state.verifyAccountForm);

    useEffect(() => {
        if (graduationImage && idImage) {
            toast.info("لقد قمت برفع ملفاتك");
        }
    }, [graduationImage, idImage]);

    const handleSubmit = () => {
        if (!agreed) {
            toast.error("الرجاء الموافقة على سياستنا");
            return;
        }

        if (graduationImage && idImage) {
            toast.info("لقد قمت برفع ملفاتك");
            return;
        }

        if (!graduationImageTmp || !idImageTmp) {
            toast.error("الرجاء اضافة الملفين");
            return;
        }
        
        dispatch(uploadProfileIDImage({ url: idImageTmp }));
        dispatch(uploadProfileGraduationImage({ url: graduationImageTmp }));
    };

    return (
        <Box className="page">
            <Grid container justifyContent={"center"}>
                <Grid
                    container
                    item
                    direction={"row"}
                    rowSpacing={5}
                    columnSpacing={isMdScreen ? 0 : 10}
                    md={8}
                    sm={12}
                    xs={12}
                >
                    <Grid item xs={12}>
                        <XCenter>
                            <Typography variant="heading1">
                                {"تأكيد الحساب"}
                            </Typography>
                        </XCenter>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        {idImage && (
                            <MuiLink
                                component={Link}
                                to={idImage}
                                target="_blank"
                            >
                                {`ملف البطاقة الشخصية`}
                            </MuiLink>
                        )}
                        <Button
                            component={"label"}
                            role={undefined}
                            variant="dashed"
                            tabIndex={-1}
                            sx={{
                                marginTop: 4,
                                paddingY: 4,
                            }}
                            fullWidth
                        >
                            <Stack alignItems={"center"}>
                                <Box
                                    component={"img"}
                                    src={IDImage}
                                    alt="نادي الخريجين"
                                    sx={{
                                        objectFit: "contain",
                                        objectPosition: "center",
                                        height: "200px",
                                    }}
                                />
                                <Typography>{"اضغط هنا للتحميل"}</Typography>
                                <VisuallyHiddenInput
                                    type="file"
                                    id="idImage"
                                    onChange={(e) => {
                                        console.log(e.target.files[0]);
                                        dispatch(
                                            uploadIdImage({
                                                file: e.target.files[0],
                                            })
                                        );
                                    }}
                                />
                            </Stack>
                        </Button>
                        <Typography
                            component={"p"}
                            variant="heading2"
                            textAlign={"center"}
                            marginTop={4}
                            fontWeight={"400"}
                        >
                            {
                                "قم بتحويل صورة ملف يحوي على صورة هويتك الشخصية (الوجه الامامي والخلفي)"
                            }
                        </Typography>
                        {idImageTmp && (
                            <MuiLink
                                component={Link}
                                to={idImageTmp}
                                target="_blank"
                            >
                                {getFileName(idImageTmp)}
                            </MuiLink>
                        )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        {graduationImage && (
                            <MuiLink
                                component={Link}
                                to={graduationImage}
                                target="_blank"
                            >
                                {`ملف التخرج`}
                            </MuiLink>
                        )}
                        <Button
                            component={"label"}
                            role={undefined}
                            variant="dashed"
                            tabIndex={-1}
                            sx={{
                                marginTop: 4,
                                paddingY: 4,
                            }}
                            fullWidth
                        >
                            <Stack alignItems={"center"}>
                                <Box
                                    component={"img"}
                                    src={GraduateImage}
                                    alt="نادي الخريجين"
                                    sx={{
                                        objectFit: "contain",
                                        objectPosition: "center",
                                        height: "200px",
                                    }}
                                />
                                <Typography>{"اضغط هنا للتحميل"}</Typography>
                                <VisuallyHiddenInput
                                    type="file"
                                    id="graduationImage"
                                    onChange={(e) => {
                                        console.log(e.target.files[0]);
                                        dispatch(
                                            uploadGraduationImage({
                                                file: e.target.files[0],
                                            })
                                        );
                                    }}
                                />
                            </Stack>
                        </Button>
                        <Typography
                            component={"p"}
                            variant="heading2"
                            textAlign={"center"}
                            marginTop={4}
                            fontWeight={"400"}
                        >
                            {"قم بتحميل ملف يحوي على صورة شهادة لتخرجك"}
                        </Typography>
                        {graduationImageTmp && (
                            <MuiLink
                                component={Link}
                                to={graduationImageTmp}
                                target="_blank"
                            >
                                {getFileName(graduationImageTmp)}
                            </MuiLink>
                        )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <FormControl variant="outlined" fullWidth>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        value={agreed}
                                        checked={agreed}
                                        onChange={(e) =>
                                            dispatch(
                                                setAgreed(e.target.checked)
                                            )
                                        }
                                    />
                                }
                                label="أنا أوافق على سياسة الخصوصية وشروط الاستخدام الخاصة بالمنصة"
                                sx={{
                                    "& .MuiTypography-root": {
                                        color: theme.palette.primary.main,
                                        fontSize: "1.25rem",
                                        fontWeight: "500",
                                    },
                                }}
                            />
                            <ValidationErrorMessage
                                errors={null}
                                field={"current"}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <Grid container justifyContent={"center"}>
                            <Grid item xs={12} sm={12} md={6}>
                                <Button
                                    fullWidth
                                    variant="main"
                                    sx={{ paddingY: 2 }}
                                    onClick={() => handleSubmit()}
                                >
                                    {"تاكيد"}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
};

export default VerifyAccount;

import React, { useState } from "react";

import MenuLink from "./MenuLink";
import { Popper, Stack } from "@mui/material";
import { CaretDown, CaretUp } from "@phosphor-icons/react";

const CMenu = ({ parent, items = [], onItemClick, children, image }) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const open = Boolean(anchorEl);
    const id = open ? "menu" : undefined;

    return (
        <>
            <MenuLink
                title={parent}
                onClick={handleClick}
                endIcon={
                    open ? (
                        <CaretUp size={12} weight="fill" />
                    ) : (
                        <CaretDown size={12} weight="fill" />
                    )
                }
                image={image}
            />
            <Popper id={id} open={open} anchorEl={anchorEl}>
                <Stack
                    sx={{
                        backgroundColor: "#fff",
                        boxShadow: "0px 0px 10px 2px #0000000a;",
                        borderRadius: 2,
                    }}
                >
                    {items.map((item) => (
                        <MenuLink
                            key={item.title}
                            route={item.route}
                            title={item.title}
                            onClick={() => {
                                onItemClick && onItemClick(item);
                                setAnchorEl(null);
                            }}
                        />
                    ))}
                    {children}
                </Stack>
            </Popper>
        </>
    );
};

export default CMenu;

import API from "./Api";

export default class ReferencesAPI {
    static async getAll() {
        const result = await API.get(`/the-reviewer`);
        return result;
    }

    static async getOne(id) {
        const result = await API.get(`/the-reviewer/${id}`);
        return result;
    }

    static async create(data) {
        const result = await API.post(`/the-reviewer`, data);
        return result;
    }

    static async update(id, data) {
        const result = await API.put(`/the-reviewer/${id}`, data);
        return result;
    }

    static async delete(id) {
        const result = await API.delete(`/the-reviewer/${id}`);
        return result;
    }
}

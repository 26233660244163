import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

const NotVerifiedAccount = ({ children }) => {
    const { accessToken, isVerified } = useSelector((state) => state.user);

    if (!accessToken) {
        return <Navigate to="/auth/login" />;
    } else {
        if (isVerified) {
            return <Navigate to="/" />;
        } else {
            return <>{children}</>;
        }
    }
};

export default NotVerifiedAccount;

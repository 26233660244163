import { Box, Stack, Typography, Link as MuiLink } from "@mui/material";
import React from "react";
import Form from "../../components/pages/login/Form";
import { Link } from "react-router-dom";
import XCenter from "../../components/common/XCenter";

const Login = () => {
    return (
        <Box
            className="page"
            sx={{
                paddingBottom: "3rem",
            }}
        >
            <Stack gap={4} sx={{ "& > *": { width: "100%" } }}>
                <Box>
                    <XCenter>
                        <Typography variant={"heading1"}>
                            {"تسجيل الدخول"}
                        </Typography>
                    </XCenter>
                </Box>
                <Box>
                    <XCenter>
                        <Form />
                    </XCenter>
                </Box>
                <Box>
                    <XCenter>
                        <Typography component={"p"} variant="normal">
                            <Typography component={"span"}>
                                {"ليس لديك حساب؟ "}
                            </Typography>
                            <Typography component={"span"}>
                                <MuiLink component={Link} to="/auth/signup">
                                    {"قم بإنشاء حساب"}
                                </MuiLink>
                            </Typography>
                        </Typography>
                    </XCenter>
                </Box>
            </Stack>
        </Box>
    );
};

export default Login;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import UserAPI from "../../services/api/User";
import { fetchUser } from "./user";

const initialState = {
    profile_image: null,
    id_image: null,
    graduation_image: null,
    loaders: {
        profile_image: {
            loading: false,
            error: null,
        },
        id_image: {
            loading: false,
            error: null,
        },
        graduation_image: {
            loading: false,
            error: null,
        },
    },
};

export const uploadProfileImage = createAsyncThunk(
    "user/uploadProfileImage",
    async ({ file }, { dispatch, getState }) => {
        const result = await UserAPI.uploadImage(file, "PROFILE_ICON");
        const userId = getState().user.id;
        dispatch(fetchUser({ id: userId }));
        return result;
    }
);

const slice = createSlice({
    name: "images",
    initialState,
    reducers: {
        setProfileImage: (state, action) => {
            state.profile_image = action.payload;
        },
        setIdImage: (state, action) => {
            state.id_image = action.payload;
        },
        setGraduationImage: (state, action) => {
            state.graduation_image = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(uploadProfileImage.pending, (state) => {
                state.loaders.profile_image.loading = true;
                state.loaders.profile_image.error = null;
            })
            .addCase(uploadProfileImage.fulfilled, (state, action) => {
                state.loaders.profile_image.loading = false;
            })
            .addCase(uploadProfileImage.rejected, (state, action) => {
                state.loaders.profile_image.loading = true;
                state.loaders.profile_image.error = action.message;
            });
    },
});

export const {
    setProfileImage,
    setIdImage,
    setGraduationImage,
    setProfileImageFile,
    setIdImageFile,
    setGraduationImageFile,
} = slice.actions;

export default slice.reducer;

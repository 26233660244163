import { useTheme } from "@emotion/react";
import { Grid, useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect } from "react";
import { default as ProfileNavigator } from "../../components/pages/individual/profile/Navigator";
import { Outlet } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setMode } from "../../store/slices/personalInfo";
import { PROFILE_MODES } from "../../services/constants";

const Profile = () => {
    const dispatch = useDispatch();

    const theme = useTheme();
    const isMdScreen = useMediaQuery(theme.breakpoints.down("md"));

    useEffect(() => {
        dispatch(setMode(PROFILE_MODES.UPDATE));
    }, []);

    return (
        <Box className="page">
            <Grid container>
                <Grid md={3} sm={12} xs={12}>
                    <ProfileNavigator />
                </Grid>
                <Grid md={9} sm={12} xs={12}>
                    <Outlet />
                </Grid>
            </Grid>
        </Box>
    );
};

export default Profile;

// src/slices/birthdateSlice.js

import { createSlice } from "@reduxjs/toolkit";
import { BirthdateGenerator } from "../../services/utils";

const initialState = {
    year: "",
    month: "",
    day: "",
    availableYears: BirthdateGenerator.generateYears(),
    availableMonths: BirthdateGenerator.generateMonths(),
    availableDays: [],
};

const birthdateSlice = createSlice({
    name: "birthdate",
    initialState,
    reducers: {
        setYear: (state, action) => {
            state.year = action.payload;

            const maxDays = BirthdateGenerator.getMaxDays(
                state.year,
                state.month
            );
            if (state.day > maxDays) {
                state.day = "";
            }
        },
        setMonth: (state, action) => {
            state.month = action.payload;

            const maxDays = BirthdateGenerator.getMaxDays(
                state.year,
                state.month
            );
            if (state.day > maxDays) {
                state.day = "";
            }

            const days = BirthdateGenerator.generateDays(
                state.year,
                state.month
            );

            setDay(days);
        },
        setDay: (state, action) => {
            state.day = action.payload;
        },
    },
});

export const { setYear, setMonth, setDay } = birthdateSlice.actions;

export default birthdateSlice.reducer;

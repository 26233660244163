import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setErrors } from "../../../../store/slices/personalInfo";
// import validationSchema from "./validationSchema";
import {
    createContactInfo,
    fetchAllCities,
    fetchAllCountries,
    fetchContactInfo,
    setCities,
    updateContactInfo,
} from "../../../../store/slices/contactInfo";

const useForm = () => {
    const dispatch = useDispatch();
    const contactInfo = useSelector((state) => state.contactInfo);
    const userId = useSelector((state) => state.user.id);

    useEffect(() => {
        dispatch(fetchAllCountries());
        dispatch(fetchContactInfo({ userId }));
    }, [dispatch, userId]);

    useEffect(() => {
        if (contactInfo.selectedCountry) {
            dispatch(setCities(contactInfo.selectedCountry.cities));
        }
    }, [dispatch, contactInfo.selectedCountry]);

    const handleSubmit = async () => {
        // try {
        //     await validationSchema.validate(
        //         {
        //             firstNameAr: contactInfo.firstNameAr,
        //             lastNameAr: contactInfo.lastNameAr,
        //             firstNameEn: contactInfo.firstNameEn,
        //             lastNameEn: contactInfo.lastNameEn,
        //             year: contactInfo.year,
        //             month: contactInfo.month,
        //             day: contactInfo.day,
        //             gender: contactInfo.gender,
        //             nationality1: contactInfo.nationality1,
        //         },
        //         { abortEarly: false }
        //     );
        // } catch (error) {
        //     const errors = collectAllValidationErrors(error);
        //     dispatch(setErrors(errors));
        //     window.scrollTo(0, 0);
        //     toast.warn("اصلح الحقول المطلوبة");
        //     return;
        // }

        const data = {
            countryId: contactInfo.selectedCountry.id,
            cityId: contactInfo.selectedCity.id,
            countryCode: "" + contactInfo.selectedCountryCode,
            phoneNumber: contactInfo.phoneNumber,
            websiteUrl: contactInfo.personalWebsite,
            linkedInUrl: contactInfo.linkedin,
        };

        if (contactInfo.id) {
            dispatch(
                updateContactInfo({
                    userId,
                    id: contactInfo.id,
                    data,
                })
            );
        } else {
            dispatch(
                createContactInfo({
                    userId,
                    data,
                })
            );
        }

        dispatch(setErrors(null));
    };

    return [contactInfo, handleSubmit];
};

export default useForm;

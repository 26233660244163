import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import ReferencesAPI from "../../services/api/References";
import { closeForm, openForm, resetAll, setAll } from "./referencesForm";
import { CountryAPI } from "../../services/api/model";

const initialState = {
    all: [],
    current: null,
    loaders: {
        all: {
            loading: false,
            error: null,
        },
        current: {
            loading: false,
            error: null,
        },
    },
};

export const fetchAllReferences = createAsyncThunk(
    "references/fetchAllReferences",
    async () => {
        const result = await ReferencesAPI.getAll();
        return result;
    }
);

export const fetchOneReference = createAsyncThunk(
    "references/fetchOneReference",
    async ({ id }, { dispatch }) => {
        const result = await ReferencesAPI.getOne(id);
        const countries = await CountryAPI.getAll();
        const tmp = countries.find(
            (item) =>
                item.phoneCode == parseInt(result.countryCode.substring(1))
        );
        dispatch(
            setAll({
                name: result.name,
                relationship: result.relationship,
                selectedJobPositionLevel: result.jobPosition,
                email: result.email,
                selectedCountryCode: tmp,
                phoneNumber: result.phoneNumber,
            })
        );
        dispatch(openForm());
        return result;
    }
);

export const createReference = createAsyncThunk(
    "references/createReference",
    async ({ data }, { dispatch }) => {
        const result = await ReferencesAPI.create(data);
        dispatch(fetchAllReferences());
        dispatch(resetAll());
        dispatch(closeForm());
        return result;
    }
);

export const updateReference = createAsyncThunk(
    "references/updateReference",
    async ({ id, data }, { dispatch }) => {
        const result = await ReferencesAPI.update(id, data);
        dispatch(fetchAllReferences());
        dispatch(resetAll());
        dispatch(closeForm());
        return result;
    }
);

export const deleteReference = createAsyncThunk(
    "references/deleteReference",
    async ({ id }, { dispatch }) => {
        const result = await ReferencesAPI.delete(id);
        dispatch(fetchAllReferences());
        dispatch(resetAll());
        return result;
    }
);

const slice = createSlice({
    name: "referencesData",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllReferences.pending, (state) => {
                state.loaders.all.loading = true;
                state.loaders.all.error = null;
            })
            .addCase(fetchAllReferences.fulfilled, (state, action) => {
                state.loaders.all.loading = false;
                state.all = action.payload;
            })
            .addCase(fetchAllReferences.rejected, (state, action) => {
                state.loaders.all.loading = true;
                state.loaders.all.error = action.message;
            })
            .addCase(fetchOneReference.pending, (state) => {
                state.loaders.current.loading = true;
                state.loaders.current.error = null;
            })
            .addCase(fetchOneReference.fulfilled, (state, action) => {
                state.loaders.current.loading = false;
                state.current = action.payload;
            })
            .addCase(fetchOneReference.rejected, (state, action) => {
                state.loaders.current.loading = true;
                state.loaders.current.error = action.message;
            })
            .addCase(updateReference.fulfilled, (state, action) => {
                state.current = null;
            })
            .addCase(deleteReference.fulfilled, (state, action) => {
                state.current = null;
            });
    },
});

export const {} = slice.actions;

export default slice.reducer;

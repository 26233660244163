import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import JobPositionLevelAPI from "../../services/api/JobPositionLevel";
import { CountryAPI } from "../../services/api/model";

const initialState = {
    name: null,
    relationship: null,
    email: null,
    phone: null,
    closed: true,
    errors: null,
    selectedJobPositionLevel: null,
    jobPositionLevels: [],
    countries: [],
    selectedCountryCode: null,
    phoneNumber: null,
    loaders: {
        jobPositionLevels: {
            loading: false,
            error: null,
        },
        countries: {
            loading: false,
            error: null,
        },
    },
};

export const fetchAllJobPositionLevels = createAsyncThunk(
    "references/fetchAllJobPositionLevels",
    async () => {
        const result = await JobPositionLevelAPI.getAll();
        return result;
    }
);

export const fetchAllCountries = createAsyncThunk(
    "contactInfo/fetchAllCountries",
    async () => {
        const result = await CountryAPI.getAll();
        return result;
    }
);

const slice = createSlice({
    name: "referencesForm",
    initialState,
    reducers: {
        setName(state, action) {
            state.name = action.payload;
        },
        setRelationship(state, action) {
            state.relationship = action.payload;
        },
        setSelectedJobPositionLevel(state, action) {
            state.selectedJobPositionLevel = action.payload;
        },
        setEmail(state, action) {
            state.email = action.payload;
        },
        setSelectedCountryCode: (state, action) => {
            state.selectedCountryCode = action.payload;
        },
        setPhoneNumber: (state, action) => {
            state.phoneNumber = action.payload;
        },
        setErrors(state, action) {
            state.errors = action.payload;
        },
        openForm: (state) => {
            state.closed = false;
        },
        closeForm: (state) => {
            state.closed = true;
        },
        setAll(state, action) {
            state.name = action.payload.name;
            state.relationship = action.payload.relationship;
            state.selectedJobPositionLevel =
                action.payload.selectedJobPositionLevel;
            state.email = action.payload.email;
            state.selectedCountryCode = action.payload.selectedCountryCode;
            state.phoneNumber = action.payload.phoneNumber;
        },
        resetAll() {
            return initialState;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllJobPositionLevels.pending, (state) => {
                state.loaders.jobPositionLevels.loading = true;
                state.loaders.jobPositionLevels.error = null;
            })
            .addCase(fetchAllJobPositionLevels.fulfilled, (state, action) => {
                state.loaders.jobPositionLevels.loading = false;
                state.jobPositionLevels = action.payload;
            })
            .addCase(fetchAllJobPositionLevels.rejected, (state, action) => {
                state.loaders.jobPositionLevels.loading = true;
                state.loaders.jobPositionLevels.error = action.message;
            })
            .addCase(fetchAllCountries.pending, (state) => {
                state.loaders.countries.loading = true;
                state.loaders.countries.error = null;
            })
            .addCase(fetchAllCountries.fulfilled, (state, action) => {
                state.loaders.countries.loading = false;
                state.countries = action.payload;
            })
            .addCase(fetchAllCountries.rejected, (state, action) => {
                state.loaders.countries.loading = true;
                state.loaders.countries.error = action.message;
            });
    },
});

export const {
    setName,
    setRelationship,
    setSelectedJobPositionLevel,
    setEmail,
    setSelectedCountryCode,
    setPhoneNumber,
    setErrors,
    openForm,
    closeForm,
    setAll,
    resetAll,
} = slice.actions;

export default slice.reducer;

import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import Form from "../../components/pages/forget-password/Form";
import XCenter from "../../components/common/XCenter";

const ResetPassword = () => {
    return (
        <Box
            className="page"
            sx={{
                paddingBottom: "3rem",
            }}
        >
            <Stack
                gap={4}
                alignItems={"center"}
                sx={{ "& > *": { width: "100%" } }}
            >
                <Box>
                    <XCenter>
                        <Typography variant={"heading1"} re>
                            {"إعادة تعيين كلمة المرور"}
                        </Typography>
                    </XCenter>
                </Box>
                <Box>
                    <XCenter>
                        <Form />
                    </XCenter>
                </Box>
            </Stack>
        </Box>
    );
};

export default ResetPassword;

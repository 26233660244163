import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    createCoaching,
    fetchAllCoaching,
    fetchOneCoaching,
    updateCoaching,
} from "../../../../store/slices/coachingData";
import {
    fetchAllWorkFields,
    setErrors,
} from "../../../../store/slices/coachingForm";
import { PROFILE_MODES } from "../../../../services/constants";

const useForm = () => {
    const dispatch = useDispatch();

    const { mode } = useSelector((state) => state.personalInfo);
    const coachingForm = useSelector((state) => state.coachingForm);
    const coachingData = useSelector((state) => state.coachingData);

    useEffect(() => {
        dispatch(fetchAllCoaching());
        dispatch(fetchAllWorkFields());
    }, []);

    useEffect(() => {
        if (mode == PROFILE_MODES.REGISTER && coachingData.all.length > 0) {
            const id = coachingData.all[0].id;
            dispatch(fetchOneCoaching({ id }));
        }
    }, [coachingData.all]);

    const handleSubmit = async () => {
        // try {
        //     await validationSchema.validate(
        //         {
        //             spec: educationLevelForm.spec,
        //             university: educationLevelForm.university,
        //             level: educationLevelForm.level,
        //             graduated: educationLevelForm.graduated,
        //             gradDate: educationLevelForm.gradDate,
        //         },
        //         { abortEarly: false }
        //     );
        // } catch (error) {
        //     const errors = collectAllValidationErrors(error);
        //     dispatch(setErrors(errors));
        //     window.scrollTo(0, 0);
        //     toast.warn("اصلح الحقول المطلوبة");
        //     return;
        // }

        const data = {
            experienceType: coachingForm.selectedExperienceType.value,
            workFieldId: coachingForm.selectedWorkField.id,
            customerType: coachingForm.customerTypes
                .filter((item) => item.checked)
                .map((item) => item.value),
            serviceDeliveryType: coachingForm.serviceDeliveryTypes
                .filter((item) => item.checked)
                .map((item) => item.value),
            customerNumber: coachingForm.customerNumber,
            averageSalaryPerHour: coachingForm.averageSalaryPerHour,
            availabilityToTravel: coachingForm.availabilityToTravel,
            providedHours: coachingForm.providedHours,
            recordedExerciseLink: coachingForm.recordedExerciseLink,
            contractedEntities: coachingForm.contractedEntities,
            files: coachingForm.files,
            links: coachingForm.links,
        };

        if (coachingData.current) {
            dispatch(
                updateCoaching({
                    id: coachingData.current.id,
                    data,
                })
            );
        } else {
            dispatch(
                createCoaching({
                    data,
                })
            );
        }

        dispatch(setErrors(null));
        window.scrollTo(0, 0);
    };

    return [coachingForm, coachingData, handleSubmit];
};

export default useForm;

import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import Form from "../../../components/pages/registeration-type/Form";
import XCenter from "../../../components/common/XCenter";

const RegistrationType = () => {
    return (
        <Box
            className="page"
            sx={{
                paddingBottom: "3rem",
            }}
        >
            <Stack
                alignItems={"center"}
                gap={4}
                sx={{ "& > *": { width: "100%" } }}
            >
                <Box>
                    <XCenter>
                        <Typography variant={"heading1"}>
                            {"نوع التسجيل"}
                        </Typography>
                    </XCenter>
                </Box>
                <Box>
                    <XCenter>
                        <Form />
                    </XCenter>
                </Box>
            </Stack>
        </Box>
    );
};

export default RegistrationType;

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    createPersonalInfo,
    fetchPersonalInfo,
    setDay,
    setFirstNameAr,
    setFirstNameEn,
    setGender,
    setLastNameAr,
    setLastNameEn,
    setMonth,
    setYear,
    updatePersonalInfo,
    setErrors,
    fetchAllNationalities,
    setNationality1,
    setNationality2,
} from "../../../../store/slices/personalInfo";
import {
    collectAllValidationErrors,
    createDateFromComponents,
} from "../../../../services/utils";
import validationSchema from "./validationSchema";
import { toast } from "react-toastify";

const useForm = () => {
    const dispatch = useDispatch();
    const personalInfo = useSelector((state) => state.personalInfo);
    const userId = useSelector((state) => state.user.id);

    useEffect(() => {
        dispatch(fetchAllNationalities());
        dispatch(fetchPersonalInfo({ userId }));
    }, [dispatch, userId]);

    const handleSubmit = async () => {
        try {
            await validationSchema.validate(
                {
                    firstNameAr: personalInfo.firstNameAr,
                    lastNameAr: personalInfo.lastNameAr,
                    firstNameEn: personalInfo.firstNameEn,
                    lastNameEn: personalInfo.lastNameEn,
                    year: personalInfo.year,
                    month: personalInfo.month,
                    day: personalInfo.day,
                    gender: personalInfo.gender,
                    nationality1: personalInfo.nationality1,
                },
                { abortEarly: false }
            );
        } catch (error) {
            const errors = collectAllValidationErrors(error);
            dispatch(setErrors(errors));
            window.scrollTo(0, 0);
            toast.warn("اصلح الحقول المطلوبة");
            return;
        }

        const birthday = createDateFromComponents(
            personalInfo.year.value,
            personalInfo.month.value,
            personalInfo.day.value
        );

        const data = {
            firstNameAr: personalInfo.firstNameAr,
            lastNameAr: personalInfo.lastNameAr,
            firstNameEn: personalInfo.firstNameEn,
            lastNameEn: personalInfo.lastNameEn,
            gender: personalInfo.gender.value,
            nationality1Id: personalInfo.nationality1.id,
            nationality2Id: personalInfo.nationality2.id,
            birthday: birthday.toISOString().split("T")[0],
        };

        if (personalInfo.id) {
            dispatch(
                updatePersonalInfo({
                    userId,
                    id: personalInfo.id,
                    data,
                })
            );
        } else {
            dispatch(
                createPersonalInfo({
                    userId,
                    data,
                })
            );
        }

        dispatch(setErrors(null));
    };

    return { personalInfo, handleSubmit };
};

export default useForm;

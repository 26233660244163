import API from "./Api";

export default class CoachingAPI {
    static async getAll() {
        const result = await API.get(`/training-and-coaching`);
        return result;
    }

    static async getOne(id) {
        const result = await API.get(`/training-and-coaching/${id}`);
        return result;
    }

    static async create(data) {
        const result = await API.post(`/training-and-coaching`, data);
        return result;
    }

    static async update(id, data) {
        const result = await API.put(`/training-and-coaching/${id}`, data);
        return result;
    }

    static async delete(id) {
        const result = await API.delete(`/training-and-coaching/${id}`);
        return result;
    }
}

import { BrowserRouter as Router, useRoutes } from "react-router-dom";
import routes from "./routes";
import store, { persistor } from "./store/index";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material";
import theme from "./services/theme";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { setLogout, setStore } from "./services/apiMiddleware";
import { logout } from "./store/slices/user";

const AppRoutes = () => {
    const element = useRoutes(routes);
    return element;
};

function App() {
    setStore(store);
    setLogout(logout);
    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <Router>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <ThemeProvider theme={theme}>
                            <CssBaseline />
                            <AppRoutes />
                            <ToastContainer />
                        </ThemeProvider>
                    </LocalizationProvider>
                </Router>
            </PersistGate>
        </Provider>
    );
}

export default App;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
    BirthdateGenerator,
    OptionsHelper,
    formatDate,
} from "../../services/utils";
import { CityAPI, CountryAPI, NationalityAPI } from "../../services/api/model";
import PersonalInfoAPI from "../../services/api/personalInfo";
import { CITIES, COUNTRIES, PROFILE_MODES } from "../../services/constants";
import ContactInfoAPI from "../../services/api/ContactInfo";

const initialState = {
    // form values
    id: null,
    selectedCountry: null,
    selectedCity: null,
    selectedCountryCode: null,
    phoneNumber: null,
    personalWebsite: null,
    linkedin: null,
    errors: null,

    // available values
    countries: [],
    cities: [],

    loaders: {
        countries: {
            loading: false,
            error: null,
        },
        cities: {
            loading: false,
            error: null,
        },
        contactInfo: {
            loading: false,
            error: null,
        },
    },
};

export const fetchContactInfo = createAsyncThunk(
    "contactInfo/fetchContactInfo",
    async ({ userId }) => {
        const result = await ContactInfoAPI.getContactInfo(userId);
        return result;
    }
);

export const createContactInfo = createAsyncThunk(
    "contactInfo/createContactInfo",
    async ({ userId, data }) => {
        const result = await ContactInfoAPI.createContactInfo(userId, data);
        return result;
    }
);

export const updateContactInfo = createAsyncThunk(
    "contactInfo/updateContactInfo",
    async ({ userId, id, data }) => {
        const result = await ContactInfoAPI.updateContactInfo(userId, id, data);
        return result;
    }
);

export const fetchAllCountries = createAsyncThunk(
    "contactInfo/fetchAllCountries",
    async () => {
        const result = await CountryAPI.getAll();
        return result;
    }
);

export const fetchAllCities = createAsyncThunk(
    "contactInfo/fetchAllCities",
    async ({ country_id }) => {
        const result = await CityAPI.getAll(country_id);
        return result;
    }
);

const slice = createSlice({
    name: "contactInfo",
    initialState,
    reducers: {
        setCities: (state, action) => {
            state.cities = action.payload;
        },
        setSelectedCountry: (state, action) => {
            state.selectedCountry = action.payload;
        },
        setSelectedCity: (state, action) => {
            state.selectedCity = action.payload;
        },
        setSelectedCountryCode: (state, action) => {
            state.selectedCountryCode = action.payload;
        },
        setPhoneNumber: (state, action) => {
            state.phoneNumber = action.payload;
        },
        setPersonalWebsite: (state, action) => {
            state.personalWebsite = action.payload;
        },
        setLinkedin: (state, action) => {
            state.linkedin = action.payload;
        },
        setErrors: (state, action) => {
            state.errors = action.payload;
        },
        setAll: (state, action) => {
            state.selectedCountry = action.payload.selectedCountry;
            state.selectedCity = action.payload.selectedCity;
            state.selectedCountryCode = action.payload.selectedCountryCode;
            state.phoneNumber = action.payload.phoneNumber;
            state.personalWebsite = action.payload.personalWebsite;
            state.linkedin = action.payload.linkedin;
        },
        resetAll: (state, action) => {
            state.selectedCountry = null;
            state.selectedCity = null;
            state.selectedCountryCode = null;
            state.phoneNumber = null;
            state.personalWebsite = null;
            state.linkedin = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllCountries.pending, (state) => {
                state.loaders.countries.loading = true;
                state.loaders.countries.error = null;
            })
            .addCase(fetchAllCountries.fulfilled, (state, action) => {
                state.loaders.countries.loading = false;
                state.countries = action.payload;
            })
            .addCase(fetchAllCountries.rejected, (state, action) => {
                state.loaders.countries.loading = true;
                state.loaders.countries.error = action.message;
            })
            .addCase(fetchAllCities.pending, (state) => {
                state.loaders.cities.loading = true;
                state.loaders.cities.error = null;
            })
            .addCase(fetchAllCities.fulfilled, (state, action) => {
                state.loaders.cities.loading = false;
                state.cities = action.payload;
            })
            .addCase(fetchAllCities.rejected, (state, action) => {
                state.loaders.cities.loading = true;
                state.loaders.cities.error = action.message;
            })
            .addCase(fetchContactInfo.pending, (state) => {
                state.loaders.contactInfo.loading = true;
                state.loaders.contactInfo.error = null;
            })
            .addCase(fetchContactInfo.fulfilled, (state, action) => {
                if (action.payload) {
                    state.id = action.payload.id;
                    state.selectedCountry = action.payload.country;
                    state.selectedCity = action.payload.city;
                    state.selectedCountryCode = action.payload.countryCode;
                    state.phoneNumber = action.payload.phoneNumber;
                    state.personalWebsite = action.payload.websiteUrl;
                    state.linkedin = action.payload.linkedInUrl;
                }
                state.loaders.contactInfo.loading = false;
            })
            .addCase(fetchContactInfo.rejected, (state, action) => {
                state.loaders.contactInfo.loading = true;
                state.loaders.contactInfo.error = action.message;
            });
    },
});

export const {
    setCities,
    setSelectedCountry,
    setSelectedCity,
    setSelectedCountryCode,
    setPhoneNumber,
    setPersonalWebsite,
    setLinkedin,
    setErrors,
    setAll,
    resetAll,
} = slice.actions;

export default slice.reducer;

import * as Yup from "yup";

const validationSchema = Yup.object().shape({
    workField: Yup.object().required("مجال العمل مطلوب"),
    experienceYears: Yup.number().min(1, "يجب ان تكون اكبر من الصفر"),
    skills: Yup.array().required("عليك اضافة عنصر واحد على الاقل"),
    theoreticalKnowledge: Yup.array().required(
        "عليك اضافة عنصر واحد على الاقل"
    ),
    cv: Yup.string().required("الرجاء رفع ملف السيرة الذاتية"),
    availabilityToTravel: Yup.boolean(),
    // whenCanStart: Yup.date("يجب ان يكون تاريخ"),
});

export default validationSchema;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import UploadAPI from "../../services/api/Upload";

const initialState = {
    idImage: null,
    graduationImage: null,
    agreed: false,
    loaders: {
        uploadFile: {
            loading: false,
            error: null,
        },
    },
};

export const uploadIdImage = createAsyncThunk(
    "verifyAccount/uploadIdImage",
    async ({ file }) => {
        const result = await UploadAPI.uploadFile(file, "ids");
        return result;
    }
);

export const uploadGraduationImage = createAsyncThunk(
    "verifyAccount/uploadGraduationImage",
    async ({ file }) => {
        const result = await UploadAPI.uploadFile(file, "graduation_images");
        return result;
    }
);

const slice = createSlice({
    name: "verifyAccountForm",
    initialState,
    reducers: {
        setAgreed(state, action) {
            state.agreed = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(uploadIdImage.pending, (state) => {
                state.loaders.uploadFile.loading = true;
                state.loaders.uploadFile.error = null;
            })
            .addCase(uploadIdImage.fulfilled, (state, action) => {
                state.loaders.uploadFile.loading = false;
                state.idImage = action.payload.url;
            })
            .addCase(uploadIdImage.rejected, (state, action) => {
                state.loaders.uploadFile.loading = true;
                state.loaders.uploadFile.error = action.message;
            })
            .addCase(uploadGraduationImage.pending, (state) => {
                state.loaders.uploadFile.loading = true;
                state.loaders.uploadFile.error = null;
            })
            .addCase(uploadGraduationImage.fulfilled, (state, action) => {
                state.loaders.uploadFile.loading = false;
                state.graduationImage = action.payload.url;
            })
            .addCase(uploadGraduationImage.rejected, (state, action) => {
                state.loaders.uploadFile.loading = true;
                state.loaders.uploadFile.error = action.message;
            });
    },
});

export const { setAgreed } = slice.actions;

export default slice.reducer;

import { useTheme } from "@emotion/react";
import {
    Autocomplete,
    Avatar,
    Box,
    Button,
    FormControl,
    Grid,
    InputBase,
    InputLabel,
    Stack,
    TextField,
    Typography,
    useMediaQuery,
} from "@mui/material";
import React from "react";
import XCenter from "../components/common/XCenter";
import ValidationErrorMessage from "../components/common/ValidationErrorMessage";
import { DeviceMobile, Envelope } from "@phosphor-icons/react";
import { useSelector } from "react-redux";

const ContactUs = () => {
    const theme = useTheme();
    const isMdScreen = useMediaQuery(theme.breakpoints.down("md"));

    const fullName = useSelector((state) => {
        return `${state.personalInfo.firstNameAr} ${state.personalInfo.lastNameAr}`;
    });

    const email = useSelector((state) => state.user.email);
    return (
        <Box className="page">
            <Grid container justifyContent={"center"}>
                <Grid
                    container
                    item
                    direction={"row"}
                    rowSpacing={5}
                    columnSpacing={isMdScreen ? 0 : 10}
                    md={6}
                    sm={12}
                    xs={12}
                >
                    <Grid item xs={12}>
                        <XCenter>
                            <Typography variant="heading1">
                                {"اتصل بنا"}
                            </Typography>
                        </XCenter>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel shrink htmlFor="firstNameAr">
                                <Typography variant="heading2">
                                    {"الاسم الكامل"}
                                </Typography>
                            </InputLabel>
                            <InputBase
                                type="text"
                                variant="labeled"
                                placeholder="اكتب هنا"
                                id="firstNameAr"
                                defaultValue={fullName}
                                // value={personalInfo.firstNameAr}
                                onChange={
                                    (e) => {}
                                    // dispatch(setFirstNameAr(e.target.value))
                                }
                            />
                            <ValidationErrorMessage
                                errors={null}
                                field={"firstNameAr"}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel shrink htmlFor="phone">
                                <Typography variant="heading2">
                                    {"رقم الهاتف"}
                                </Typography>
                            </InputLabel>
                            <Grid item container marginTop={4}>
                                <Grid xs={1.25}>
                                    <Autocomplete
                                        variant="labeled"
                                        id="phonecode"
                                        // value={}
                                        options={[]}
                                        getOptionLabel={(option) =>
                                            option ? option.nameAr : ""
                                        }
                                        isOptionEqualToValue={(option, value) =>
                                            option.id === value.id
                                        }
                                        onChange={
                                            (e, option) => {}
                                            // dispatch(setSpec(option))
                                        }
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                placeholder="اختر من هنا"
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid xs={10.75}>
                                    <InputBase
                                        variant="labeled"
                                        id="phone"
                                        fullWidth
                                        sx={{
                                            paddingY: "6px",
                                        }}
                                        // value={jobHistoryForm.organization}
                                        onChange={
                                            (e) => {}
                                            // dispatch(setOrganization(e.target.value))
                                        }
                                        placeholder="اكتب هنا"
                                    />
                                </Grid>
                            </Grid>

                            <ValidationErrorMessage
                                errors={null}
                                field={"phone"}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel shrink htmlFor="email">
                                <Typography variant="heading2">
                                    {"البريد الالكتروني"}
                                </Typography>
                            </InputLabel>
                            <InputBase
                                type="text"
                                variant="labeled"
                                placeholder="اكتب هنا"
                                id="email"
                                defaultValue={email}
                                // value={personalInfo.firstNameAr}
                                onChange={
                                    (e) => {}
                                    // dispatch(setFirstNameAr(e.target.value))
                                }
                            />
                            <ValidationErrorMessage
                                errors={null}
                                field={"email"}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel shrink htmlFor="message">
                                <Typography variant="heading2">
                                    {"رسالتك"}
                                </Typography>
                            </InputLabel>
                            <InputBase
                                multiline
                                minRows={4}
                                variant="labeled"
                                id="message"
                                // value={jobHistoryForm.responsibilities}
                                onChange={
                                    (value) => {}
                                    // dispatch(setResponsibilities(value))
                                }
                                placeholder="اكتب هنا"
                            />
                            <ValidationErrorMessage
                                errors={null}
                                field={"message"}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <Button fullWidth variant="main" sx={{ paddingY: 2 }}>
                            {"ارسال"}
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <Grid container>
                            <Grid item xs={6}>
                                <Stack
                                    direction={"row"}
                                    gap={2}
                                    alignItems={"center"}
                                >
                                    <Avatar
                                        sx={{
                                            color: theme.palette.primary.main,
                                            bgcolor: "#fff",
                                            borderColor:
                                                theme.palette.primary.main,
                                            borderWidth: "1px",
                                            borderStyle: "solid",
                                            height: 56,
                                            width: 56,
                                        }}
                                    >
                                        <DeviceMobile size={32} />
                                    </Avatar>
                                    <Typography variant="heading2">
                                        {"+90 (538) 033 40 67"}
                                    </Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={6}>
                                <Stack
                                    direction={"row"}
                                    gap={2}
                                    alignItems={"center"}
                                >
                                    <Avatar
                                        sx={{
                                            color: theme.palette.primary.main,
                                            bgcolor: "#fff",
                                            borderColor:
                                                theme.palette.primary.main,
                                            borderWidth: "1px",
                                            borderStyle: "solid",
                                            height: 56,
                                            width: 56,
                                        }}
                                    >
                                        <Envelope size={32} />
                                    </Avatar>
                                    <Typography variant="heading2">
                                        {"info@syrianalumni.com"}
                                    </Typography>
                                </Stack>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
};

export default ContactUs;

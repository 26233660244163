import React from "react";
import { useDispatch, useSelector } from "react-redux";
import useForm from "./useForm";
import { useTheme } from "@emotion/react";
import {
    Autocomplete,
    Box,
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    Grid,
    InputBase,
    InputLabel,
    TextField,
    Typography,
    useMediaQuery,
} from "@mui/material";
import Loader from "../../../../common/Loader";
import ValidationErrorMessage from "../../../../common/ValidationErrorMessage";
import {
    setAchievements,
    setCurrent,
    setEndDate,
    setOrganization,
    setResponsibilities,
    setSelectedJobPositionLevel,
    setSelectedWorkField,
    setStartDate,
} from "../../../../../store/slices/jobHistoryForm";
import { DatePicker } from "@mui/x-date-pickers";
import { DatePickerStyle } from "../../../../../services/theme";
import XCenter from "../../../../common/XCenter";
import dayjs from "dayjs";
import { PROFILE_MODES } from "../../../../../services/constants";
const Form = () => {
    const dispatch = useDispatch();
    const [jobHistoryForm, jobHistoryData, handleSubmit] = useForm();

    const { mode } = useSelector((state) => state.personalInfo);

    const theme = useTheme();
    const isMdScreen = useMediaQuery(theme.breakpoints.down("md"));

    return (
        <Box component={"form"}>
            <Grid
                container
                direction={"row"}
                rowSpacing={5}
                columnSpacing={isMdScreen ? 0 : 10}
            >
                <Grid item xs={12} sm={12} md={12}>
                    <Loader
                        loading={jobHistoryForm.loaders.workFields.loading}
                        error={jobHistoryForm.loaders.workFields.error}
                    >
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel shrink htmlFor="workField">
                                <Typography variant="heading2">
                                    {"نوع الوظيفة *"}
                                </Typography>
                            </InputLabel>
                            <Autocomplete
                                variant="labeled"
                                id="workField"
                                value={jobHistoryForm.selectedWorkField}
                                options={jobHistoryForm.workFields}
                                getOptionLabel={(option) =>
                                    option ? option.nameAr : ""
                                }
                                isOptionEqualToValue={(option, value) =>
                                    option.id === value.id
                                }
                                onChange={(e, option) =>
                                    dispatch(setSelectedWorkField(option))
                                }
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        placeholder="اختر من هنا"
                                    />
                                )}
                            />
                            <ValidationErrorMessage
                                errors={jobHistoryForm.errors}
                                field={"workField"}
                            />
                        </FormControl>
                    </Loader>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    <Loader
                        loading={
                            jobHistoryForm.loaders.jobPositionLevels.loading
                        }
                        error={jobHistoryForm.loaders.jobPositionLevels.error}
                    >
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel shrink htmlFor="jobPositionLevel">
                                <Typography variant="heading2">
                                    {"المنصب الوظيفي *"}
                                </Typography>
                            </InputLabel>
                            <Autocomplete
                                variant="labeled"
                                id="jobPositionLevel"
                                value={jobHistoryForm.selectedJobPositionLevel}
                                options={jobHistoryForm.jobPositionLevels}
                                getOptionLabel={(option) =>
                                    option ? option.nameAr : ""
                                }
                                isOptionEqualToValue={(option, value) =>
                                    option.id === value.id
                                }
                                onChange={(e, option) =>
                                    dispatch(
                                        setSelectedJobPositionLevel(option)
                                    )
                                }
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        placeholder="اختر من هنا"
                                    />
                                )}
                            />
                            <ValidationErrorMessage
                                errors={jobHistoryForm.errors}
                                field={"jobPositionLevel"}
                            />
                        </FormControl>
                    </Loader>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    <FormControl variant="outlined" fullWidth>
                        <InputLabel shrink htmlFor="organization">
                            <Typography variant="heading2">
                                {"الجهة الموظفة *"}
                            </Typography>
                        </InputLabel>
                        <InputBase
                            variant="labeled"
                            id="organization"
                            value={jobHistoryForm.organization}
                            onChange={(e) =>
                                dispatch(setOrganization(e.target.value))
                            }
                            placeholder="اكتب هنا"
                        />
                        <ValidationErrorMessage
                            errors={jobHistoryForm.errors}
                            field={"organization"}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    <FormControl variant="outlined" fullWidth>
                        <InputLabel shrink htmlFor="startDate">
                            <Typography variant="heading2">
                                {"تاريخ البدء *"}
                            </Typography>
                        </InputLabel>
                        <DatePicker
                            id="startDate"
                            sx={{
                                ...DatePickerStyle,
                            }}
                            value={dayjs(jobHistoryForm.startDate)}
                            onChange={(value) => dispatch(setStartDate(value))}
                        />
                        <ValidationErrorMessage
                            errors={jobHistoryForm.errors}
                            field={"startDate"}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    <FormControl variant="outlined" fullWidth>
                        <InputLabel shrink htmlFor="endDate">
                            <Typography variant="heading2">
                                {"تاريخ الانتهاء *"}
                            </Typography>
                        </InputLabel>
                        <DatePicker
                            id="endDate"
                            sx={{
                                ...DatePickerStyle,
                            }}
                            value={dayjs(jobHistoryForm.endDate)}
                            onChange={(value) => dispatch(setEndDate(value))}
                            disabled={jobHistoryForm.current}
                        />
                        <ValidationErrorMessage
                            errors={jobHistoryForm.errors}
                            field={"endDate"}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                    <FormControl variant="outlined" fullWidth>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    value={jobHistoryForm.current}
                                    checked={jobHistoryForm.current}
                                    onChange={(e) =>
                                        dispatch(setCurrent(e.target.checked))
                                    }
                                />
                            }
                            label="مازلت اعمل هنا"
                        />
                        <ValidationErrorMessage
                            errors={jobHistoryForm.errors}
                            field={"current"}
                        />
                    </FormControl>
                </Grid>
                {mode == PROFILE_MODES.UPDATE && (
                    <>
                        <Grid item xs={12} sm={12} md={12}>
                            <FormControl variant="outlined" fullWidth>
                                <InputLabel shrink htmlFor="responsibilities">
                                    <Typography variant="heading2">
                                        {"المسؤوليات *"}
                                    </Typography>
                                </InputLabel>
                                <InputBase
                                    multiline
                                    minRows={4}
                                    variant="labeled"
                                    id="responsibilities"
                                    value={jobHistoryForm.responsibilities}
                                    onChange={(e) =>
                                        dispatch(setResponsibilities(e.target.value))
                                    }
                                    placeholder="اكتب هنا"
                                />
                                <ValidationErrorMessage
                                    errors={jobHistoryForm.errors}
                                    field={"responsibilities"}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <FormControl variant="outlined" fullWidth>
                                <InputLabel shrink htmlFor="achievements">
                                    <Typography variant="heading2">
                                        {"الانجازات *"}
                                    </Typography>
                                </InputLabel>
                                <InputBase
                                    multiline
                                    minRows={4}
                                    variant="labeled"
                                    id="achievements"
                                    value={jobHistoryForm.achievements}
                                    onChange={(e) =>
                                        dispatch(setAchievements(e.target.value))
                                    }
                                    placeholder="اكتب هنا"
                                />
                                <ValidationErrorMessage
                                    errors={jobHistoryForm.errors}
                                    field={"achievements"}
                                />
                            </FormControl>
                        </Grid>
                    </>
                )}

                <Grid item container>
                    <Grid item xs={12}>
                        <XCenter>
                            <Button
                                onClick={() => handleSubmit()}
                                sx={{
                                    textDecoration: "underline",
                                    fontSize: "1.2rem",
                                }}
                            >
                                {jobHistoryData.current
                                    ? "تعديل"
                                    : "إضافة تخصص آخر"}
                            </Button>
                        </XCenter>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
};

export default Form;

import API from "./Api";

export default class JobPositionLevelAPI {
    static async getAll(userId) {
        const result = await API.get(`/job-position-level`);
        return result;
    }

    static async getOne(userId, id) {
        const result = await API.get(
            `/job-position-level/${id}`
        );
        return result;
    }

    static async create(userId, data) {
        const result = await API.post(
            `/job-position-level`,
            data
        );
        return result;
    }

    static async update(userId, id, data) {
        const result = await API.put(
            `/job-position-level/${id}`,
            data
        );
        return result;
    }

    static async delete(userId, id) {
        const result = await API.delete(
            `/job-position-level/${id}`
        );
        return result;
    }
}

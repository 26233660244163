// src/slices/birthdateSlice.js

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
    EducationLevelAPI,
    SpecAPI,
    UniversityAPI,
} from "../../services/api/model";

const initialState = {
    spec: null,
    university: null,
    level: null,
    gradDate: null,
    graduated: true,
    errors: null,
    specs: [],
    universities: [],
    levels: [],
    loaders: {
        specs: {
            loading: false,
            error: null,
        },
        universities: {
            loading: false,
            error: null,
        },
        levels: {
            loading: false,
            error: null,
        },
    },
};

export const fetchAllUniversities = createAsyncThunk(
    "universities/fetchAllUniversities",
    async () => {
        const result = await UniversityAPI.getAll();
        return result;
    }
);

export const fetchAllSpecs = createAsyncThunk(
    "spec/fetchAllUniversities",
    async () => {
        const result = await SpecAPI.getAll();
        return result;
    }
);

export const fetchAllLevels = createAsyncThunk(
    "educationLevels/fetchAllLevels",
    async () => {
        const result = await EducationLevelAPI.getAll();
        return result;
    }
);

const educationLevelForm = createSlice({
    name: "educationLevelForm",
    initialState,
    reducers: {
        setSpec: (state, action) => {
            state.spec = action.payload;
        },
        setUniversity: (state, action) => {
            state.university = action.payload;
        },
        setLevel: (state, action) => {
            state.level = action.payload;
        },
        setGradDate: (state, action) => {
            state.gradDate = action.payload;
        },
        setGraduated: (state, action) => {
            state.graduated = action.payload;
        },
        setErrors: (state, action) => {
            state.errors = action.payload;
        },
        setAll: (state, action) => {
            state.spec = action.payload.spec;
            state.university = action.payload.university;
            state.level = action.payload.level;
            state.gradDate = action.payload.gradDate;
            state.graduated = action.payload.graduated;
        },
        resetAll: (state, action) => {
            state.spec = null;
            state.university = null;
            state.level = null;
            state.gradDate = null;
            state.graduated = false;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllUniversities.pending, (state) => {
                state.loaders.universities.loading = true;
                state.loaders.universities.error = null;
            })
            .addCase(fetchAllUniversities.fulfilled, (state, action) => {
                state.loaders.universities.loading = false;
                state.universities = action.payload;
            })
            .addCase(fetchAllUniversities.rejected, (state, action) => {
                state.loaders.universities.loading = true;
                state.loaders.universities.error = action.message;
            })
            .addCase(fetchAllSpecs.pending, (state) => {
                state.loaders.specs.loading = true;
                state.loaders.specs.error = null;
            })
            .addCase(fetchAllSpecs.fulfilled, (state, action) => {
                state.loaders.specs.loading = false;
                state.specs = action.payload;
            })
            .addCase(fetchAllSpecs.rejected, (state, action) => {
                state.loaders.specs.loading = true;
                state.loaders.specs.error = action.message;
            })
            .addCase(fetchAllLevels.pending, (state) => {
                state.loaders.levels.loading = true;
                state.loaders.levels.error = null;
            })
            .addCase(fetchAllLevels.fulfilled, (state, action) => {
                state.loaders.levels.loading = false;
                state.levels = action.payload;
            })
            .addCase(fetchAllLevels.rejected, (state, action) => {
                state.loaders.levels.loading = true;
                state.loaders.levels.error = action.message;
            });
    },
});

export const {
    setSpec,
    setUniversity,
    setLevel,
    setGradDate,
    setGraduated,
    setAll,
    resetAll,
    setErrors,
} = educationLevelForm.actions;

export default educationLevelForm.reducer;

import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import Navbar from "../components/common/Navbar";
import Footer from "../components/common/Footer";
import { useDispatch, useSelector } from "react-redux";
import { useTheme } from "@emotion/react";
import { Box, useMediaQuery } from "@mui/material";
import { fetchPersonalInfo } from "../store/slices/personalInfo";
import NavbarMobile from "../components/common/NavbarMobile";
import { fetchUser } from "../store/slices/user";
import { REGISTERTATION_TYPES } from "../services/constants";

const MainLayout = () => {
    const dispatch = useDispatch();

    const { role, id: userId } = useSelector((state) => state.user);

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const isMdScreen = useMediaQuery(theme.breakpoints.down("md"));

    useEffect(() => {
        if (role !== REGISTERTATION_TYPES.ADMIN) {
            dispatch(fetchPersonalInfo({ userId }));
        }
        dispatch(fetchUser({ id: userId }));
    }, []);

    return (
        <Box sx={{ minHeight: "100vh" }}>
            {isMdScreen ? <NavbarMobile /> : <Navbar />}
            <Box
                component={"main"}
                sx={{
                    padding: isSmallScreen ? "1rem" : "2rem",
                }}
            >
                <Outlet />
            </Box>
            <Footer />
        </Box>
    );
};

export default MainLayout;

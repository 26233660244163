import API from "./Api";

export default class PersonalInfoAPI {
    static async getPersonalInfo(userId) {
        const result = await API.get(`/personal-info?user=${userId}`);
        return result;
    }

    static async createPersonalInfo(userId, data) {
        const result = await API.post(`/personal-info?user=${userId}`, data);
        return result;
    }

    static async updatePersonalInfo(userId, id, data) {
        const result = await API.put(
            `/personal-info/${id}?user=${userId}`,
            data
        );
        return result;
    }
}

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    fetchAllLanguages,
    setErrors,
} from "../../../../../store/slices/languageExperiencesForm";
import {
    createLanguageExperience,
    fetchOneLanguageExperience,
    updateLanguageExperience,
} from "../../../../../store/slices/languageExperiencesData";
import { toast } from "react-toastify";
import { collectAllValidationErrors } from "../../../../../services/utils";
import validationSchema from "./validationSchema";
import { PROFILE_MODES } from "../../../../../services/constants";

const useForm = () => {
    const dispatch = useDispatch();

    const { id: userId } = useSelector((state) => state.user);
    const { mode } = useSelector((state) => state.personalInfo);

    const languageExperiencesForm = useSelector(
        (state) => state.languageExperiencesForm
    );

    const languageExperiencesData = useSelector(
        (state) => state.languageExperiencesData
    );

    useEffect(() => {
        dispatch(fetchAllLanguages());
    }, []);

    useEffect(() => {
        if (
            mode == PROFILE_MODES.REGISTER &&
            languageExperiencesData.all.length > 0
        ) {
            const id = languageExperiencesData.all[0].id;
            dispatch(fetchOneLanguageExperience({ userId, id }));
        }
    }, [languageExperiencesData.all]);

    const handleSubmit = async () => {
        try {
            await validationSchema.validate(
                {
                    language: languageExperiencesForm.lang,
                    level: languageExperiencesForm.level,
                },
                { abortEarly: false }
            );
        } catch (error) {
            const errors = collectAllValidationErrors(error);
            dispatch(setErrors(errors));
            toast.warn("اصلح الحقول المطلوبة");
            return;
        }

        if (languageExperiencesForm.current) {
            dispatch(
                updateLanguageExperience({
                    userId,
                    id: languageExperiencesForm.current.id,
                    languageId: languageExperiencesForm.lang.id,
                    level: languageExperiencesForm.level.value,
                })
            );
        } else {
            dispatch(
                createLanguageExperience({
                    userId,
                    languageId: languageExperiencesForm.lang.id,
                    level: languageExperiencesForm.level.value,
                })
            );
        }
    };
    return [languageExperiencesForm, handleSubmit];
};

export default useForm;

// apiMiddleware.js
let store;
let logout;

export const setStore = (reduxStore) => {
    store = reduxStore;
};

export const getStore = () => store;

export const setLogout = (reduxLogout) => {
    logout = reduxLogout;
};

export const getLogout = () => logout;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import EducationAPI from "../../services/api/Education";
import { resetAll, setAll } from "./educationLevelForm";

const initialState = {
    all: [],
    current: null,
    loaders: {
        all: {
            loading: false,
            error: null,
        },
        current: {
            loading: false,
            error: null,
        },
    },
};

export const fetchAllEducations = createAsyncThunk(
    "educations/fetchAllEducations",
    async ({ userId }) => {
        const result = await EducationAPI.getAll(userId);
        return result;
    }
);

export const fetchOneEducation = createAsyncThunk(
    "educations/fetchOneEducation",
    async ({ userId, id }, { dispatch }) => {
        const result = await EducationAPI.getOne(userId, id);
        dispatch(
            setAll({
                spec: result.specialization,
                university: result.college,
                level: result.educationLevel,
                gradDate: result.graduationDate,
                graduated: result.graduated,
            })
        );
        return result;
    }
);

export const createEducation = createAsyncThunk(
    "educations/createEducation",
    async ({ userId, data }, { dispatch }) => {
        const result = await EducationAPI.create(userId, data);
        dispatch(fetchAllEducations(userId));
        dispatch(resetAll());
        return result;
    }
);

export const updateEducation = createAsyncThunk(
    "educations/updateEducation",
    async ({ userId, id, data }, { dispatch }) => {
        const result = await EducationAPI.update(userId, id, data);
        dispatch(fetchAllEducations(userId));
        dispatch(resetAll());
        return result;
    }
);

export const deleteEducation = createAsyncThunk(
    "educations/deleteEducation",
    async ({ userId, id }, { dispatch }) => {
        const result = await EducationAPI.delete(userId, id);
        dispatch(fetchAllEducations(userId));
        dispatch(resetAll());
        return result;
    }
);

const educationLevelData = createSlice({
    name: "educationLevelData",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllEducations.pending, (state) => {
                state.loaders.all.loading = true;
                state.loaders.all.error = null;
            })
            .addCase(fetchAllEducations.fulfilled, (state, action) => {
                state.loaders.all.loading = false;
                state.all = action.payload;
            })
            .addCase(fetchAllEducations.rejected, (state, action) => {
                state.loaders.all.loading = true;
                state.loaders.all.error = action.message;
            })
            .addCase(fetchOneEducation.pending, (state) => {
                state.loaders.current.loading = true;
                state.loaders.current.error = null;
            })
            .addCase(fetchOneEducation.fulfilled, (state, action) => {
                state.loaders.current.loading = false;
                state.current = action.payload;
            })
            .addCase(fetchOneEducation.rejected, (state, action) => {
                state.loaders.current.loading = true;
                state.loaders.current.error = action.message;
            })
            .addCase(updateEducation.fulfilled, (state, action) => {
                state.current = null;
            })
            .addCase(deleteEducation.fulfilled, (state, action) => {
                state.current = null;
            });
    },
});

export const {} = educationLevelData.actions;

export default educationLevelData.reducer;

import { Box, Grid } from "@mui/material";
import React from "react";
import RequestItem from "../../components/common/RequestItem";

const ReceivedInterestRequests = () => {
    return (
        <Box className="page">
            <Grid container direction={"row"} spacing={3}>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                    <RequestItem />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                    <RequestItem />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                    <RequestItem />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                    <RequestItem />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                    <RequestItem />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                    <RequestItem />
                </Grid>
            </Grid>
        </Box>
    );
};

export default ReceivedInterestRequests;

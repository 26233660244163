import API from "./Api";

export default class ContactInfoAPI {
    static async getContactInfo(userId) {
        const result = await API.get(`/contact-information`);
        return result;
    }

    static async createContactInfo(userId, data) {
        const result = await API.post(
            `/contact-information`,
            data
        );
        return result;
    }

    static async updateContactInfo(userId, id, data) {
        const result = await API.put(`/contact-information/`, data);
        return result;
    }
}

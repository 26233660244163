import { Box } from "@mui/material";
import React from "react";
import LogoImg from "../../assets/img/logo.png";

const Logo = ({ variant = "large" }) => {
    return (
        <Box
            component={"img"}
            src={LogoImg}
            alt="نادي الخريجين"
            sx={{
                objectFit: "contain",
                objectPosition: "center",
                height: variant == "large" ? "100px" : "50px",
            }}
        />
    );
};

export default Logo;

import React, { useEffect } from "react";
import Footer from "../components/common/Footer";
import { Box, Button, Link as MuiLink, useMediaQuery } from "@mui/material";
import { Link, Outlet } from "react-router-dom";
import Logo from "../assets/img/logo.png";
import { useTheme } from "@emotion/react";
import { useDispatch, useSelector } from "react-redux";
import { fetchUser, logoutThunk } from "../store/slices/user";
import useAuthenicate from "../hooks/useAuthenicate";
import LogoutBtn from "../components/common/LogoutBtn";

const AuthLayout = () => {
    const dispatch = useDispatch();
    const isAuthenticated = useAuthenicate();

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));


    return (
        <Box sx={{ minHeight: "100vh" }}>
            <Box
                component={"nav"}
                sx={{
                    padding: isSmallScreen ? "1rem" : "2rem",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                }}
            >
                <MuiLink component={Link} to={"/"}>
                    <Box
                        component={"img"}
                        src={Logo}
                        alt="نادي الخريجين"
                        sx={{
                            objectFit: "contain",
                            objectPosition: "center",
                            height: "100px",
                        }}
                    />
                </MuiLink>
                <LogoutBtn />
            </Box>
            <Box
                component={"main"}
                sx={{
                    padding: isSmallScreen ? "1rem" : "2rem",
                }}
            >
                <Outlet />
            </Box>
            <Footer />
        </Box>
    );
};

export default AuthLayout;

import {
    Box,
    Chip,
    Grid,
    Stack,
    Typography,
    useMediaQuery,
    useTheme,
    Link as MuiLink,
    IconButton,
} from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../common/Loader";
import { Link } from "react-router-dom";
import {
    deleteCoaching,
    fetchOneCoaching,
} from "../../../../store/slices/coachingData";
import { Pencil, Trash } from "@phosphor-icons/react";
import {
    CustomerTypeLabel,
    ServiceDeliveryTypeLabel,
} from "../../../../services/constants";

const Item = (props) => {
    const dispatch = useDispatch();
    const userId = useSelector((state) => state.user.id);

    const theme = useTheme();
    const isMdScreen = useMediaQuery(theme.breakpoints.down("md"));

    return (
        <Box
            className="block"
            sx={{
                position: "relative",
                boxShadow: "0px 0px 10px 2px #0000000a;",
                padding: "1rem",
            }}
        >
            <Grid container rowSpacing={2} columnSpacing={isMdScreen ? 0 : 10}>
                <Grid item xs={12} sm={12} md={12}>
                    <Chip label={props.experienceType} color="primary" />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                    <Typography variant="heading2">
                        {"الموضوع الرئيسي الذي تقدمه :"}
                        <Typography variant="normal-main">
                            {props.workField?.nameAr}
                        </Typography>
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <Typography variant="heading2">
                        {"منهجية تقديم الخدمة: "}
                        <Typography variant="normal-main">
                            {props.serviceDeliveryType
                                .map((item) => ServiceDeliveryTypeLabel[item])
                                .join(",")}
                        </Typography>
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <Typography variant="heading2">
                        {"عدد الساعات: "}
                        <Typography variant="normal-main">
                            {props.providedHours}
                        </Typography>
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <Typography variant="heading2">
                        {"نوع عملائك: "}
                        <Typography variant="normal-main">
                            {props.customerType
                                .map((item) => CustomerTypeLabel[item])
                                .join(",")}
                        </Typography>
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <Typography variant="heading2">
                        {"عدد عملائك: "}
                        <Typography variant="normal-main">
                            {props.customerNumber}
                        </Typography>
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                    <Typography variant="heading2">
                        {"الخبرة العملية في هذا الموضوع: "}
                    </Typography>
                    {props.contractedEntities.map((item) => (
                        <Box>
                            <Typography variant="normal-main">
                                - {item}
                            </Typography>
                        </Box>
                    ))}
                </Grid>
            </Grid>
            <Box
                sx={{
                    position: "absolute",
                    left: 0,
                    top: 0,
                }}
            >
                <Stack direction="row" spacing={1}>
                    <IconButton
                        onClick={() =>
                            dispatch(
                                deleteCoaching({
                                    id: props.id,
                                })
                            )
                        }
                    >
                        <Trash color="red" />
                    </IconButton>
                    <IconButton
                        onClick={() => {
                            dispatch(
                                fetchOneCoaching({
                                    id: props.id,
                                })
                            );
                            props.scrollRef.current.scrollIntoView();
                        }}
                    >
                        <Pencil style={{ color: theme.palette.primary.main }} />
                    </IconButton>
                </Stack>
            </Box>
        </Box>
    );
};

const List = ({ scrollRef }) => {
    const { all, loaders } = useSelector((state) => state.coachingData);
    const theme = useTheme();
    const isMdScreen = useMediaQuery(theme.breakpoints.down("md"));

    return (
        <Box className="block">
            <Stack gap={2}>
                <Loader
                    loading={loaders.all.loading}
                    error={loaders.all.error}
                    height={200}
                >
                    {all.map((item) => (
                        <Box key={item.id}>
                            <Item {...item} scrollRef={scrollRef} />
                        </Box>
                    ))}
                </Loader>
            </Stack>
        </Box>
    );
};

export default List;

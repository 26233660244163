import React from "react";
import Lottie from "react-lottie";
import ProfileAnimation from "../../assets/json/profile-animation.json";
const ProfileUpdate = () => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: ProfileAnimation,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
        },
    };
    return (
        <div>
            <Lottie options={defaultOptions} height={400} width={400} />
        </div>
    );
};

export default ProfileUpdate;

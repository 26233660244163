import { Box, Typography } from "@mui/material";
import React from "react";

const ValidationErrorMessage = ({ errors, field }) => {
    const all = errors ? errors[field] : "";
    if (all) {
        if (Array.isArray(all)) {
            const messages = all.join("\n");
            return (
                <Box>
                    {messages.map((message) => (
                        <Typography
                            sx={{
                                color: "red",
                                fontSize: "0.8rem",
                            }}
                        >
                            {message}
                        </Typography>
                    ))}
                </Box>
            );
        } else {
            return (
                <Box>
                    <Typography
                        sx={{
                            color: "red",
                            fontSize: "0.8rem",
                        }}
                    >
                        {all}
                    </Typography>
                </Box>
            );
        }
    } else {
        return <div></div>;
    }
};

export default ValidationErrorMessage;

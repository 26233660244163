import { useTheme } from "@emotion/react";
import {
    Autocomplete,
    Button,
    FormControl,
    Grid,
    InputBase,
    InputLabel,
    TextField,
    Typography,
    useMediaQuery,
} from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import useForm from "./useForm";
import { Box } from "@mui/system";
import { DatePickerStyle } from "../../../../services/theme";
import ValidationErrorMessage from "../../../common/ValidationErrorMessage";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import XCenter from "../../../common/XCenter";
import {
    openForm,
    setEmail,
    setName,
    setPhoneNumber,
    setRelationship,
    setSelectedCountryCode,
    setSelectedJobPositionLevel,
    setTopic,
} from "../../../../store/slices/referencesForm";
import Loader from "../../../common/Loader";

const Form = () => {
    const dispatch = useDispatch();

    const theme = useTheme();
    const isMdScreen = useMediaQuery(theme.breakpoints.down("md"));

    const [referencesForm, referencesData, handleSubmit] = useForm();
    return (
        <Box component={"form"}>
            {referencesForm.closed === false && (
                <Grid
                    container
                    direction={"row"}
                    justifyContent={"center"}
                    rowSpacing={5}
                    columnSpacing={isMdScreen ? 0 : 10}
                >
                    <Grid item xs={12} sm={12} md={6}>
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel shrink htmlFor="name">
                                <Typography variant="heading2">
                                    {"اسم المرجع*"}
                                </Typography>
                            </InputLabel>
                            <InputBase
                                variant="labeled"
                                placeholder="اكتب هنا"
                                id="name"
                                onChange={(e) =>
                                    dispatch(setName(e.target.value))
                                }
                                value={referencesForm.name}
                            />
                            <ValidationErrorMessage
                                errors={referencesForm.errors}
                                field={"name"}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel shrink htmlFor="relationship">
                                <Typography variant="heading2">
                                    {"العلاقة*"}
                                </Typography>
                            </InputLabel>
                            <InputBase
                                variant="labeled"
                                placeholder="اكتب هنا"
                                id="relationship"
                                onChange={(e) =>
                                    dispatch(setRelationship(e.target.value))
                                }
                                value={referencesForm.relationship}
                            />
                            <ValidationErrorMessage
                                errors={referencesForm.errors}
                                field={"relationship"}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <Loader
                            loading={
                                referencesForm.loaders.jobPositionLevels.loading
                            }
                            error={
                                referencesForm.loaders.jobPositionLevels.error
                            }
                        >
                            <FormControl variant="outlined" fullWidth>
                                <InputLabel shrink htmlFor="jobPositionLevel">
                                    <Typography variant="heading2">
                                        {"الموضع في ذاك الوقت *"}
                                    </Typography>
                                </InputLabel>
                                <Autocomplete
                                    variant="labeled"
                                    id="jobPositionLevel"
                                    value={
                                        referencesForm.selectedJobPositionLevel
                                    }
                                    options={referencesForm.jobPositionLevels}
                                    getOptionLabel={(option) =>
                                        option ? option.nameAr : ""
                                    }
                                    isOptionEqualToValue={(option, value) =>
                                        option.id === value.id
                                    }
                                    onChange={(e, option) =>
                                        dispatch(
                                            setSelectedJobPositionLevel(option)
                                        )
                                    }
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            placeholder="اختر من هنا"
                                        />
                                    )}
                                />
                                <ValidationErrorMessage
                                    errors={referencesForm.errors}
                                    field={"jobPositionLevel"}
                                />
                            </FormControl>
                        </Loader>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel shrink htmlFor="email">
                                <Typography variant="heading2">
                                    {"البريد الالكتروني"}
                                </Typography>
                            </InputLabel>
                            <InputBase
                                type="text"
                                variant="labeled"
                                placeholder="اكتب هنا"
                                id="email"
                                value={referencesForm.email}
                                onChange={(e) =>
                                    dispatch(setEmail(e.target.value))
                                }
                            />
                            <ValidationErrorMessage
                                errors={referencesForm.errors}
                                field={"email"}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel shrink htmlFor="phone">
                                <Typography variant="heading2">
                                    {"رقم الهاتف *"}
                                </Typography>
                            </InputLabel>
                            <Grid item container marginTop={4}>
                                <Grid xs={1.25}>
                                    <Autocomplete
                                        variant="labeled"
                                        id="phonecode"
                                        value={
                                            referencesForm.selectedCountryCode
                                        }
                                        options={referencesForm.countries}
                                        getOptionLabel={(option) =>
                                            option ? "+" + option.phoneCode : ""
                                        }
                                        isOptionEqualToValue={(option, value) =>
                                            option.id === value.id
                                        }
                                        onChange={(e, option) =>
                                            dispatch(
                                                setSelectedCountryCode(option)
                                            )
                                        }
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                placeholder="اختر من هنا"
                                            />
                                        )}
                                        sx={{
                                            "& .MuiInputBase-root": {
                                                paddingRight: "10px !important",
                                            },
                                        }}
                                    />
                                </Grid>
                                <Grid xs={10.75}>
                                    <InputBase
                                        variant="labeled"
                                        id="phone"
                                        fullWidth
                                        sx={{
                                            paddingY: "6px",
                                        }}
                                        value={referencesForm.phoneNumber}
                                        onChange={(e) =>
                                            dispatch(
                                                setPhoneNumber(e.target.value)
                                            )
                                        }
                                        placeholder="اكتب هنا"
                                    />
                                </Grid>
                            </Grid>

                            <ValidationErrorMessage
                                errors={null}
                                field={"city"}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            )}
            <Grid item container marginTop={5}>
                <Grid item xs={12}>
                    <XCenter>
                        <Button
                            onClick={() => {
                                if (referencesForm.closed === true) {
                                    dispatch(openForm());
                                } else {
                                    handleSubmit();
                                }
                            }}
                            sx={{
                                textDecoration: "underline",
                                fontSize: "1.2rem",
                            }}
                        >
                            {referencesData.current
                                ? "تعديل"
                                : "إضافة مرجع آخر"}
                        </Button>
                    </XCenter>
                </Grid>
            </Grid>
        </Box>
    );
};

export default Form;

import {
    Box,
    Button,
    FormControl,
    InputBase,
    InputLabel,
    Stack,
    Typography,
    useMediaQuery,
} from "@mui/material";
import React from "react";
import { resetRegister, setEmail } from "../../../store/slices/register";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AuthAPI from "../../../services/api/auth";
import { useTheme } from "@emotion/react";

const EmailForm = () => {
    const navigate = useNavigate();

    const dispatch = useDispatch();
    const { accountType, email, role } = useSelector((state) => state.register);

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

    const OnEmailChanged = (e) => {
        dispatch(setEmail(e.target.value));
    };

    const OnSubmit = async () => {
        if (email && accountType) {
            if (accountType == "company") {
                try {
                    await AuthAPI.register({ email, role });
                    dispatch(resetRegister());
                    navigate("/auth/login");
                } catch (e) {
                    navigate("/auth/signup");
                }
            } else if (accountType == "individual") {
                navigate("/auth/signup/registration-type");
            }
        }
    };
    return (
        <Box
            component="form"
            noValidate
            width={isSmallScreen ? "100%" : "500px"}
        >
            <Stack gap={2}>
                <FormControl variant="outlined" sx={{ width: "100%" }}>
                    <InputLabel shrink htmlFor="email">
                        <Typography variant="heading2">
                            {"البريد الالكتروني"}
                        </Typography>
                    </InputLabel>
                    <InputBase
                        variant="labeled"
                        placeholder="اكتب هنا"
                        id="email"
                        onChange={OnEmailChanged}
                        defaultValue={email}
                    />
                </FormControl>
                <FormControl variant="outlined" sx={{ width: "100%" }}>
                    <Button variant="main" onClick={() => OnSubmit()}>
                        {"التالي"}
                    </Button>
                </FormControl>
            </Stack>
        </Box>
    );
};

export default EmailForm;

import React from "react";
import Card from "../../common/Card";
import { PencilLine } from "@phosphor-icons/react";

const SkillsAndKnowledge = () => {
    return (
        <div>
            <Card
                title={"المهارات والمعرفة النظرية "}
                icon={<PencilLine size={32} />}
            ></Card>
        </div>
    );
};

export default SkillsAndKnowledge;

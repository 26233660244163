import { useTheme } from "@emotion/react";
import { Box, Stack } from "@mui/material";
import { Star } from "@phosphor-icons/react";
import React from "react";

const Review = ({ starCount, level }) => {
    const theme = useTheme();
    return (
        <Box className="block">
            <Stack direction={"row"} gap={0.25} sx={{ direction: "rtl" }}>
                {Array.from({ length: starCount }).map((v, i) => {
                    return (
                        <Star
                            style={{ color: theme.palette.primary.main }}
                            size={12}
                            weight={level >= i + 1 ? "fill" : "regular"}
                            key={i}
                        />
                    );
                })}
            </Stack>
        </Box>
    );
};

export default Review;

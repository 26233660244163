import React from "react";
import { useDispatch } from "react-redux";
import useAuthenicate from "../../hooks/useAuthenicate";
import { logoutThunk } from "../../store/slices/user";
import { Button } from "@mui/material";

const LogoutBtn = () => {
    const dispatch = useDispatch();
    const isAuthenticated = useAuthenicate();

    return (
        isAuthenticated && (
            <Button
                sx={{
                    textTransform: "capitalize",
                    fontSize: "1.2rem",
                }}
                onClick={() => dispatch(logoutThunk())}
            >
                تسجيل الخروج
            </Button>
        )
    );
};

export default LogoutBtn;

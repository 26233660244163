import * as Yup from "yup";

const validationSchema = Yup.object().shape({
    spec: Yup.object().required("التخصص مطلوب"),
    university: Yup.object().required("الجامعة مطلوبة"),
    level: Yup.object().required("المستوى التعليمي مطلوب"),
    graduated: Yup.boolean(),
    // gradDate: Yup.date().nullable().when("graduated", {
    //     is: false,
    //     then: Yup.date().required("تاريخ التخرج مطلوب").nullable(),
    //     otherwise: Yup.date().nullable(),
    // }),
});

export default validationSchema;

import React from "react";
import useForm from "./useForm";
import {
    Autocomplete,
    Box,
    Button,
    FormControl,
    Grid,
    InputLabel,
    TextField,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import { useDispatch } from "react-redux";
import Loader from "../../../common/Loader";
import { setSelectedWorkFields } from "../../../../store/slices/interests";
import { setNext, setPrev } from "../../../../store/slices/stepper";
import ValidationErrorMessage from "../../../common/ValidationErrorMessage";

const Form = () => {
    const dispatch = useDispatch();

    const theme = useTheme();
    const isMdScreen = useMediaQuery(theme.breakpoints.down("md"));

    const [interests, handleSubmit] = useForm();
    return (
        <Box component={"form"}>
            <Grid
                container
                direction={"row"}
                rowSpacing={5}
                columnSpacing={isMdScreen ? 0 : 10}
            >
                <Grid item xs={12} sm={12} md={12}>
                    <Loader
                        loading={interests.loaders.workFields.loading}
                        error={interests.loaders.workFields.error}
                    >
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel shrink htmlFor="workFields">
                                <Typography variant="heading2">
                                    {
                                        "المناصب الوظيفية المهتم بالعمل بها ( يمكنك اختيار 5 على الأكثر)"
                                    }
                                </Typography>
                            </InputLabel>
                            <Autocomplete
                                multiple
                                variant="labeled"
                                id="workFields"
                                value={interests.selectedWorkFields}
                                options={interests.workFields}
                                getOptionLabel={(option) =>
                                    option ? option.nameAr : ""
                                }
                                isOptionEqualToValue={(option, value) =>
                                    option.id === value.id
                                }
                                onChange={(e, option) =>
                                    dispatch(setSelectedWorkFields(option))
                                }
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        placeholder="اختر من هنا"
                                    />
                                )}
                            />
                            <ValidationErrorMessage
                                errors={interests.errors}
                                field={"workFields"}
                            />
                        </FormControl>
                    </Loader>
                </Grid>
                <Grid item xs={12}>
                    <Grid container justifyContent={"end"}>
                        <Grid item xs={3}>
                            <Button
                                fullWidth
                                variant="main"
                                sx={{ paddingY: 2 }}
                                onClick={() => handleSubmit()}
                            >
                                {"حفظ"}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
};

export default Form;

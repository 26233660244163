import { useTheme } from "@emotion/react";
import { Box, Grid, useMediaQuery } from "@mui/material";
import React, { useRef } from "react";
import { useSelector } from "react-redux";
import Form from "../../components/pages/register/certificates/Form";
import List from "../../components/pages/register/certificates/List";

const Certificates = () => {
    const formRef = useRef(null);

    const { mode } = useSelector((state) => state.personalInfo);

    const theme = useTheme();
    const isMdScreen = useMediaQuery(theme.breakpoints.down("md"));
    return (
        <Box className="page">
            <Grid container justifyContent={"center"}>
                <Grid
                    container
                    item
                    direction={"row"}
                    rowSpacing={5}
                    columnSpacing={isMdScreen ? 0 : 10}
                    md={9}
                    sm={12}
                    xs={12}
                >
                    <Grid item xs={12}>
                        <List scrollRef={formRef} />
                    </Grid>

                    <Grid item xs={12} ref={formRef}>
                        <Form />
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
};

export default Certificates;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import JobHistoryAPI from "../../services/api/JobHistory";
import { resetAll, setAll } from "./jobHistoryForm";

const initialState = {
    all: [],
    current: null,
    loaders: {
        all: {
            loading: false,
            error: null,
        },
        current: {
            loading: false,
            error: null,
        },
    },
};

export const fetchAllJobHistories = createAsyncThunk(
    "jobHistory/fetchAllJobHistories",
    async ({ userId }) => {
        const result = await JobHistoryAPI.getAll(userId);
        return result;
    }
);

export const fetchOneJobHistory = createAsyncThunk(
    "jobHistory/fetchOneJobHistory",
    async ({ userId, id }, { dispatch }) => {
        const result = await JobHistoryAPI.getOne(userId, id);
        dispatch(
            setAll({
                selectedWorkField: result.workField,
                selectedJobPositionLevel: result.jobPositionLevel,
                organization: result.organization,
                startDate: result.startDate,
                endDate: result.endDate,
                current: result.current,
                responsibilities: result.responsibilities,
                achievements: result.achievements,
            })
        );
        return result;
    }
);

export const createJobHistory = createAsyncThunk(
    "jobHistory/createJobHistory",
    async ({ userId, data }, { dispatch }) => {
        const result = await JobHistoryAPI.create(userId, data);
        dispatch(fetchAllJobHistories(userId));
        dispatch(resetAll());
        return result;
    }
);

export const updateJobHistory = createAsyncThunk(
    "jobHistory/updateJobHistory",
    async ({ userId, id, data }, { dispatch }) => {
        const result = await JobHistoryAPI.update(userId, id, data);
        dispatch(fetchAllJobHistories(userId));
        dispatch(resetAll());
        return result;
    }
);

export const deleteJobHistory = createAsyncThunk(
    "jobHistory/deleteJobHistory",
    async ({ userId, id }, { dispatch }) => {
        const result = await JobHistoryAPI.delete(userId, id);
        dispatch(fetchAllJobHistories(userId));
        dispatch(resetAll());
        return result;
    }
);

const jobHistoryDataSlice = createSlice({
    name: "jobHistoryData",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllJobHistories.pending, (state) => {
                state.loaders.all.loading = true;
                state.loaders.all.error = null;
            })
            .addCase(fetchAllJobHistories.fulfilled, (state, action) => {
                state.loaders.all.loading = false;
                state.all = action.payload;
            })
            .addCase(fetchAllJobHistories.rejected, (state, action) => {
                state.loaders.all.loading = true;
                state.loaders.all.error = action.message;
            })
            .addCase(fetchOneJobHistory.pending, (state) => {
                state.loaders.current.loading = true;
                state.loaders.current.error = null;
            })
            .addCase(fetchOneJobHistory.fulfilled, (state, action) => {
                state.loaders.current.loading = false;
                state.current = action.payload;
            })
            .addCase(fetchOneJobHistory.rejected, (state, action) => {
                state.loaders.current.loading = true;
                state.loaders.current.error = action.message;
            })
            .addCase(updateJobHistory.fulfilled, (state, action) => {
                state.current = null;
            })
            .addCase(deleteJobHistory.fulfilled, (state, action) => {
                state.current = null;
            });
    },
});

export const {} = jobHistoryDataSlice.actions;

export default jobHistoryDataSlice.reducer;

import {
    Avatar,
    Box,
    Chip,
    Grid,
    Stack,
    Typography,
    useTheme,
    Link as MuiLink,
    Button,
} from "@mui/material";
import React from "react";
import CheckMark from "../../../assets/svg/checkMark.svg";
import XCenter from "../../common/XCenter";
import {
    AirplaneTaxiing,
    CalendarDots,
    DeviceMobile,
    EnvelopeSimple,
    FlagBannerFold,
    Globe,
    LinkedinLogo,
    MapPin,
    Money,
} from "@phosphor-icons/react";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import {
    calcAvgHourSalary,
    convertBirthdateFormat,
} from "../../../services/utils";
import { REGISTERTATION_TYPES } from "../../../services/constants";

const Initial = ({ user }) => {
    const theme = useTheme();
    return (
        <Grid
            container
            justifyContent={"center"}
            alignItems={"top"}
            rowSpacing={4}
        >
            <Grid item xs={4} sm={4} md={4}>
                <XCenter>
                    <Box position={"relative"} width={"290px"} height={"290px"}>
                        <Avatar
                            sx={{ width: "inherit", height: "inherit" }}
                            imgProps={{
                                crossOrigin: "anonymous",
                            }}
                            src={user.iconUrl}
                        />
                        {user.role == REGISTERTATION_TYPES.FREELANCER &&
                            user.freelanceService.availableToWorkRemotely && (
                                <Chip
                                    label="متاح للعمل"
                                    sx={{
                                        position: "absolute",
                                        bottom: -30,
                                        left: "50%",
                                        transform: "translateX(-50%)",
                                        fontSize: "27px",
                                        color: theme.palette.primary.main,
                                        paddingY: 3,
                                        paddingX: 4,
                                        borderRadius: "30px",
                                        background:
                                            "linear-gradient(93.5deg, rgba(217, 217, 217, 0.6) 5.34%, rgba(178, 185, 204, 0.288) 41.46%, rgba(233, 233, 247, 0.6) 94.53%)",
                                    }}
                                />
                            )}
                    </Box>
                </XCenter>
            </Grid>
            <Grid item xs={12} sm={12} md={8}>
                <Stack>
                    <Stack direction={"row"} alignItems={"center"} gap={2}>
                        <Typography variant="heading1">
                            {`${user.personalInfo.firstNameAr} ${user.personalInfo.lastNameAr}`}
                        </Typography>
                        <Box
                            component={"img"}
                            src={CheckMark}
                            sx={{
                                objectFit: "contain",
                                objectPosition: "center",
                                height: "50px",
                            }}
                        />
                    </Stack>
                    <Typography
                        sx={{
                            color: theme.palette.primary.main,
                            fontSize: "24px",
                        }}
                    >
                        {`${user.personalPracticalExperiences.workField.nameAr}, ${user.personalPracticalExperiences.yearsOfExperience} سنوات`}
                    </Typography>
                </Stack>
                <Grid
                    container
                    direction={"row"}
                    marginTop={4}
                    alignItems={"center"}
                    spacing={2}
                >
                    <Grid item xs={12} sm={6} md={4}>
                        <Stack direction={"row"} gap={1} alignItems={"center"}>
                            <DeviceMobile
                                size={20}
                                color={theme.palette.primary.main}
                            />
                            <Typography
                                sx={{
                                    color: theme.palette.primary.main,
                                    fontSize: "20px",
                                }}
                            >
                                {`${user.contactInformation.countryCode} ${user.contactInformation.phoneNumber}`}
                            </Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Stack direction={"row"} gap={1} alignItems={"center"}>
                            <EnvelopeSimple
                                size={20}
                                color={theme.palette.primary.main}
                            />
                            <Typography
                                sx={{
                                    color: theme.palette.primary.main,
                                    fontSize: "20px",
                                }}
                            >
                                {`${user.email}`}
                            </Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Stack direction={"row"} gap={2}>
                            {user.contactInformation.websiteUrl && (
                                <MuiLink
                                    component={Link}
                                    to={`${user.contactInformation.websiteUrl}`}
                                    sx={{ color: "#006CFE" }}
                                    target="_blank"
                                >
                                    <Globe size={20} weight="fill" />
                                </MuiLink>
                            )}

                            <MuiLink
                                component={Link}
                                to={`${user.contactInformation.linkedInUrl}`}
                                sx={{ color: "#006CFE" }}
                                target="_blank"
                            >
                                <LinkedinLogo size={20} weight="fill" />
                            </MuiLink>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Stack direction={"row"} gap={1} alignItems={"center"}>
                            <FlagBannerFold
                                size={20}
                                color={theme.palette.primary.main}
                            />
                            <Typography
                                sx={{
                                    color: theme.palette.primary.main,
                                    fontSize: "20px",
                                }}
                            >
                                {`${user.personalInfo.nationality1.nameAr} ${
                                    user.personalInfo.nationality2
                                        ? ", " +
                                          user.personalInfo.nationality2.nameAr
                                        : ""
                                }`}
                            </Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Stack direction={"row"} gap={1} alignItems={"center"}>
                            <MapPin
                                size={20}
                                color={theme.palette.primary.main}
                            />
                            <Typography
                                sx={{
                                    color: theme.palette.primary.main,
                                    fontSize: "20px",
                                }}
                            >
                                {`${user.contactInformation.country.nameAr}, ${user.contactInformation.city.nameAr}`}
                            </Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Stack direction={"row"} gap={1} alignItems={"center"}>
                            <CalendarDots
                                size={20}
                                color={theme.palette.primary.main}
                            />
                            <Typography
                                sx={{
                                    color: theme.palette.primary.main,
                                    fontSize: "20px",
                                }}
                            >
                                {convertBirthdateFormat(
                                    user.personalInfo.birthday
                                )}
                            </Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Stack direction={"row"} gap={1} alignItems={"center"}>
                            <AirplaneTaxiing
                                size={20}
                                color={theme.palette.primary.main}
                            />
                            <Typography
                                sx={{
                                    color: theme.palette.primary.main,
                                    fontSize: "20px",
                                }}
                            >
                                {user.personalPracticalExperiences
                                    .availabilityToTravel
                                    ? "لديه قابلية للسفر"
                                    : "ليس لديه القابلية للسفر"}
                            </Typography>
                        </Stack>
                    </Grid>
                    {user.role == REGISTERTATION_TYPES.TRAINER && (
                        <Grid item xs={12} sm={6} md={4}>
                            <Stack
                                direction={"row"}
                                gap={1}
                                alignItems={"center"}
                            >
                                <Money
                                    size={20}
                                    color={theme.palette.primary.main}
                                />
                                <Typography
                                    sx={{
                                        color: theme.palette.primary.main,
                                        fontSize: "20px",
                                    }}
                                >
                                    {`${calcAvgHourSalary(
                                        user.trainingAndCoaching.map(
                                            (item) => item.averageSalaryPerHour
                                        )
                                    )}$ الأجر في الساعة`}
                                </Typography>
                            </Stack>
                        </Grid>
                    )}
                </Grid>
                <Stack
                    direction={"row"}
                    justifyContent={"end"}
                    gap={2}
                    marginTop={2}
                >
                    <Button
                        variant="main"
                        sx={{
                            background:
                                "linear-gradient(180deg, #FAB458 0%, #FFA227 100%)",
                        }}
                    >
                        {"عرض السيرة الذاتية"}
                    </Button>
                    <Button variant="main">{"معرفة اهتمامه بالوظيفة "}</Button>
                </Stack>
            </Grid>
        </Grid>
    );
};

export default Initial;

import { Box, Grid, Stack, Typography, Link as MuiLink } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
    return (
        <Box
            component={"footer"}
            sx={{
                paddingY: "1.5rem",
                paddingX: "2rem",
                boxShadow: "0px -3px 20px 0px #00000012;",
            }}
        >
            <Grid container justifyContent={"space-between"}>
                <Grid item xs={"auto"}>
                    <Stack direction={"row"} gap={0.5}>
                        <Typography>{"طور من قبل"}</Typography>
                        <MuiLink component={Link} to="/terms-conditions">
                            {"نادي الخريجين"}
                        </MuiLink>
                    </Stack>
                </Grid>
                <Grid item xs={"auto"}>
                    <Stack direction={"row"} gap={1}>
                        <MuiLink component={Link} to="/privacy-policy">
                            {"سياسة الخصوصية"}
                        </MuiLink>
                        <MuiLink component={Link} to="/terms-conditions">
                            {"شروط الاستخدام"}
                        </MuiLink>
                    </Stack>
                </Grid>
            </Grid>
        </Box>
    );
};

export default Footer;

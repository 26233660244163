import { useTheme } from "@emotion/react";
import {
    Autocomplete,
    Box,
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    Grid,
    InputBase,
    InputLabel,
    Stack,
    TextField,
    Typography,
    styled,
    useMediaQuery,
    Link as MuiLink,
} from "@mui/material";
import React from "react";
import useForm from "./useForm";
import { useDispatch } from "react-redux";
import Loader from "../../../common/Loader";
import {
    setAvailabilityToTravel,
    setCvFile,
    setExperienceYears,
    setSelectedSkills,
    setSelectedTheoreticalKnowledge,
    setSelectedWorkField,
    setWhenCanStart,
    uploadCV,
} from "../../../../store/slices/experiencesForm";
import ValidationErrorMessage from "../../../common/ValidationErrorMessage";
import { DatePicker } from "@mui/x-date-pickers";
import { DatePickerStyle } from "../../../../services/theme";
import { setNext, setPrev } from "../../../../store/slices/stepper";
import { CloudArrowUp, PencilSimple, Trash } from "@phosphor-icons/react";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import { getFileName } from "../../../../services/utils";
import { default as JobHistoryList } from "../practical-experience/job-history/List";
const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
});

const Form = () => {
    const dispatch = useDispatch();
    const [experiencesForm, handleSubmit] = useForm();

    const theme = useTheme();
    const isMdScreen = useMediaQuery(theme.breakpoints.down("md"));

    return (
        <Box component={"form"}>
            <Grid
                container
                direction={"row"}
                rowSpacing={5}
                columnSpacing={isMdScreen ? 0 : 10}
            >
                <Grid item xs={12} sm={12} md={6}>
                    <Loader
                        loading={experiencesForm.loaders.workFields.loading}
                        error={experiencesForm.loaders.workFields.error}
                    >
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel shrink htmlFor="workField">
                                <Typography variant="heading2">
                                    {"الخبرات العملية *"}
                                </Typography>
                            </InputLabel>
                            <Autocomplete
                                variant="labeled"
                                id="workField"
                                value={experiencesForm.selectedWorkField}
                                options={experiencesForm.workFields}
                                getOptionLabel={(option) =>
                                    option ? option.nameAr : ""
                                }
                                isOptionEqualToValue={(option, value) =>
                                    option.id === value.id
                                }
                                onChange={(e, option) =>
                                    dispatch(setSelectedWorkField(option))
                                }
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        placeholder="اختر من هنا"
                                    />
                                )}
                            />
                            <ValidationErrorMessage
                                errors={experiencesForm.errors}
                                field={"workField"}
                            />
                        </FormControl>
                    </Loader>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    <FormControl variant="outlined" fullWidth>
                        <InputLabel shrink htmlFor="experienceYears">
                            <Typography variant="heading2">
                                {"سنوات الخبرة *"}
                            </Typography>
                        </InputLabel>
                        <Stack direction={"row"} gap={1} marginTop={6}>
                            <Button
                                variant="main"
                                sx={{
                                    borderRadius: 0,
                                }}
                                onClick={() =>
                                    dispatch(
                                        setExperienceYears({
                                            operation: "inc",
                                        })
                                    )
                                }
                            >
                                <Typography sx={{ transform: "scale(2)" }}>
                                    +
                                </Typography>
                            </Button>
                            <TextField
                                id="experienceYears"
                                value={experiencesForm.experienceYears}
                                onChange={(e) =>
                                    dispatch(
                                        setExperienceYears({
                                            value: e.target.value,
                                        })
                                    )
                                }
                                sx={{
                                    width: "50px",
                                    "& .MuiInputBase-input": {
                                        textAlign: "center",
                                    },
                                }}
                            />
                            <Button
                                variant="main"
                                sx={{
                                    borderRadius: 0,
                                }}
                                onClick={() =>
                                    dispatch(
                                        setExperienceYears({
                                            operation: "decr",
                                        })
                                    )
                                }
                            >
                                <Typography sx={{ transform: "scale(2)" }}>
                                    -
                                </Typography>
                            </Button>
                        </Stack>
                        <ValidationErrorMessage
                            errors={experiencesForm.errors}
                            field={"experienceYears"}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                    <Loader
                        loading={experiencesForm.loaders.skills.loading}
                        error={experiencesForm.loaders.skills.error}
                    >
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel shrink htmlFor="skills">
                                <Typography variant="heading2">
                                    {"المهارات *"}
                                </Typography>
                            </InputLabel>
                            <Autocomplete
                                variant="labeled"
                                id="skills"
                                multiple={true}
                                value={experiencesForm.selectedSkills}
                                options={experiencesForm.skills}
                                getOptionLabel={(option) =>
                                    option ? option.nameAr : ""
                                }
                                isOptionEqualToValue={(option, value) =>
                                    option.id === value.id
                                }
                                onChange={(e, option) =>
                                    dispatch(setSelectedSkills(option))
                                }
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        placeholder="اختر من هنا"
                                    />
                                )}
                            />
                            <ValidationErrorMessage
                                errors={experiencesForm.errors}
                                field={"workField"}
                            />
                        </FormControl>
                    </Loader>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                    <Loader
                        loading={
                            experiencesForm.loaders.theoreticalKnowledge.loading
                        }
                        error={
                            experiencesForm.loaders.theoreticalKnowledge.error
                        }
                    >
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel shrink htmlFor="theoreticalKnowledge">
                                <Typography variant="heading2">
                                    {"المعرفة النظرية *"}
                                </Typography>
                            </InputLabel>
                            <Autocomplete
                                variant="labeled"
                                id="theoreticalKnowledge"
                                multiple={true}
                                value={
                                    experiencesForm.selectedTheoreticalKnowledge
                                }
                                options={experiencesForm.theoreticalKnowledge}
                                getOptionLabel={(option) =>
                                    option ? option.nameAr : ""
                                }
                                isOptionEqualToValue={(option, value) =>
                                    option.id === value.id
                                }
                                onChange={(e, option) =>
                                    dispatch(
                                        setSelectedTheoreticalKnowledge(option)
                                    )
                                }
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        placeholder="اختر من هنا"
                                    />
                                )}
                            />
                            <ValidationErrorMessage
                                errors={experiencesForm.errors}
                                field={"workField"}
                            />
                        </FormControl>
                    </Loader>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                    <FormControl variant="outlined" fullWidth>
                        <InputLabel shrink htmlFor="cvFile">
                            <Typography variant="heading2">
                                {"السيرة الذاتية *"}
                            </Typography>
                        </InputLabel>
                        <Grid container>
                            <Grid item xs={12}>
                                <Button
                                    component={"label"}
                                    role={undefined}
                                    variant="outlined"
                                    tabIndex={-1}
                                    sx={{ marginTop: 6 }}
                                    fullWidth
                                    endIcon={<PencilSimple />}
                                >
                                    <Typography>{"اختر الملف"}</Typography>
                                    <VisuallyHiddenInput
                                        type="file"
                                        id="cvFile"
                                        onChange={(e) => {
                                            console.log(e.target.files[0]);
                                            dispatch(
                                                setCvFile(e.target.files[0])
                                            );
                                        }}
                                    />
                                </Button>
                            </Grid>
                            {experiencesForm.cv && (
                                <Grid item xs={12}>
                                    <MuiLink
                                        component={Link}
                                        download
                                        to={`${process.env.REACT_APP_BACKEND_BASE}/${experiencesForm.cv}`}
                                        target="_blank"
                                    >
                                        {getFileName(experiencesForm.cv)}
                                    </MuiLink>
                                </Grid>
                            )}
                            {experiencesForm.cvFile && (
                                <Grid item xs={12}>
                                    <Typography>
                                        {experiencesForm.cvFile.name}
                                    </Typography>
                                </Grid>
                            )}
                            {experiencesForm.cvFile && (
                                <>
                                    <Grid item xs={6}>
                                        <Button
                                            variant="main"
                                            fullWidth
                                            sx={{ marginTop: 6 }}
                                            endIcon={<CloudArrowUp />}
                                            onClick={() =>
                                                dispatch(
                                                    uploadCV({
                                                        file: experiencesForm.cvFile,
                                                    })
                                                )
                                            }
                                        >
                                            {"ارفع الملف"}
                                        </Button>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button
                                            variant="danger"
                                            fullWidth
                                            sx={{
                                                marginTop: 6,
                                            }}
                                            endIcon={<Trash />}
                                            onClick={() =>
                                                dispatch(setCvFile(null))
                                            }
                                        >
                                            {"امسح الملف"}
                                        </Button>
                                    </Grid>
                                </>
                            )}
                        </Grid>
                        <ValidationErrorMessage
                            errors={experiencesForm.errors}
                            field={"cvFile"}
                        />
                        <ValidationErrorMessage
                            errors={experiencesForm.errors}
                            field={"cv"}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                    <FormControl variant="outlined" fullWidth>
                        <InputLabel shrink htmlFor="whenCanStart">
                            <Typography variant="heading2">
                                {
                                    "متى يمكنك الالتحاق في حال عرضت عليك فرصة جديدة؟"
                                }
                            </Typography>
                        </InputLabel>
                        <DatePicker
                            id="whenCanStart"
                            value={dayjs(experiencesForm.whenCanStart)}
                            onChange={(value) =>
                                dispatch(setWhenCanStart(value))
                            }
                            sx={{ ...DatePickerStyle }}
                        />
                        <ValidationErrorMessage
                            errors={experiencesForm.errors}
                            field={"workField"}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl variant="outlined" fullWidth>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    value={experiencesForm.availabilityToTravel}
                                    checked={
                                        experiencesForm.availabilityToTravel
                                    }
                                    onChange={(e) =>
                                        dispatch(
                                            setAvailabilityToTravel(
                                                e.target.checked
                                            )
                                        )
                                    }
                                />
                            }
                            label={
                                <Typography variant="heading2">
                                    {"قابلية السفر"}
                                </Typography>
                            }
                        />
                        <ValidationErrorMessage
                            errors={experiencesForm.errors}
                            field={"availabilityToTravel"}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <JobHistoryList />
                </Grid>
                <Grid item xs={12}>
                    <Grid container justifyContent={"space-between"}>
                        <Grid item xs={3}>
                            <Button
                                fullWidth
                                variant="outlined"
                                sx={{ paddingY: 2 }}
                                onClick={() => dispatch(setPrev())}
                            >
                                {"السابق"}
                            </Button>
                        </Grid>
                        <Grid item xs={3}>
                            <Button
                                fullWidth
                                variant="main"
                                sx={{ paddingY: 2 }}
                                onClick={() => {
                                    handleSubmit();
                                    dispatch(setNext());
                                }}
                            >
                                {"التالي"}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
};

export default Form;

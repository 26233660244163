import {
    Box,
    Button,
    MobileStepper,
    Step,
    StepLabel,
    Stepper,
    Tab,
    Tabs,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { prepareStepPath } from "../../../services/utils";
import { REGISTER_STEPS_LABELS } from "../../../services/constants";
import { setStepper } from "../../../store/slices/stepper";
import { useNavigate } from "react-router-dom";
import { CaretLeft, CaretRight } from "@phosphor-icons/react";

const Progressor = ({ activeStep }) => {
    const navigate = useNavigate();

    const theme = useTheme();
    const isSmScreen = useMediaQuery(theme.breakpoints.down("sm"));

    const dispatch = useDispatch();
    const { role } = useSelector((state) => state.user);
    const { current, steps } = useSelector((state) => state.stepper);

    useEffect(() => {
        if (steps.length == 0) dispatch(setStepper(role));
    }, [role]);

    useEffect(() => {
        const step = steps[current];
        const path = prepareStepPath(step);
        navigate(path);
    }, [current]);

    return (
        <Box>
            {!isSmScreen && (
                <Stepper
                    activeStep={current}
                    alternativeLabel
                    orientation={isSmScreen ? "vertical" : "horizontal"}
                >
                    {steps.map((step) => (
                        <Step key={step}>
                            <StepLabel>{REGISTER_STEPS_LABELS[step]}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
            )}
            {isSmScreen && (
                <Tabs
                    value={current}
                    variant="scrollable"
                    scrollButtons
                    allowScrollButtonsMobile
                    aria-label="scrollable force tabs example"
                >
                    {steps.map((step) => (
                        <Tab label={REGISTER_STEPS_LABELS[step]} />
                    ))}
                </Tabs>
            )}
        </Box>
    );
};

export default Progressor;

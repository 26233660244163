import { Box, Grid, Stack, useMediaQuery } from "@mui/material";
import React, { useRef } from "react";
import Progressor from "../../components/pages/register/Progressor";
import Form from "../../components/pages/register/education-level/Form";
import List from "../../components/pages/register/education-level/List";
import { useTheme } from "@emotion/react";
import { PROFILE_MODES } from "../../services/constants";
import { useSelector } from "react-redux";
const EducationLevel = () => {
    const formRef = useRef(null);

    const { mode } = useSelector((state) => state.personalInfo);

    const theme = useTheme();
    const isMdScreen = useMediaQuery(theme.breakpoints.down("md"));
    return (
        <Box className="page">
            <Grid container justifyContent={"center"}>
                <Grid
                    container
                    item
                    direction={"row"}
                    rowSpacing={5}
                    columnSpacing={isMdScreen ? 0 : 10}
                    md={9}
                    sm={12}
                    xs={12}
                >
                    {mode == PROFILE_MODES.UPDATE && (
                        <Grid item xs={12}>
                            <List scrollRef={formRef} />
                        </Grid>
                    )}

                    <Grid item xs={12} ref={formRef}>
                        <Form />
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
};

export default EducationLevel;

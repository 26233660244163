import { Box, Stack } from "@mui/material";
import React, { useEffect } from "react";
import Progressor from "../../components/pages/register/Progressor";
import { Outlet, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setMode } from "../../store/slices/personalInfo";
import { PROFILE_MODES } from "../../services/constants";
import UserAPI from "../../services/api/User";
import { isRegistrationCompleted } from "../../services/utils";

const Index = () => {
    const navigate = useNavigate();

    const dispatch = useDispatch();

    const userId = useSelector((state) => state.user.id);
    const role = useSelector((state) => state.user.role);

    useEffect(() => {
        dispatch(setMode(PROFILE_MODES.REGISTER));
    }, []);

    useEffect(() => {
        const fetchUser = async () => {
            const user = await UserAPI.getOne(userId);
            const completed = isRegistrationCompleted(role, user);
            console.log(user);
            console.log(completed);
            if (completed) {
                navigate("/");
            }
        };

        fetchUser();
    }, [userId]);

    return (
        <Box className="page">
            <Stack gap={4} sx={{ "& > *": { width: "100%" } }}>
                <Progressor />
                <Outlet />
            </Stack>
        </Box>
    );
};

export default Index;

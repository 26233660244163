import API from "./Api";

export default class InterestsAPI {
    static async get(userId) {
        const result = await API.get(`/personal-interests?user=${userId}`);
        return result;
    }

    static async create(userId, data) {
        const result = await API.post(
            `/personal-interests?user=${userId}`,
            data
        );
        return result;
    }

    static async update(userId, data) {
        const result = await API.put(
            `/personal-interests?user=${userId}`,
            data
        );
        return result;
    }
}

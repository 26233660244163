import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    createCertificate,
    fetchAllCertificates,
    updateCertificate,
} from "../../../../store/slices/certificatesData";
import { setErrors } from "../../../../store/slices/certificatesForm";
import { createEducation } from "../../../../store/slices/educationLevelData";

const useForm = () => {
    const dispatch = useDispatch();

    
    const certificatesForm = useSelector((state) => state.certificatesForm);
    const certificatesData = useSelector((state) => state.certificatesData);

    useEffect(() => {
        dispatch(fetchAllCertificates());
    }, []);

    const handleSubmit = async () => {
        // try {
        //     await validationSchema.validate(
        //         {
        //             spec: educationLevelForm.spec,
        //             university: educationLevelForm.university,
        //             level: educationLevelForm.level,
        //             graduated: educationLevelForm.graduated,
        //             gradDate: educationLevelForm.gradDate,
        //         },
        //         { abortEarly: false }
        //     );
        // } catch (error) {
        //     const errors = collectAllValidationErrors(error);
        //     dispatch(setErrors(errors));
        //     window.scrollTo(0, 0);
        //     toast.warn("اصلح الحقول المطلوبة");
        //     return;
        // }

        const data = {
            name: certificatesForm.name,
            certificateInstitution: certificatesForm.certificateInstitution,
            releaseDate: certificatesForm.releaseDate,
            expirationDate: certificatesForm.expirationDate,
            certificateUrl: certificatesForm.certificateUrl,
        };

        if (certificatesData.current) {
            dispatch(
                updateCertificate({
                    id: certificatesData.current.id,
                    data,
                })
            );
        } else {
            dispatch(
                createCertificate({
                    data,
                })
            );
        }

        dispatch(setErrors(null));
        window.scrollTo(0, 0);
    };

    return [certificatesForm, certificatesData, handleSubmit];
};

export default useForm;

// src/slices/birthdateSlice.js

import { createSlice } from "@reduxjs/toolkit";
import {
    ENTERPRISE_STEPS,
    FREELANCER_STEPS,
    JOB_SEEKER_STEPS,
    REGISTERTATION_TYPES,
    TRAINER_STEPS,
} from "../../services/constants";

const initialState = {
    current: -1,
    steps: [],
};

const birthdateSlice = createSlice({
    name: "stepper",
    initialState,
    reducers: {
        setStepper: (state, action) => {
            switch (action.payload) {
                case REGISTERTATION_TYPES.JOB_SEEKER:
                    state.steps = JOB_SEEKER_STEPS;
                    break;
                case REGISTERTATION_TYPES.FREELANCER:
                    state.steps = FREELANCER_STEPS;
                    break;
                case REGISTERTATION_TYPES.TRAINER:
                    state.steps = TRAINER_STEPS;
                    break;
                case REGISTERTATION_TYPES.ENTERPRISE:
                    state.steps = ENTERPRISE_STEPS;
                    break;
                default:
                    state.steps = [];
                    break;
            }
            state.current = 0;
        },
        setNext: (state, action) => {
            const nextStep = state.current + 1;
            if (nextStep <= state.steps.length) {
                state.current = nextStep;
            } else {
                state.current = -1;
            }
            window.scrollTo(0, 0);
        },

        setPrev: (state, action) => {
            const prevStep = state.current - 1;
            if (prevStep >= 0) {
                state.current = prevStep;
            }
            window.scrollTo(0, 0);
        },
    },
});

export const { setStepper, setNext, setPrev } = birthdateSlice.actions;

export default birthdateSlice.reducer;

import React from "react";
import { Box, Button, Link as MuiLink, Stack, Typography } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
const MenuLink = ({ route, title, onClick, endIcon, image }) => {
    const navigate = useNavigate();

    return (
        <Button
            onClick={(e) => {
                onClick ? onClick(e) : navigate(route);
            }}
            endIcon={endIcon ? endIcon : <></>}
            sx={{}}
        >
            <Stack direction={"row"} gap={2} alignItems={"center"}>
                {image && (
                    <Box
                        component={"img"}
                        src={image}
                        alt="نادي الخريجين"
                        sx={{
                            objectFit: "contain",
                            objectPosition: "center",
                            height: "4rem",
                        }}
                    />
                )}
                <Typography
                    sx={{
                        fontWeight: 600,
                        fontSize: "20px",
                        lineHeight: "37.48px",
                    }}
                >
                    {title}
                </Typography>
            </Stack>
        </Button>
    );
};

export default MenuLink;

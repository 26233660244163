import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
    BirthdateGenerator,
    OptionsHelper,
    formatDate,
} from "../../services/utils";
import { NationalityAPI } from "../../services/api/model";
import PersonalInfoAPI from "../../services/api/personalInfo";
import { PROFILE_MODES } from "../../services/constants";

const initialState = {
    // form values
    id: null,
    firstNameAr: null,
    lastNameAr: null,
    firstNameEn: null,
    lastNameEn: null,
    gender: null,
    year: null,
    month: null,
    day: null,
    nationality1: null,
    nationality2: null,
    mode: null,
    errors: null,
    loaders: {
        nationality: {
            loading: false,
            error: null,
        },
        personalInfo: {
            loading: false,
            error: null,
        },
    },
    // available values
    availableYears: BirthdateGenerator.generateYears(),
    availableMonths: BirthdateGenerator.generateMonths(),
    availableDays: [],
    availableNationalities: [],
};

export const fetchPersonalInfo = createAsyncThunk(
    "personalInfo/fetchPersonalInfo",
    async ({ userId }) => {
        const result = await PersonalInfoAPI.getPersonalInfo(userId);
        return result;
    }
);

export const createPersonalInfo = createAsyncThunk(
    "personalInfo/createPersonalInfo",
    async ({ userId, data }) => {
        const result = await PersonalInfoAPI.createPersonalInfo(userId, data);
        return result;
    }
);

export const updatePersonalInfo = createAsyncThunk(
    "personalInfo/updatePersonalInfo",
    async ({ userId, id, data }) => {
        const result = await PersonalInfoAPI.updatePersonalInfo(
            userId,
            id,
            data
        );
        return result;
    }
);

export const fetchAllNationalities = createAsyncThunk(
    "personalInfo/fetchAllNationalities",
    async () => {
        const result = await NationalityAPI.getAll();
        return result;
    }
);

const slice = createSlice({
    name: "personalInfo",
    initialState,
    reducers: {
        setYear: (state, action) => {
            state.year = action.payload;

            const maxDays = BirthdateGenerator.getMaxDays(
                state.year,
                state.month
            );
            if (state.day > maxDays) {
                state.day = "";
            }
        },
        setMonth: (state, action) => {
            state.month = action.payload;

            const maxDays = BirthdateGenerator.getMaxDays(
                state.year.value,
                state.month.value
            );
            if (state.day > maxDays) {
                state.day = "";
            }

            state.availableDays = BirthdateGenerator.generateDays(
                state.year.value,
                state.month.value
            );
        },
        setDay: (state, action) => {
            state.day = action.payload;
        },
        setFirstNameAr: (state, action) => {
            state.firstNameAr = action.payload;
        },
        setLastNameAr: (state, action) => {
            state.lastNameAr = action.payload;
        },
        setFirstNameEn: (state, action) => {
            state.firstNameEn = action.payload;
        },
        setLastNameEn: (state, action) => {
            state.lastNameEn = action.payload;
        },
        setGender: (state, action) => {
            state.gender = action.payload;
        },
        setNationality1: (state, action) => {
            state.nationality1 = action.payload;
        },
        setNationality2: (state, action) => {
            state.nationality2 = action.payload;
        },
        setMode: (state, action) => {
            if (PROFILE_MODES[action.payload]) {
                state.mode = action.payload;
            }
        },
        setAll: (state, action) => {
            const { year, month, day } = formatDate(action.payload.birthdate);
            setYear(year);
            setMonth(month);
            setDay(day);
            setFirstNameAr(action.payload.firstNameAr);
            setLastNameAr(action.payload.lastNameAr);
            setFirstNameEn(action.payload.firstNameEn);
            setLastNameEn(action.payload.lastNameEn);
            setGender(action.payload.gender);
            setNationality1(action.payload.nationality1);
            setNationality2(action.payload.nationality2);
        },
        resetAll: (state, action) => {
            setYear("");
            setMonth("");
            setDay("");
            setFirstNameAr(null);
            setLastNameAr(null);
            setFirstNameEn(null);
            setLastNameEn(null);
            setGender(null);
            setNationality1(null);
            setNationality2(null);
        },
        setErrors: (state, action) => {
            state.errors = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllNationalities.pending, (state) => {
                state.loaders.nationality.loading = true;
                state.loaders.nationality.error = null;
            })
            .addCase(fetchAllNationalities.fulfilled, (state, action) => {
                state.loaders.nationality.loading = false;
                console.log(action);
                state.availableNationalities = action.payload;
            })
            .addCase(fetchAllNationalities.rejected, (state, action) => {
                state.loaders.nationality.loading = true;
                state.loaders.nationality.error = action.message;
            })
            .addCase(fetchPersonalInfo.pending, (state) => {
                state.loaders.personalInfo.loading = true;
                state.loaders.personalInfo.error = null;
            })
            .addCase(fetchPersonalInfo.fulfilled, (state, action) => {
                if (
                    action.payload &&
                    Array.isArray(action.payload) &&
                    action.payload.length > 0
                ) {
                    action.payload = action.payload[0];
                    const { year, month, day } = formatDate(
                        action.payload.birthday
                    );
                    console.log({ year, month, day });
                    state.year = OptionsHelper.getOptionObj(year);
                    state.month = OptionsHelper.getOptionObj(month);
                    state.availableDays = BirthdateGenerator.generateDays(
                        year,
                        month
                    );
                    state.day = OptionsHelper.getOptionObj(day);
                    state.id = action.payload.id;
                    state.firstNameAr = action.payload.firstNameAr;
                    state.lastNameAr = action.payload.lastNameAr;
                    state.firstNameEn = action.payload.firstNameEn;
                    state.lastNameEn = action.payload.lastNameEn;
                    state.gender = OptionsHelper.getGenderOptionObj(
                        action.payload.gender
                    );
                    state.nationality1 = action.payload.nationality1;
                    state.nationality2 = action.payload.nationality2;
                }
                state.loaders.personalInfo.loading = false;
            });
    },
});

export const {
    setFirstNameAr,
    setLastNameAr,
    setFirstNameEn,
    setLastNameEn,
    setGender,
    setYear,
    setMonth,
    setDay,
    setNationality1,
    setNationality2,
    setMode,
    setErrors,
    setAll,
    resetAll,
} = slice.actions;

export default slice.reducer;

import { combineReducers, configureStore } from "@reduxjs/toolkit";
import {
    persistStore,
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import register from "./slices/register";
import login from "./slices/login";
import user from "./slices/user";
import profile from "./slices/profile";
import forgetPassword from "./slices/forgetPassword";
import birthdate from "./slices/birthdate";
import personalInfo from "./slices/personalInfo";
import languageExperiencesForm from "./slices/languageExperiencesForm";
import languageExperiencesData from "./slices/languageExperiencesData";
import stepper from "./slices/stepper";
import educationLevelForm from "./slices/educationLevelForm";
import educationLevelData from "./slices/educationLevelData";
import experiencesForm from "./slices/experiencesForm";
import jobHistoryForm from "./slices/jobHistoryForm";
import jobHistoryData from "./slices/jobHistoryData";
import list from "./slices/list";
import profileStepper from "./slices/profileStepper";
import contactInfo from "./slices/contactInfo";
import { resetState } from "./actions";
import interests from "./slices/interests";
import certificatesForm from "./slices/certificatesForm";
import certificatesData from "./slices/certificatesData";
import referencesForm from "./slices/referencesForm";
import referencesData from "./slices/referencesData";
import images from "./slices/images";
import coachingForm from "./slices/coachingForm";
import coachingData from "./slices/coachingData";
import servicesForm from "./slices/servicesForm";
import verifyAccountForm from "./slices/verifyAccountForm";

const appReducer = combineReducers({
    register,
    login,
    user,
    profile,
    forgetPassword,
    birthdate,
    personalInfo,
    contactInfo,
    languageExperiencesForm,
    languageExperiencesData,
    educationLevelForm,
    educationLevelData,
    stepper,
    profileStepper,
    experiencesForm,
    jobHistoryForm,
    jobHistoryData,
    list,
    interests,
    certificatesForm,
    certificatesData,
    referencesForm,
    referencesData,
    coachingForm,
    coachingData,
    servicesForm,
    verifyAccountForm,
    // images,
});

const rootReducer = (state, action) => {
    if (action.type === resetState.type) {
        state = undefined;
    }
    return appReducer(state, action);
};

const persistConfig = {
    key: "root",
    storage,
    blacklist: [
        "login",
        "forgetPassword",
        "birthdate",
        "personalInfo",
        "contactInfo",
        "languageExperiencesForm",
        "languageExperiencesData",
        "experiencesForm",
        "list",
        "profileStepper",
        "interests",
        "verifyAccountForm",
    ],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
});

export const persistor = persistStore(store);
export default store;

import API from "./Api";

export default class UserAPI {
    static async getOne(userId) {
        const result = await API.get(`/users/${userId}`);
        return result;
    }

    static async uploadImage(path, type) {
        const result = await API.post(`/users/update-profile-images`, {
            path,
            type,
        });
        return result;
    }
}

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { toast } from "react-toastify";
import { logout } from "../../store/slices/user";
import { REGISTERTATION_TYPES } from "../../services/constants";

const ProtectedRouteWrapper = ({ children }) => {
    const dispatch = useDispatch();
    const { accessToken, isVerified, role } = useSelector(
        (state) => state.user
    );

    if (role == REGISTERTATION_TYPES.ADMIN) {
        return <Navigate to="/admin" />;
    }

    if (!accessToken) {
        return <Navigate to="/auth/login" />;
    } else {
        // if (!isVerified) {
        //     return <Navigate to="/register/personal-info" />;
        // } else {
        return <>{children}</>;
        // }
    }
};

export default ProtectedRouteWrapper;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AuthAPI from "../../services/api/auth";
import { resetState } from "../actions";
import UserAPI from "../../services/api/User";
import UploadAPI from "../../services/api/Upload";
import { getFileName } from "../../services/utils";

const initialState = {
    accessToken: null,
    createdAt: null,
    deletedAt: null,
    email: null,
    graduationImage: null,
    iconUrl: null,
    id: null,
    idImage: null,
    isVerified: false,
    role: null,
    status: null,
    updatedAt: null,
    loaders: {
        profile_image: {
            loading: false,
            error: null,
        },
        id_image: {
            loading: false,
            error: null,
        },
        graduation_image: {
            loading: false,
            error: null,
        },
    },
};

export const fetchUser = createAsyncThunk("user/fetchUser", async ({ id }) => {
    const result = await UserAPI.getOne(id);
    return result;
});

export const uploadProfileImage = createAsyncThunk(
    "user/uploadProfileImage",
    async ({ file }, { dispatch, getState }) => {
        const result = await UploadAPI.uploadFile(file, "profile_icon");
        await UserAPI.uploadImage(result.url, "PROFILE_ICON");
        const userId = getState().user.id;
        dispatch(fetchUser({ id: userId }));
        return result;
    }
);

export const uploadProfileIDImage = createAsyncThunk(
    "user/uploadProfileIDImage",
    async ({ url }, { dispatch, getState }) => {
        await UserAPI.uploadImage(url, "ID");
        const userId = getState().user.id;
        dispatch(fetchUser({ id: userId }));
    }
);
export const uploadProfileGraduationImage = createAsyncThunk(
    "user/uploadProfileGraduationImage",
    async ({ url }, { dispatch, getState }) => {
        await UserAPI.uploadImage(url, "GRADUATION");
        const userId = getState().user.id;
        dispatch(fetchUser({ id: userId }));
    }
);

export const deleteProfileImage = createAsyncThunk(
    "user/deleteProfileImage",
    async ({ file = "" }, { dispatch, getState }) => {
        const result = await UploadAPI.deleteFile(
            getFileName(file),
            "profile_icon"
        );
        await UserAPI.uploadImage(null, "PROFILE_ICON");
        const userId = getState().user.id;
        dispatch(fetchUser({ id: userId }));
        return result;
    }
);

export const loginThunk = createAsyncThunk(
    "user/loginThunk",
    async ({ email, password }, { dispatch }) => {
        const result = await AuthAPI.login({ email, password });
        const { accessToken, user } = result;
        dispatch(login({ accessToken, ...user }));
    }
);

export const logoutThunk = createAsyncThunk(
    "user/logoutThunk",
    (payload, { dispatch }) => {
        dispatch(logout());
        dispatch(resetState());
    }
);

const slice = createSlice({
    name: "user",
    initialState,
    reducers: {
        login: (state, action) => {
            state.accessToken = action.payload.accessToken;
            state.createdAt = action.payload.createdAt;
            state.deletedAt = action.payload.deletedAt;
            state.email = action.payload.email;
            state.graduationImage = action.payload.graduationImage;
            state.iconUrl = action.payload.iconUrl;
            state.id = action.payload.id;
            state.idImage = action.payload.idImage;
            state.isVerified = action.payload.isVerified;
            state.role = action.payload.role;
            state.status = action.payload.status;
            state.updatedAt = action.payload.updatedAt;
        },
        logout: (state) => {
            state.accessToken = null;
            state.createdAt = null;
            state.deletedAt = null;
            state.email = null;
            state.graduationImage = null;
            state.iconUrl = null;
            state.id = null;
            state.idImage = null;
            state.isVerified = false;
            state.role = null;
            state.status = null;
            state.updatedAt = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchUser.fulfilled, (state, action) => {
                state.createdAt = action.payload.createdAt;
                state.deletedAt = action.payload.deletedAt;
                state.email = action.payload.email;
                state.graduationImage = action.payload.graduationImage;
                state.iconUrl = action.payload.iconUrl;
                state.id = action.payload.id;
                state.idImage = action.payload.idImage;
                state.isVerified = action.payload.isVerified;
                state.role = action.payload.role;
                state.status = action.payload.status;
                state.updatedAt = action.payload.updatedAt;
            })
            .addCase(uploadProfileImage.pending, (state) => {
                state.loaders.profile_image.loading = true;
                state.loaders.profile_image.error = null;
            })
            .addCase(uploadProfileImage.fulfilled, (state, action) => {
                state.loaders.profile_image.loading = false;
                state.iconUrl = action.payload.url;
            })
            .addCase(uploadProfileImage.rejected, (state, action) => {
                state.loaders.profile_image.loading = true;
                state.loaders.profile_image.error = action.message;
            });
    },
});

export const { login, logout } = slice.actions;

export default slice.reducer;

import API from "./Api";

export default class JobHistoryAPI {
    static async getAll(userId) {
        const result = await API.get(`/job-history?user=${userId}`);
        return result;
    }

    static async getOne(userId, id) {
        const result = await API.get(`/job-history/${id}?user=${userId}`);
        return result;
    }

    static async create(userId, data) {
        const result = await API.post(`/job-history?user=${userId}`, data);
        return result;
    }

    static async update(userId, id, data) {
        const result = await API.put(`/job-history/${id}?user=${userId}`, data);
        return result;
    }

    static async delete(userId, id) {
        const result = await API.delete(`/job-history/${id}?user=${userId}`);
        return result;
    }
}

import AuthRedirectWrapper from "./components/common/AuthRedirectWrapper";
import ProtectedRouteWrapper from "./components/common/ProtectedRouteWrapper";
import AuthLayout from "./layouts/AuthLayout";
import MainLayout from "./layouts/MainLayout";
import Home from "./pages/Home";
import Login from "./pages/auth/Login";
import ForgetPassword from "./pages/auth/ForgetPassword";
import AccountType from "./pages/auth/signup/AccountType";
import RegistrationType from "./pages/auth/signup/RegistrationType";
import PersonalInfo from "./pages/register/PersonalInfo";
import NotVerifiedAccount from "./components/common/NotVerifiedAccount";
import EducationLevel from "./pages/register/EducationLevel";
import { default as Register } from "./pages/register/Index";
import PracticalExperience from "./pages/register/PracticalExperience";
import ContactInfo from "./pages/register/ContactInfo";
import ReceivedInterestRequests from "./pages/individual/ReceivedInterestRequests";
import Profile from "./pages/individual/Profile";
import ProfileMe from "./pages/individual/ProfileMe";
import ProfileVisitors from "./pages/individual/ProfileVisitors";
import VerifyAccount from "./pages/individual/VerifyAccount";
import ContactUs from "./pages/ContactUs";
import InterestRequest from "./pages/individual/InterestRequest";
import PersonalInterests from "./pages/register/PersonalInterests";
import Certificates from "./pages/register/Certificates";
import References from "./pages/register/References";
import Coaching from "./pages/register/Coaching";
import Services from "./pages/register/Services";
import ProfileUpdate from "./pages/individual/ProfileUpdate";

const routes = [
    {
        path: "/",
        element: (
            <ProtectedRouteWrapper>
                <MainLayout />
            </ProtectedRouteWrapper>
        ),
        children: [
            { path: "/", element: <Home /> },
            {
                path: "/received-requests",
                element: <ReceivedInterestRequests />,
            },
            {
                path: "/received-requests/:id",
                element: <InterestRequest />,
            },
            {
                path: "/profile",
                element: <ProfileMe />,
            },
            {
                path: "/profile/update",
                element: <Profile />,
                children: [
                    {
                        index: true,
                        element: <PersonalInfo />,
                    },
                    {
                        path: "personal-info",
                        element: <PersonalInfo />,
                    },
                    {
                        path: "initial-info",
                        element: <PersonalInfo />,
                    },
                    {
                        path: "education-level",
                        element: <EducationLevel />,
                    },
                    {
                        path: "experiences",
                        element: <PracticalExperience />,
                    },
                    {
                        path: "interests",
                        element: <PersonalInterests />,
                    },
                    {
                        path: "certificates",
                        element: <Certificates />,
                    },
                    {
                        path: "references",
                        element: <References />,
                    },
                    {
                        path: "services",
                        element: <Services />,
                    },
                    {
                        path: "coaching",
                        element: <Coaching />,
                    },
                    {
                        path: "contact-info",
                        element: <ContactInfo />,
                    },
                    {
                        path: "advantages",
                        element: <PersonalInfo />,
                    },
                    {
                        path: "company-contact-info",
                        element: <PersonalInfo />,
                    },
                ],
            },
            {
                path: "/profile/visitors",
                element: <ProfileVisitors />,
            },
            {
                path: "/profile/verify",
                element: <VerifyAccount />,
            },

            {
                path: "/contact-us",
                element: <ContactUs />,
            },
        ],
    },
    {
        path: "/auth",
        element: (
            <AuthRedirectWrapper>
                <AuthLayout />
            </AuthRedirectWrapper>
        ),
        children: [
            { path: "login", element: <Login /> },
            { path: "reset-password", element: <ForgetPassword /> },
            {
                path: "signup",
                children: [
                    {
                        element: <AccountType />,
                        index: true,
                    },
                    {
                        path: "registration-type",
                        element: <RegistrationType />,
                    },
                ],
            },
        ],
    },
    {
        path: "/register",
        element: (
            <NotVerifiedAccount>
                <AuthLayout />
            </NotVerifiedAccount>
        ),
        children: [
            {
                element: <Register />,
                children: [
                    {
                        path: "personal-info",
                        element: <PersonalInfo />,
                    },
                    { path: "education-level", element: <EducationLevel /> },
                    { path: "experiences", element: <PracticalExperience /> },
                    { path: "contact-info", element: <ContactInfo /> },
                    { path: "coaching", element: <Coaching /> },
                    { path: "services", element: <Services /> },
                ],
            },
        ],
    },
    {
        path: "/admin",
        element: (
            <NotVerifiedAccount>
                <MainLayout />
            </NotVerifiedAccount>
        ),
        children: [
            {
                element: <Home />,
                children: [
                    {
                        path: "users",
                        element: <Home />,
                    },
                ],
            },
        ],
    },
];

export default routes;
